import axios from "axios";
import { setErrors } from "./errorHandler";
import { getErrorObject } from "utils/apiUtils";
import { NO_DATA_FOUND } from "constants/errorMessage";
import { DEFAULT_ROWS_PER_PAGE } from "constants/constants";
import { getAssesmmentProductListSearchBody, getAssessmentConfigurationBody, getAssessmentListBody, getAssessmentListCountBody, getAssessmentNotesBody, getClientListDataSearchBody, getQuestionnaireInfoBody, getVendorListDataSearchBody } from "utils/assessmentUtils";

export const SEARCH_ASSESSMENT_LIST_LOADING =
  "@@assessments/SEARCH_ASSESSMENT_LIST_LOADING";
export const SEARCH_ASSESSMENT_LIST_SUCCESS =
  "@@assessments/SEARCH_ASSESSMENT_LIST_SUCCESS";
export const SEARCH_ASSESSMENT_LIST_FAILURE =
  "@@assessments/SEARCH_ASSESSMENT_LIST_FAILURE";

export const getAssessmentListData =
  (formData = {}, page, rowPerPage, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_LIST_LOADING });
    try {
      const searchData = {
        ClientOrgProductVendor: formData?.ClientProductVendor,
        ClientOrgId: formData?.ClientOrgId,
        VendorName: formData?.VendorOrgName,
        ProductName: formData?.ProductName,
        AssessmentTypeId: formData?.AssessmentRequestType,
        MasterStepName: formData?.AssessmentStatusId,
      };
      const body = getAssessmentListBody(
        searchData,
        page,
        rowPerPage,
        sortModel
      );
      const response = await axios.post(
        `serviceprovider/legacy/assessment/search`,
        body
      );
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_CLIENT_LIST_LOADING =
  "@@assessments/SEARCH_CLIENT_LIST_LOADING";
export const SEARCH_CLIENT_LIST_SUCCESS =
  "@@assessments/SEARCH_CLIENT_LIST_SUCCESS";
export const SEARCH_CLIENT_LIST_FAILURE =
  "@@assessments/SEARCH_CLIENT_LIST_FAILURE";

export const getClientListData =
  (page = 1, searchValue = "", callback) =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_CLIENT_LIST_LOADING,
    });
    const body = getClientListDataSearchBody(page, searchValue);
    try {
      const res = await axios.post(`/serviceprovider/client/search`, body);
      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter(
          (it) => it.Message !== NO_DATA_FOUND
        );
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_CLIENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_CLIENT_LIST_SUCCESS,
          payload: res?.data,
          searchClientValue: searchValue,
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_CLIENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_VENDOR_LIST_LOADING =
  "@@assessments/SEARCH_VENDOR_LIST_LOADING";
export const SEARCH_VENDOR_LIST_SUCCESS =
  "@@assessments/SEARCH_VENDOR_LIST_SUCCESS";
export const SEARCH_VENDOR_LIST_FAILURE =
  "@@assessments/SEARCH_VENDOR_LIST_FAILURE";

export const getVendorListData =
  (page = 1, searchValue = "", callback) =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_VENDOR_LIST_LOADING,
    });
    const body = getVendorListDataSearchBody(page, searchValue);
    try {
      const res = await axios.post(`serviceprovider/vendor/search`, body);
      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter(
          (it) => it.Message !== NO_DATA_FOUND
        );
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_VENDOR_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_VENDOR_LIST_SUCCESS,
          payload: res?.data,
          searchVendorValue: searchValue,
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_VENDOR_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING =
  "@@assessments/SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING";
export const SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS =
  "@@assessments/SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS";
export const SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE =
  "@@assessments/SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE";

export const getAssessmentProductList =
  (page = 1, searchValue = "", callback) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING });
    try {
      const body = getAssesmmentProductListSearchBody(page, searchValue);
      const response = await axios.post(
        `/serviceprovider/assessmentrequest/product/search`,
        body
      );
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
          (it) => it.Message !== NO_DATA_FOUND
        );
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS,
          payload: response?.data || [],
          searchProductValue: searchValue,
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_ASSESSMENT_CONFIG_LOADING =
  "@assessments/SEARCH_ASSESSMENT_CONFIG_LOADING";
export const SEARCH_ASSESSMENT_CONFIG_SUCCESS =
  "@assessments/SEARCH_ASSESSMENT_CONFIG_SUCCESS";
export const SEARCH_ASSESSMENT_CONFIG_FAILURE =
  "@assessments/SEARCH_ASSESSMENT_CONFIG_FAILURE";

export const getAssessmentConfiguration = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_CONFIG_LOADING });
  try {
    const body = getAssessmentConfigurationBody();
    const response = await axios.post(
      `/legacy/assessment/configuration/search`,
      body
    );
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_LIST_COUNT_LOADING =
  "@@assessments/SEARCH_ASSESSMENT_LIST_COUNT_LOADING";
export const SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS =
  "@@assessments/SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS";
export const SEARCH_ASSESSMENT_LIST_COUNT_FAILURE =
  "@@assessments/SEARCH_ASSESSMENT_LIST_COUNT_FAILURE";

export const getAssessmentListCountData =
  (formData = {}, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_LIST_COUNT_LOADING });
    try {
      const searchData = {
        ClientOrgProductVendor: formData?.ClientProductVendor,
        ClientOrgId: formData?.ClientOrgId,
        VendorName: formData?.VendorOrgName,
        ProductName: formData?.ProductName,
        AssessmentTypeId: formData?.AssessmentRequestType,
        MasterStepName: formData?.AssessmentStatusId,
      };
      const body = getAssessmentListCountBody(searchData, sortModel);
      const response = await axios.post(
        `serviceprovider/legacy/assessment/search`,
        body
      );
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
          payload: 0,
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_VENDOR_LIST_PAGE =
  "@@assessments/SET_VENDOR_LIST_PAGE";

export const setVendorListPage =
  (page = 1, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_VENDOR_LIST_PAGE,
      payload: page,
    });
    if (callback) {
      callback();
    }
  };

export const UPDATE_VENDOR_LIST = "@@assessments/UPDATE_VENDOR_LIST";

export const updateVendorList =
  (list = [], callback) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_VENDOR_LIST,
      payload: list,
    });
    if (callback) {
      callback();
    }
  };

export const SET_CLIENT_LIST_PAGE =
  "@@assessments/SET_CLIENT_LIST_PAGE";

export const setClientListPage =
  (page = 1, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_CLIENT_LIST_PAGE,
      payload: page,
    });
    if (callback) {
      callback();
    }
  };

export const UPDATE_CLIENT_LIST = "@@assessments/UPDATE_CLIENT_LIST";

export const updateClientList =
  (list = [], callback) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_CLIENT_LIST,
      payload: list,
    });
    if (callback) {
      callback();
    }
  };

export const SET_PRODUCT_LIST_PAGE =
  "@@assessments/SET_PRODUCT_LIST_PAGE";

export const setProductListPage =
  (page = 1, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_PRODUCT_LIST_PAGE,
      payload: page,
    });
    if (callback) {
      callback();
    }
  };

export const UPDATE_PRODUCT_LIST = "@@assessments/UPDATE_PRODUCT_LIST";

export const updateProductList =
  (list = [], callback) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_LIST,
      payload: list,
    });
    if (callback) {
      callback();
    }
  };
export const SET_ASSESSMENT_PAGE_NO =
  "@@assessments/SET_ASSESSMENT_PAGE_NO";

export const setAssessmentPageNo =
  (page = 0) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_PAGE_NO,
      payload: page,
    });
  };

export const SET_ASSESSMENT_ROW_PER_PAGE =
  "@@assessments/SET_ASSESSMENT_ROW_PER_PAGE";

export const setAssessmentRowPerPage =
  (page = DEFAULT_ROWS_PER_PAGE, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_ROW_PER_PAGE,
      payload: page,
    });
    if (callback) {
      callback(page);
    }
  };

export const SET_ASSESSMENT_FILTERS =
  "@@assessments/SET_ASSESSMENT_FILTERS";

export const setAssessmentFilters =
  (data = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_FILTERS,
      payload: data,
    });
  };

export const SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET =
  "@assessments/SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET";

export const setOpenAssessmentDetailsSidesheet =
  (value) => async (dispatch) => {
    dispatch({
      type: SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET,
      payload: value,
    });
  };

export const SET_SELECTED_ASSESSMENT_DETAILS_CARD =
  "@assessments/SET_SELECTED_ASSESSMENT_DETAILS_CARD";

export const setSelectedAssessmentCardValue = (card) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_ASSESSMENT_DETAILS_CARD,
    payload: card,
  });
};

export const RESET_QUESTIONAIRE_SUMMARY =
  "@assessments/RESET_QUESTIONAIRE_SUMMARY";

export const resetQuestionaireSummary = () => async (dispatch) => {
  dispatch({
    type: RESET_QUESTIONAIRE_SUMMARY,
  });
};
export const GET_ASSESSMENT_NOTES_LOADING =
  "@assessments/GET_ASSESSMENT_NOTES_LOADING";
export const GET_ASSESSMENT_NOTES_SUCCESS =
  "@assessments/GET_ASSESSMENT_NOTES_SUCCESS";
export const GET_ASSESSMENT_NOTES_FAILURE =
  "@assessments/GET_ASSESSMENT_NOTES_FAILURE";

export const getAssessmentNotes =
  (searchData = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ASSESSMENT_NOTES_LOADING });
    try {
      const body = getAssessmentNotesBody(searchData);
      const response = await axios.post(
        `/legacy/assessment/notes/search`,
        body
      );
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: GET_ASSESSMENT_NOTES_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_ASSESSMENT_NOTES_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ASSESSMENT_NOTES_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const GET_QUESTIONNAIRE_INFO_LOADING =
  "@assessments/GET_QUESTIONNAIRE_INFO_LOADING";
export const GET_QUESTIONNAIRE_INFO_SUCCESS =
  "@assessments/GET_QUESTIONNAIRE_INFO_SUCCESS";
export const GET_QUESTIONNAIRE_INFO_FAILURE =
  "@assessments/GET_QUESTIONNAIRE_INFO_FAILURE";

export const getQuestionnaireInformation =
  (assessmentId, callback) => async (dispatch) => {
    dispatch({ type: GET_QUESTIONNAIRE_INFO_LOADING });
    try {
      const body = getQuestionnaireInfoBody(assessmentId);
      const response = await axios.post(`/legacy/assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: GET_QUESTIONNAIRE_INFO_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_QUESTIONNAIRE_INFO_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
        dispatch(setSelectedAssessmentCardValue(response?.data?.Data?.Rows[0]));
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: GET_QUESTIONNAIRE_INFO_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };
