import axios from "axios";
import { NO_DATA_FOUND } from "constants/errorMessage";
import { VENDOR_APP_ID, SEND_EMAIL, USER_ID, VENDOR_USER_ROLE_ID } from "constants/users";
import { getErrorObject } from "utils/apiUtils";
import { searchVendorProfileSpecificUsersBody, searchVendorUsersBody } from "utils/vendorUsersUtils";
import { setErrors } from "./errorHandler";

export const SEARCH_VENDOR_USERS_LOADING =
  "@@vendorUsers/SEARCH_VENDOR_USERS_LOADING";
export const SEARCH_VENDOR_USERS_SUCCESS =
  "@@vendorUsers/SEARCH_VENDOR_USERS_SUCCESS";
export const SEARCH_VENDOR_USERS_FAILURE =
  "@@vendorUsers/SEARCH_VENDOR_USERS_FAILURE";

export const searchVendorUsers = (vendorOrgId) => async (dispatch) => {
  dispatch({
    type: SEARCH_VENDOR_USERS_LOADING,
  });

  const body = searchVendorUsersBody(vendorOrgId);

  try {
    const response = await axios.post(`/user/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter(
        (errorItem) => errorItem.Message !== NO_DATA_FOUND
      );
        dispatch({
          type: SEARCH_VENDOR_USERS_FAILURE,
        });
        dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_VENDOR_USERS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: SEARCH_VENDOR_USERS_FAILURE,
    });
  }
};

export const SEARCH_VENDOR_SPECIFIC_USER_LOADING =
  "@@vendorUsers/SEARCH_VENDOR_SPECIFIC_USER_LOADING";
export const SEARCH_VENDOR_SPECIFIC_USER_SUCCESS =
  "@@vendorUsers/SEARCH_VENDOR_SPECIFIC_USER_SUCCESS";
export const SEARCH_VENDOR_SPECIFIC_USER_FAILURE =
  "@@vendorUsers/SEARCH_VENDOR_SPECIFIC_USER_FAILURE";

export const searchVendorSpecificUser =
  (searchValue, vendorOrgId) => async (dispatch) => {
    dispatch({
      type: SEARCH_VENDOR_SPECIFIC_USER_LOADING,
    });
    let body = searchVendorProfileSpecificUsersBody(searchValue, vendorOrgId)

    try {
      const response = await axios.post(`/user/search`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
          (errorItem) => errorItem.Message !== NO_DATA_FOUND
        ); 
          dispatch({
            type: SEARCH_VENDOR_SPECIFIC_USER_FAILURE,
          });
          dispatch(setErrors(errors));
      } else {
        dispatch({
          type: SEARCH_VENDOR_SPECIFIC_USER_SUCCESS,
          payload: response?.data?.Data?.Rows,
        });
      }
    } catch {
      dispatch({
        type: SEARCH_VENDOR_SPECIFIC_USER_FAILURE,
      });
    }
  };

export const POST_VENDOR_USER_TO_LIST_LOADING =
  "@@vendorUsers/POST_VENDOR_USER_TO_LIST_LOADING";
export const POST_VENDOR_USER_TO_LIST_SUCCESS =
  "@@vendorUsers/POST_VENDOR_USER_TO_LIST_SUCCESS";
export const POST_VENDOR_USER_TO_LIST_FAILURE =
  "@@vendorUsers/POST_VENDOR_USER_TO_LIST_FAILURE";

export const addVendorUsersToList = (formData) => async (dispatch) => {
  dispatch({
    type: POST_VENDOR_USER_TO_LIST_LOADING,
  });
  
  let tempFormData = {
        "UserId": USER_ID,
        "UserEmail": formData?.UserEmail,
        "FirstName": formData?.FirstName,
        "MiddleName": formData?.MiddleName,
        "LastName": formData?.LastName,
        "NamePrefix": formData?.NamePrefix,
        "Roles":[
            {"UserRoleId": VENDOR_USER_ROLE_ID}
        ],
        "AppId": VENDOR_APP_ID,
        "OrgId": formData?.id,
        "SendEmail": SEND_EMAIL 
      }

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`/user`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_VENDOR_USER_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: POST_VENDOR_USER_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchVendorUsers(formData?.id));
    }
  } catch {
    dispatch({
      type: POST_VENDOR_USER_TO_LIST_FAILURE,
    });
  }
};

export const PUT_VENDOR_USER_TO_LIST_LOADING =
  "@@vendorUsers/PUT_VENDOR_USER_TO_LIST_LOADING";
export const PUT_VENDOR_USER_TO_LIST_SUCCESS =
  "@@vendorUsers/PUT_VENDOR_USER_TO_LIST_SUCCESS";
export const PUT_VENDOR_USER_TO_LIST_FAILURE =
  "@@vendorUsers/PUT_VENDOR_USER_TO_LIST_FAILURE";

export const updateVendorUsersToList = (formData) => async (dispatch) => {
  dispatch({
    type: PUT_VENDOR_USER_TO_LIST_LOADING,
  });

  let tempFormData = {
        "UserId": formData?.editedFormData?.UserId,
        "UserEmail": formData?.editedFormData?.UserEmail,
        "FirstName": formData?.editedFormData?.FirstName,
        "MiddleName": formData?.editedFormData?.MiddleName,
        "LastName": formData?.editedFormData?.LastName,
        "NamePrefix": formData?.editedFormData?.NamePrefix,
        "Roles":[
            {"UserRoleId": VENDOR_USER_ROLE_ID}
        ],
        "AppId": VENDOR_APP_ID,
        "OrgId": formData?.id,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.put(`/user/${formData?.editedFormData?.UserId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_VENDOR_USER_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_VENDOR_USER_TO_LIST_SUCCESS,
        payload: {
          response: response?.data?.Data,
          formData,
        },
      });
    }

    dispatch(
      setVendorUser({
        selectedUserIndex: formData?.itemToBeUpdatedIndex + 1,
        actions: null,
        selectedVendorUserID:
          formData?.editedFormData?.UserId,
      })
    );

  } catch {
    dispatch({
      type: PUT_VENDOR_USER_TO_LIST_FAILURE,
    });
  }
};

export const SET_VENDOR_USERS_LOADING =
  "@@vendorUsers/SET_VENDOR_USERS_LOADING";
export const SET_VENDOR_USERS_SUCCESS =
  "@@vendorUsers/SET_VENDOR_USERS_SUCCESS";
export const SET_VENDOR_USERS_FAILURE =
  "@@vendorUsers/SET_VENDOR_USERS_FAILURE";

export const setVendorUser = (payload) => async (dispatch) => {
  dispatch({
    type: SET_VENDOR_USERS_LOADING,
  });
  try {
    dispatch({
      type: SET_VENDOR_USERS_SUCCESS,
      payload: payload,
    });
  } catch {
    dispatch({
      type: SET_VENDOR_USERS_FAILURE,
    });
  }
};

export const DELETE_VENDOR_USER_LOADING = "@@vendorusers/DELETE_VENDOR_USER_LOADING";
export const DELETE_VENDOR_USER_FAILURE = "@@vendorusers/DELETE_VENDOR_USER_FAILURE";
export const DELETE_VENDOR_USER_SUCCESS = "@@vendorusers/DELETE_VENDOR_USER_SUCCESS";

export const removeVendorUser = (vendorOrgId, userId, userRoleId) => async (dispatch) => {
  dispatch({
      type: DELETE_VENDOR_USER_LOADING
  })

  let tempFormData = {
    "UserId": userId,
    "Roles":[
        {"UserRoleId": userRoleId}
    ],
    "AppId": VENDOR_APP_ID,
    "OrgId": vendorOrgId,
  };

  const body = JSON.stringify(tempFormData);
  try {
    let res = await axios.post(`/user/${userId}/action/unassign`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: DELETE_VENDOR_USER_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: DELETE_VENDOR_USER_SUCCESS,
        payload: res?.data,
      });
      dispatch(searchVendorUsers(vendorOrgId));
      dispatch(
        setVendorUser({
          selectedUserIndex: null,
          actions: null,
          selectedCorlUserID: null,
        })
      );
    }
    }catch(err) {
      dispatch({
        type : DELETE_VENDOR_USER_FAILURE,
        payload: getErrorObject(err)
    })
  }

}
export const POST_RESEND_INVITE_USER_LOADING = "@@vendorusers/POST_RESEND_INVITE_USER_LOADING";
export const POST_RESEND_INVITE_USER_FAILURE = "@@vendorusers/POST_RESEND_INVITE_USER_FAILURE";
export const POST_RESEND_INVITE_USER_SUCCESS = "@@vendorusers/POST_RESEND_INVITE_USER_SUCCESS";

export const resendInviteVendorUser = (reqBody) => async (dispatch) => {
  dispatch({
      type: POST_RESEND_INVITE_USER_LOADING
  })
  let body = JSON.stringify(reqBody)
  try {
    let res = await axios.post(`/user/${reqBody.UserId}/action/sendemailinvite`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_RESEND_INVITE_USER_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_RESEND_INVITE_USER_SUCCESS,
        payload: res?.data,
      });
    }
    }catch(err) {
      dispatch({
        type : POST_RESEND_INVITE_USER_FAILURE,
        payload: getErrorObject(err)
    })
  }
}
