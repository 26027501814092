import { isNil } from "lodash"

export const getSlaInformationListSearchBody = (vendorOrgId, customerVendorId, productVendorId) => {
  return {
    "intent": "",
    "fields":
      [
        "ProductVendorId",
        "CustomerVendorProductId",
        "ProducVendorId",
        "SlaStatementId",
        "SlaText",
        "Indent",
        "DisplayOrder",
        "IncidentSlaSectionId",
        "TimeOffsetText",
        "ResponseTimeUnit",
        "ResponseEventText",
        "RequiresDocument",
        "VendorSlaResponseId",
        "ResponseTime",
        "QuestionResponseType",
        "QuestionResponseCategory",
        "QuestionResponseText",
        "ParentSlaStatementId",
        "ResponseCategoryId"
      ],
    "criterion":
      [
        {
          "field": "VendorOrgId",
          "operator": "=",
          "value": vendorOrgId
        },
        {
          "field": "CustomerVendorId",
          "operator": isNil(customerVendorId) ? "isnull" : "=",
          "value": isNil(customerVendorId) ? "" : customerVendorId
        },
        {
          "field": "ProductVendorId",
          "operator": isNil(productVendorId) ? "isnull" : "=",
          "value": isNil(productVendorId) ? "" : productVendorId
        }
      ],
    "sort": [
      {
        "field": "DisplayOrder",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0
  }
}

export const getSlaStatementListSearchBody = () => {
  return {
    "intent": "",
    "fields":
      [
        "SlaStatementId",
        "IncidentSlaSectionId",
        "SlaText",
        "Indent",
        "DisplayOrder",
        "IncidentSlaSectionId",
        "TimeOffsetText",
        "ResponseTimeUnit",
        "ResponseEventText",
        "RequiresDocument",
        "QuestionResponseType",
        "ParentSlaStatementId",
        "ResponseCategoryId"
      ],
    "criterion": [],
    "sort": [
      {
        "field": "DisplayOrder",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0
  }
}