import { ERROR_MESSAGE, QUESTIONS_REORDERED_SUCCESSFULLY_MESSAGE, QUESTION_DELETED_SUCCESSFULLY_MESSAGE, QUESTION_SAVED_SUCCESSFULLY_MESSAGE, QUESTION_UPDATED_SUCCESSFULLY_MESSAGE, SAVE_SUCCESSFULLY_MESSAGE, STATUS_UPDATED_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { cloneDeep, isEmpty } from 'lodash'
import { toast } from 'react-toastify';
import { 
    SET_QUESTION_LIST,
    POST_QUESTIONNAIRE_TEMPLATE_LOADING,
    POST_QUESTIONNAIRE_TEMPLATE_FAILURE,
    POST_QUESTIONNAIRE_TEMPLATE_SUCCESS,
    POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
    POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING,
    POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
    GET_QUESTIONNAIRE_TEMPLATE_LOADING,
    GET_QUESTIONNAIRE_TEMPLATE_SUCCESS,
    GET_QUESTIONNAIRE_TEMPLATE_FAILURE,
    SET_SELECTED_QUESTION,
    PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING,
    PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
    PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE,
    RESET_QUESTIONNAIRE_TEMPLATE,
    PUT_QUESTIONNAIRE_TEMPLATE_LOADING,
    PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS,
    PUT_QUESTIONNAIRE_TEMPLATE_FAILURE,
    HIDE_ALERT,
    DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
    ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS,
    SEARCH_INCIDENT_LIST_LOADING,
    SEARCH_INCIDENT_LIST_SUCCESS,
    SEARCH_INCIDENT_LIST_FAILURE,
    GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING,
    GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS,
    GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE,
    POST_COMMENT_QUESTIONNAIRE_LOADING,
    POST_COMMENT_QUESTIONNAIRE_SUCCESS,
    POST_COMMENT_QUESTIONNAIRE_FAILURE,
    POST_QUESTIONNAIRE_STATUS_LOADING,
    POST_QUESTIONNAIRE_STATUS_SUCCESS,
    POST_QUESTIONNAIRE_STATUS_FAILURE,
    QUESTION_PANEL_DISABLE_STATE,
    REORDER_QUESTIONS_FAILURE,
    REORDER_QUESTIONS_LOADING,
    REORDER_QUESTIONS_SUCCESS
} from '../actions/questionnairBuilder';

import { CLIENT_APPROVED_STATUS, CLIENT_REJECTED_STATUS } from '../pages/QuestionnaireBuilder/constants'

const initialState = {
    loading: false, 
    templateQuestionList: [],
    incidentList: [],
    questionnaireStatusList: [],
    questionnaireTemplate: {},
    selectedQuestion: {},
    showQuestionnaireSavedAlert: false,
    showQuestionSavedAlert: false,
    showQuestionUpdatedAlert: false,
    showQuestionDeletedAlert: false,
    showQuestionRequiredAlert: false,
    isQuestionnaireCommentSaved : false,
    isQuestionnaireStatusSaved : false,
    questionPanelDisableState: true,
};

const convertIncidentList = (list) => {
    return list.map((incident) => ({
        "value": incident.IncidentId,
        "label": incident.IncidentName
    }))
}

export default function (state = initialState, action) { 
    const { type, payload } = action; 
    switch (type) {   
        case SET_QUESTION_LIST: {       
            const templateQuestionList = cloneDeep(state.templateQuestionList);       
            templateQuestionList.push({      
                questionNumber: templateQuestionList.length + 1,
                ...payload       
            });       
            return {           
                ...state,           
                templateQuestionList
           }   
        }   

        case SET_SELECTED_QUESTION: {
            let questionResponseList = payload?.QuestionResponseOptionList?.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            return {
                ...state,
                selectedQuestion: { ...payload, QuestionResponseOptionList: questionResponseList }
            }
        }

        case POST_QUESTIONNAIRE_TEMPLATE_LOADING:
        case POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING:
        case GET_QUESTIONNAIRE_TEMPLATE_LOADING:
        case PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING:
        case PUT_QUESTIONNAIRE_TEMPLATE_LOADING:
        case SEARCH_INCIDENT_LIST_LOADING:
        case GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING:
        case REORDER_QUESTIONS_LOADING: {
            return {
                ...state,
                loading: true
            }
        }

        case POST_QUESTIONNAIRE_TEMPLATE_SUCCESS:
        case PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS: {
            toast.success(SAVE_SUCCESSFULLY_MESSAGE)
            return {
                ...state,
                loading: false,
                showQuestionnaireSavedAlert: true,
            }
        }

        case POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS: {
            toast.success(QUESTION_SAVED_SUCCESSFULLY_MESSAGE)
            return {
                ...state,
                loading: false,
                showQuestionSavedAlert: true,
                questionPanelDisableState: true
            }
        }

        case SEARCH_INCIDENT_LIST_SUCCESS: {
            return {
                ...state,
                loading : false,
                incidentList : convertIncidentList(payload?.Data?.Rows)
            }
        }

        case GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS: {
            return {
                ...state,
                loading : false,
                questionnaireStatusList : payload?.Data?.filter( item => 
                   !((item.Label === CLIENT_APPROVED_STATUS) || (item.Label === CLIENT_REJECTED_STATUS)) && item
                )
            }
        }

        case PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS: {
            toast.success(QUESTION_UPDATED_SUCCESSFULLY_MESSAGE)
            return {
                ...state,
                loading: false,
                showQuestionUpdatedAlert: true,
            }
        }

        case GET_QUESTIONNAIRE_TEMPLATE_SUCCESS: {
            const initialQuestionList = payload.Data?.QuestionList ?? [];
            let selectedQuestionValue = state?.selectedQuestion;
            let templateQuestionList = initialQuestionList.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            if (!isEmpty(selectedQuestionValue)) {
              let index = templateQuestionList.findIndex(
                (item) =>
                  item?.QuestionnaireTemplateQuestionId ===
                  selectedQuestionValue?.QuestionnaireTemplateQuestionId
              );
              selectedQuestionValue = templateQuestionList[index];
              selectedQuestionValue = {
                ...selectedQuestionValue,
                QuestionResponseOptionList: selectedQuestionValue?.QuestionResponseOptionList?.sort((a, b) => a.DisplayOrder - b.DisplayOrder),
              };
            }
            return {
                ...state,
                loading: false,
                questionnaireTemplate: payload?.Data,
                selectedQuestion: selectedQuestionValue,
                templateQuestionList: templateQuestionList.map((it, i) => {
                    return {
                        ...it,
                        questionNumber: i + 1
                    }
                })
            }
        }

        case POST_QUESTIONNAIRE_TEMPLATE_FAILURE:
        case POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE:
        case GET_QUESTIONNAIRE_TEMPLATE_FAILURE:
        case PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE:
        case PUT_QUESTIONNAIRE_TEMPLATE_FAILURE:
        case REORDER_QUESTIONS_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }

        case SEARCH_INCIDENT_LIST_FAILURE: {
            return {
                ...state,
                loading : false,
                incidentList: []
            }
        }

        case GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE: {
            return {
                ...state,
                loading : false,
                questionnaireStatusList: payload
            }
        }

        case RESET_QUESTIONNAIRE_TEMPLATE: {
            return {
                ...state,
                questionnaireTemplate: {}
            }
        }

        case DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS: {
            toast.success(QUESTION_DELETED_SUCCESSFULLY_MESSAGE)
            return {
                ...state,
                showQuestionDeletedAlert: true,
            }
        }

        case POST_COMMENT_QUESTIONNAIRE_LOADING: {
            return {
                ...state,
                isQuestionnaireCommentSaved : false
            }
        } 

        case POST_QUESTIONNAIRE_STATUS_LOADING: {
            return {
                ...state,
                isQuestionnaireStatusSaved : false
            }
        }

        case POST_COMMENT_QUESTIONNAIRE_SUCCESS: {
            return {
                ...state,
                isQuestionnaireCommentSaved : true
            }
        }

        case POST_QUESTIONNAIRE_STATUS_SUCCESS: {
            return {
                ...state,
                isQuestionnaireStatusSaved : true
            }
        }

        case POST_COMMENT_QUESTIONNAIRE_FAILURE: {
            return {
                ...state,
                isQuestionnaireCommentSaved : false
            }
        }

        case POST_QUESTIONNAIRE_STATUS_FAILURE: {
            return {
                ...state,
                isQuestionnaireStatusSaved : false
            }
        }

        case ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS: {
            toast.success(STATUS_UPDATED_SUCCESSFULLY_MESSAGE)
            return {
                ...state,
                showQuestionRequiredAlert: true,
            }
        }

        case HIDE_ALERT: {
            return {
                ...state,
                showQuestionnaireSavedAlert: false,
                showQuestionSavedAlert: false,
                showQuestionUpdatedAlert: false,
                showQuestionDeletedAlert: false,
                showQuestionRequiredAlert: false,
            }
        }

        case QUESTION_PANEL_DISABLE_STATE: {
            return {
                ...state,
                questionPanelDisableState: payload
            }
        }        

        case REORDER_QUESTIONS_SUCCESS: {
            toast.success(QUESTIONS_REORDERED_SUCCESSFULLY_MESSAGE)
            return {
                ...state,
                loading: false,
            }
        }
        
        default:     
            return state; 
    }
}
