import axios from "axios";
import { getErrorObject } from "../utils/apiUtils";
import { getCorlClearedAssessmentBody, getProductComponentBody, getProductGroupListBody, getProductListBody, getSelectedProductBody } from "../utils/vendorProductUtils";
import { setErrors } from './errorHandler';
import { DEASSOCIATE_PRODUCTS_FROM_PRODUCTGROUP_ERROR_MESSAGE, VENDORS_PRODUCTS_TAB_OPTIONS } from "pages/VendorProfile/Products/constants";
import { setCustomErrors } from "./customErrorHandler";
import { NO_DATA_FOUND } from "constants/errorMessage";

export const SEARCH_PRODUCT_GROUP_LIST_LOADING = '@@vendorProductGroup/SEARCH_PRODUCT_GROUP_LIST_LOADING';
export const SEARCH_PRODUCT_GROUP_LIST_SUCCESS = '@@vendorProductGroup/SEARCH_PRODUCT_GROUP_LIST_SUCCESS';
export const SEARCH_PRODUCT_GROUP_LIST_FAILURE = '@@vendorProductGroup/SEARCH_PRODUCT_GROUP_LIST_FAILURE';

export const searchProductGroupList = (vendorOrgId) => async (dispatch) => {
dispatch({ type: SEARCH_PRODUCT_GROUP_LIST_LOADING });
    let body = getProductGroupListBody(vendorOrgId)
  try {
    const response = await axios.post("vendor/productgroup/search",body);
    if(response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
            (it) => it.Message !== NO_DATA_FOUND
          );
          dispatch(setErrors(errors));
        dispatch({
            type: SEARCH_PRODUCT_GROUP_LIST_FAILURE
          });
    } 
    else {
        dispatch({
            type: SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
            payload: response?.data?.Data?.Rows
        });
    }
   
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_GROUP_LIST_FAILURE,
      payload: getErrorObject(err)
    });
  }
}

export const POST_PRODUCT_GROUP_TO_LIST_LOADING = '@@vendorProductGroup/POST_PRODUCT_GROUP_TO_LIST_LOADING';
export const POST_PRODUCT_GROUP_TO_LIST_SUCCESS = '@@vendorProductGroup/POST_PRODUCT_GROUP_TO_LIST_SUCCESS';
export const POST_PRODUCT_GROUP_TO_LIST_FAILURE = '@@vendorProductGroup/POST_PRODUCT_GROUP_TO_LIST_FAILURE';

export const addProductGrouptoList = (formData, vendorOrgId, callback) => async dispatch => {
    dispatch({
        type: POST_PRODUCT_GROUP_TO_LIST_LOADING
    });
    const body = JSON.stringify({...formData, active:true, VendorOrgId : vendorOrgId});

    try {
        const response = await axios.post("vendor/productgroup",body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: POST_PRODUCT_GROUP_TO_LIST_FAILURE
            })
        } else {
            dispatch({
            type: POST_PRODUCT_GROUP_TO_LIST_SUCCESS,
            payload: response?.data?.Data
        });
        dispatch(searchProductGroupList(vendorOrgId))
            if (callback) {
                callback();
            }
        }
    } catch {
        dispatch({
            type: POST_PRODUCT_GROUP_TO_LIST_FAILURE
        })
    }
}

export const PUT_PRODUCT_GROUP_TO_LIST_LOADING = '@@vendorProductGroup/PUT_PRODUCT_GROUP_TO_LIST_LOADING';
export const PUT_PRODUCT_GROUP_TO_LIST_SUCCESS = '@@vendorProductGroup/PUT_PRODUCT_GROUP_TO_LIST_SUCCESS';
export const PUT_PRODUCT_GROUP_TO_LIST_FAILURE = '@@vendorProductGroup/PUT_PRODUCT_GROUP_TO_LIST_FAILURE';

export const updateProductGroupinList = (formData, vendorOrgId, callback) => async dispatch => {
    dispatch({
        type: PUT_PRODUCT_GROUP_TO_LIST_LOADING
    });
    const body = JSON.stringify({...formData, VendorOrgId: vendorOrgId});
    try {
        const response = await axios.put(`vendor/productgroup/${formData?.ProductGroupId}`,body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: PUT_PRODUCT_GROUP_TO_LIST_FAILURE
            })
        } else {
            dispatch({
                type: PUT_PRODUCT_GROUP_TO_LIST_SUCCESS,
                payload: response?.data?.Data,
        });
        dispatch(searchProductGroupList(vendorOrgId))
            if (callback) {
                callback();
            }
        }
    } catch {
        dispatch({
            type: PUT_PRODUCT_GROUP_TO_LIST_FAILURE
        })
    }
}

export const DELETE_PRODUCT_GROUP_FROM_LIST_LOADING = '@@vendorProductGroup/DELETE_PRODUCT_GROUP_FROM_LIST_LOADING';
export const DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS = '@@vendorProductGroup/DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS';
export const DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE = '@@vendorProductGroup/DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE';

export const deleteProductGroupinList = (productGroupId, forceDelete = false, vendorOrgId, callback) => async dispatch => {    
    dispatch({
        type: DELETE_PRODUCT_GROUP_FROM_LIST_LOADING
    });
    let url = `/vendor/productgroup/${productGroupId}`;
    if(forceDelete) {
      url = `${url}?forceDelete=true`
    }
    
    try {
        const response = await axios.delete(url);
        if(response?.data?.ErrorCount > 0) {
            const errors = response?.data?.Errors[0]?.Message?.toLowerCase()?.includes(DEASSOCIATE_PRODUCTS_FROM_PRODUCTGROUP_ERROR_MESSAGE)
            dispatch({
                type: DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE,
                payload: errors
            })
            
        } else {
            dispatch({
                type: DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS,
                payload: response?.data?.Data,
        });
        dispatch(searchProductGroupList(vendorOrgId))
            if (callback) {
                callback();
        }
        }
    } catch {
        dispatch({
            type: DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE
        })
    }
}

export const SEARCH_PRODUCT_LIST_LOADING = '@@vendorProduct/SEARCH_PRODUCT_LIST_LOADING';
export const SEARCH_PRODUCT_LIST_SUCCESS = '@@vendorProduct/SEARCH_PRODUCT_LIST_SUCCESS';
export const SEARCH_PRODUCT_LIST_FAILURE = '@@vendorProduct/SEARCH_PRODUCT_LIST_FAILURE';

export const searchProductList = (vendorOrgId) => async (dispatch) => {
dispatch({ type: SEARCH_PRODUCT_LIST_LOADING });
    let body = getProductListBody(vendorOrgId)
 try {
    const response = await axios.post("vendor/product/search",body);
    if(response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
            (errorItem) => errorItem.Message !== NO_DATA_FOUND
        );
  dispatch({
            type: SEARCH_PRODUCT_LIST_FAILURE,
        });
        dispatch(setErrors(errors));
    } 
    else {
        dispatch({
            type: SEARCH_PRODUCT_LIST_SUCCESS,
            payload: response?.data?.Data?.Rows,
            vendorOrgId: vendorOrgId,
        });
    }
   
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_LIST_FAILURE,
      payload: getErrorObject(err)
    });
  }
}

export const POST_PRODUCT_TO_LIST_LOADING = '@@vendorProduct/POST_PRODUCT_TO_LIST_LOADING';
export const POST_PRODUCT_TO_LIST_SUCCESS = '@@vendorProduct/POST_PRODUCT_TO_LIST_SUCCESS';
export const POST_PRODUCT_TO_LIST_FAILURE = '@@vendorProduct/POST_PRODUCT_TO_LIST_FAILURE';

export const addProductstoList = (formData, vendorOrgId) => async dispatch => {
    dispatch({
        type: POST_PRODUCT_TO_LIST_LOADING
    });
    const body = JSON.stringify({...formData, active:true, VendorOrgId: vendorOrgId});
    try {
        const response = await axios.post("vendor/product",body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: POST_PRODUCT_TO_LIST_FAILURE
            })
        } else {
            dispatch({
            type: POST_PRODUCT_TO_LIST_SUCCESS,
            payload: response?.data?.Data
        });
            dispatch(searchProductList(vendorOrgId));
        }
    } catch {
        dispatch({
            type: POST_PRODUCT_TO_LIST_FAILURE
        })
    }
}


export const PUT_PRODUCT_TO_LIST_LOADING = '@@vendorProduct/PUT_PRODUCT_TO_LIST_LOADING';
export const PUT_PRODUCT_TO_LIST_SUCCESS = '@@vendorProduct/PUT_PRODUCT_TO_LIST_SUCCESS';
export const PUT_PRODUCT_TO_LIST_FAILURE = '@@vendorProduct/PUT_PRODUCT_TO_LIST_FAILURE';

export const updateProductsinList = (formData, productId, vendorOrgId) => async dispatch => {
    
    dispatch({
        type: PUT_PRODUCT_TO_LIST_LOADING
    });

    const body = JSON.stringify({...formData, OemOrgId: vendorOrgId, VendorOrgId: vendorOrgId});
    try {
        const response = await axios.put(`vendor/product/${productId}`,body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: PUT_PRODUCT_TO_LIST_FAILURE
            })
        } else {
            dispatch({
            type: PUT_PRODUCT_TO_LIST_SUCCESS,
            payload: response?.data?.Data,
        });
            dispatch(searchProductList(vendorOrgId));
        }
    } catch {
        dispatch({
            type: PUT_PRODUCT_TO_LIST_FAILURE
        })
    }
}

export const DELETE_PRODUCT_FROM_LIST_LOADING = '@@vendorProduct/DELETE_PRODUCT_FROM_LIST_LOADING';
export const DELETE_PRODUCT_FROM_LIST_SUCCESS = '@@vendorProduct/DELETE_PRODUCT_FROM_LIST_SUCCESS';
export const DELETE_PRODUCT_FROM_LIST_FAILURE = '@@vendorProduct/DELETE_PRODUCT_FROM_LIST_FAILURE';

export const deleteProductsinList = (productVendorId, vendorOrgId, forcedState=false) => async dispatch => {
    dispatch({
        type: DELETE_PRODUCT_FROM_LIST_LOADING
    });
    let url = `/vendor/product/${productVendorId}`;
    if(forcedState) {
      url = `${url}?forceDelete=true`
    }
    try {
        const response = await axios.delete(url);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setProductDeleteErrors(response?.data?.Errors));
            dispatch(setCustomErrors(response?.data?.Errors));
            dispatch({
                type: DELETE_PRODUCT_FROM_LIST_FAILURE
            })
        } else {
            dispatch({
                type: DELETE_PRODUCT_FROM_LIST_SUCCESS,
                payload: response?.data?.Data,
        });
        dispatch(searchProductList(vendorOrgId));
        }
    } catch {
        dispatch({
            type: DELETE_PRODUCT_FROM_LIST_FAILURE
        })
    }
}

export const SET_VENDORS_PRODUCTS_TAB_VALUE =
  "@products/SET_VENDORS_PRODUCTS_TAB_VALUE";

export const setVendorProductsTabValue =
  (value = VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS) =>
    async (dispatch) => {
      dispatch({
        type: SET_VENDORS_PRODUCTS_TAB_VALUE,
        payload: value,
      });
    };

    export const SET_PRODUCT_DELETE_ERRORS =
    "@products/SET_PRODUCT_DELETE_ERRORS";
  
  export const setProductDeleteErrors = (value) => async (dispatch) => {
        dispatch({
          type: SET_PRODUCT_DELETE_ERRORS,
          payload: value,
        });
      };

      export const SET_PRODUCTS_GROUP_FORCE_DELETE =
      "@products/SET_PRODUCTS_GROUP_FORCE_DELETE";
    
    export const setProductsGroupForceDelete = (value) => async (dispatch) => {
          dispatch({
            type: SET_PRODUCTS_GROUP_FORCE_DELETE,
            payload: value,
          });
        };

export const SEARCH_SELECTED_PRODUCT_DETAILS_LOADING = '@@vendorProduct/SEARCH_SELECTED_PRODUCT_DETAILS_LOADING';
export const SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS = '@@vendorProduct/SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS';
export const SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE = '@@vendorProduct/SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE';
        
export const searchSelectedProductDetails = (vendorOrgId, productId) => async (dispatch) => {
dispatch({ type: SEARCH_SELECTED_PRODUCT_DETAILS_LOADING });
    let body = getSelectedProductBody(vendorOrgId,productId)
    try {
    const response = await axios.post("vendor/product/search",body);
    if(response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
            (errorItem) => errorItem.Message !== NO_DATA_FOUND
        );
    dispatch({
            type: SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE,
        });
        dispatch(setErrors(errors));
    } 
    else {
        dispatch({
            type: SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS,
            payload: response?.data?.Data?.Rows[0],
        });
    }
    
    } catch (err) {
    dispatch({
        type: SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE,
        payload: getErrorObject(err)
    });
    }
}
export const SEARCH_PRODUCT_COMPONENT_LOADING = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_LOADING';
export const SEARCH_PRODUCT_COMPONENT_SUCCESS = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_SUCCESS';
export const SEARCH_PRODUCT_COMPONENT_FAILURE = '@@vendorProduct/SEARCH_PRODUCT_COMPONENT_FAILURE';
        
export const searchProductComponent = (productVendorId) => async (dispatch) => {
dispatch({ type: SEARCH_PRODUCT_COMPONENT_LOADING });
    let body = getProductComponentBody(productVendorId)
    try {
    const response = await axios.post("vendor/product/component/search",body);
    if(response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
            (errorItem) => errorItem.Message !== NO_DATA_FOUND
        );
    dispatch({
            type: SEARCH_PRODUCT_COMPONENT_FAILURE,
        });
        dispatch(setErrors(errors));
    } 
    else {
        dispatch({
            type: SEARCH_PRODUCT_COMPONENT_SUCCESS,
            payload: response?.data?.Data?.Rows,
        });
    }
    
    } catch (err) {
    dispatch({
        type: SEARCH_PRODUCT_COMPONENT_FAILURE,
        payload: getErrorObject(err)
    });
    }
}

export const ADD_PRODUCT_COMPONENT_LOADING = '@@vendorProduct/ADD_PRODUCT_COMPONENT_LOADING';
export const ADD_PRODUCT_COMPONENT_SUCCESS = '@@vendorProduct/ADD_PRODUCT_COMPONENT_SUCCESS';
export const ADD_PRODUCT_COMPONENT_FAILURE = '@@vendorProduct/ADD_PRODUCT_COMPONENT_FAILURE';
        
export const addProductComponent = (formData) => async (dispatch) => {
    dispatch({ type: ADD_PRODUCT_COMPONENT_LOADING });
    const body = JSON.stringify(formData);
        try {
        const response = await axios.post("vendor/product/component",formData);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: ADD_PRODUCT_COMPONENT_FAILURE,
            });
        } 
        else {
            dispatch({
                type: ADD_PRODUCT_COMPONENT_SUCCESS,
                payload: response?.data?.Data,
            });
            dispatch(searchProductComponent(formData?.ProductVendorId));
        }
        
        } catch (err) {
        dispatch({
            type: ADD_PRODUCT_COMPONENT_FAILURE,
            payload: getErrorObject(err)
        });
    }
}

export const UPDATE_PRODUCT_COMPONENT_LOADING = '@@vendorProduct/UPDATE_PRODUCT_COMPONENT_LOADING';
export const UPDATE_PRODUCT_COMPONENT_SUCCESS = '@@vendorProduct/UPDATE_PRODUCT_COMPONENT_SUCCESS';
export const UPDATE_PRODUCT_COMPONENT_FAILURE = '@@vendorProduct/UPDATE_PRODUCT_COMPONENT_FAILURE';
        
export const updateProductComponent = (formData, productComponentId) => async (dispatch) => {
    dispatch({ type: UPDATE_PRODUCT_COMPONENT_LOADING });
    const body = JSON.stringify(formData);
        try {
        const response = await axios.put(`vendor/product/component/${productComponentId}`,formData);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: UPDATE_PRODUCT_COMPONENT_FAILURE,
            });
        } 
        else {
            dispatch({
                type: UPDATE_PRODUCT_COMPONENT_SUCCESS,
                payload: response?.data?.Data,
            });
            dispatch(searchProductComponent(formData?.ProductVendorId));
        }
        
        } catch (err) {
        dispatch({
            type: UPDATE_PRODUCT_COMPONENT_FAILURE,
            payload: getErrorObject(err)
        });
    }
}

export const DELETE_PRODUCT_COMPONENT_LOADING = '@@vendorProduct/DELETE_PRODUCT_COMPONENT_LOADING';
export const DELETE_PRODUCT_COMPONENT_SUCCESS = '@@vendorProduct/DELETE_PRODUCT_COMPONENT_SUCCESS';
export const DELETE_PRODUCT_COMPONENT_FAILURE = '@@vendorProduct/DELETE_PRODUCT_COMPONENT_FAILURE';
        
export const deleteProductComponent = (productVendorId, productComponentId) => async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT_COMPONENT_LOADING });
        try {
        const response = await axios.delete(`vendor/product/component/${productComponentId}`);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: DELETE_PRODUCT_COMPONENT_FAILURE,
            });
        } 
        else {
            dispatch({
                type: DELETE_PRODUCT_COMPONENT_SUCCESS,
                payload: response?.data?.Data,
            });
            dispatch(searchProductComponent(productVendorId));
        }
        
        } catch (err) {
        dispatch({
            type: DELETE_PRODUCT_COMPONENT_FAILURE,
            payload: getErrorObject(err)
        });
    }
}

export const ADD_CORL_CLEARED_ASSESSMENT_LOADING = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_LOADING';
export const ADD_CORL_CLEARED_ASSESSMENT_SUCCESS = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_SUCCESS';
export const ADD_CORL_CLEARED_ASSESSMENT_FAILURE = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_FAILURE';
        
export const addCorlClearedAssessment = (data) => async (dispatch) => {
    dispatch({ type: ADD_CORL_CLEARED_ASSESSMENT_LOADING });
    const body = getCorlClearedAssessmentBody(data);
        try {
        const response = await axios.post("serviceprovider/vendor/product/assessmentrequest",body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: ADD_CORL_CLEARED_ASSESSMENT_FAILURE,
            });
        } 
        else {
            dispatch({
                type: ADD_CORL_CLEARED_ASSESSMENT_SUCCESS,
                payload: response?.data?.Data,
            });
            dispatch(searchSelectedProductDetails(data?.VendorOrgId, data?.ProductId));
        }
        
        } catch (err) {
        dispatch({
            type: ADD_CORL_CLEARED_ASSESSMENT_FAILURE,
            payload: getErrorObject(err)
        });
    }
}