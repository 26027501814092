import { SET_ERRORS, CLEAR_ERRORS } from "../actions/errorHandler";

const initialState = {
  list: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ERRORS: {
      return {
        ...state,
        list: payload
      };
    }

    case CLEAR_ERRORS: {
        return {
            ...state,
            list: []
        }
    }

    default:
      return state;
  }
}
