import { CLIENT_APP_ID } from "constants/users";
import { isEmpty } from "lodash";

export const getClientSearchBody = (ClientOrgId = 0) => {
  const criterion = [];
  if (ClientOrgId !== 0) {
    criterion.push({
      "field": "ClientOrgId",
      "operator": "=",
      "value": ClientOrgId
    })
  }
  return {
    "intent": "",
    "fields": [
      "ClientOrgId",
      "ClientOrgName",
      "Active",
      "DomainName",
      "Phone",
      "YearFounded",
      "LogoFileLocation",
      "NumberOfEmployeesRange",
      "RegisteredBusinessAddressLine1",
      "RegisteredBusinessAddressLine2",
      "RegisteredBusinessAddressCity",
      "RegisteredBusinessAddressCountry",
      "RegisteredBusinessAddressState",
      "RegisteredBusinessAddressZip",
      "ZendeskOrgId"
    ],
    "criterion": criterion,
    "sort": [
      {
        "field": "ClientOrgName",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0

  }
}

export const getClientListSearchBody = (searchText = '') => {
  const criterion = [
    {
      "field": "ClientOrgId",
      "operator": "<>",
      "value": "0"
    }
  ];
  if (!isEmpty(searchText.trim())) {
    criterion.push({
      "field": "ClientOrgName",
      "operator": "startswith",
      "value": searchText.trim()
    })
  }
  return {
    "intent": "",
    "fields": [
      "ClientOrgId",
      "ClientOrgName",
      "RegisteredBusinessAddressCity",
      "Active",
      "IsCORLCleared",
      "IsTPIRSubscribed",
      "ZendeskOrgId"
    ],
    "criterion": criterion,
    "sort": [
      {
        "field": "ClientOrgName",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0

  }
}

export const getClientContactBody = (clientOrgId = 0, searchText = '') => {
  const criterion = [];
  if (clientOrgId !== 0) {
    criterion.push({
      "field": "ClientOrgId",
      "operator": "=",
      "value": clientOrgId
    });
  }
  if (!isEmpty(searchText.trim())) {
    criterion.push({
      "field": "FirstName",
      "operator": "startswith",
      "value": searchText.trim()
    });
  }
  return {
    intent: "",
    fields: [
      "ClientContactId",
      "ContactTypeId",
      "ContactTypeName",
      "ContactId",
      "FirstName",
      "LastName",
      "Email",
      "Title",
      "CellPhone",
      "OfficePhone"
    ],
    criterion: criterion,
    sort: [
      {
        field: "FirstName",
        order: "ASC",
      },
    ],
    page: 0,
    pageSize: 0,
  };
}

export const getClientUserListSearchBody = (clientOrgId = 0, searchText = '') => {
  const criterion = [
    {
      "field": "ClientOrgId",
      "operator": "=",
      "value": clientOrgId
    }
  ];
  if (!isEmpty(searchText.trim())) {
    criterion.push({
      "field": "FirstName",
      "operator": "startswith",
      "value": searchText.trim()
    })
  }
  return {
    intent: "",
    fields: [
      "UserId",
      "UserEmail",
      "FirstName",
      "MiddleName",
      "LastName",
      "NamePrefix",
      "NameSuffix",
      "ProfilePictureUrl",
      "ExternalIdpId",
      "ClientAppUserList",
      "ClientOrgName"
    ],
    criterion: criterion,
    sort: [
      {
        "field": "FirstName",
        "order": "ASC"
      }
    ],
    page: 0,
    pageSize: 0
  }
}

export const getClientContractListSearchBody = (clientOrgId = 0) => {
  const criterion = [
    {
      "field": "ClientOrgId",
      "operator": "=",
      "value": clientOrgId
    }
  ];
  return {
    intent: "",
    fields: [
      "ClientContractId",
      "ClientOrgId",
      "EffectiveDate",
      "IsTPIRSubscribed",
      "TPIRVendorLimit",
      "TPIROutreachLimit",
      "IsCORLCleared",
      "AssessmentPurchasedLimit",
      "AssessmentLaneLimit",
      "ReservedLaneLimit",
      "ExpeditedAssessmentLimit",
      "BatchAssessmentLoadLimit",
      "IsPreAssessmentAllowed",
      "ContractIndustryId",
      "SharepointURL",
      "ContactFirstName",
      "ContactLastName",
      "ContactEmail",
      "InsertTimestamp",
      "ContractIndustry",
      "LaneType",
      "ClientDynamicId",
      "CustomerSupportLevel",
      "CustomerSuccessTier",
      "StakeHolderPortal",
      "ExecutiveSummary",
      "DynamicVendorRoaster",
      "CyberRiskScore",
      "VendorCollaborationScore",
      "ClientVendorInventory",
      "CorlContactId",
      "IsRemediationEnabled",
      "RemediationPreferences",
      "ReviewCorlRemRecommendationId",
      "AffordanceForAlternateRemGuidanceId",
      "ValidationPreferencesId",
      "GRCApiIntegrationId"
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0
  }
}

export const searchClientUserRoleListBody = (searchText) => {
  const criterion = [];
  if (!isEmpty(searchText)) {
    criterion.push({
      "field": "UserRoleName",
      "operator": "startswith",
      "value": searchText
    })
  }
  return {
    "intent": "",
    "fields": [
      "UserRoleId",
      "AppId",
      "UserRoleName",
      "AppName"
    ],
    "criterion": [
      ...criterion,
      {
        "field": "AppId",
        "operator": "=",
        "value": CLIENT_APP_ID
      },
      {
        "field": "Active",
        "operator": "=",
        "value": 1
      }
    ],
    "sort": []
  }
}

export const searchUserRoleListOfClientUserBody = (userId) => {
  return {
    "intent": "",
    "fields": [
      "UserRoleId",
      "AppId",
      "UserRoleName",
      "AppName"
    ],
    "criterion": [
      {
        "field": "AppId",
        "operator": "=",
        "value": CLIENT_APP_ID
      },
      {
        "field": "UserId",
        "operator": "=",
        "value": userId
      }
    ],
    "sort": []
  }
}

export const getContactDetailsSearchBody = (searchText) => {
  return {
    intent: "",
    fields: [
      "FirstName",
      "LastName",
      "Title",
      "Email",
      "CellPhone",
      "OfficePhone",
      "IsTeamContact",
      "FullName",
      "Active"
    ],
    criterion: [
      {
        "field": "Email",
        "operator": "startswith",
        "value": searchText
      }
    ],
    sort: [],
    page: 0,
    pageSize: 0
  }
}

export const getClientByClientOrgIdSearchBody = (clientOrgId) => {
  return {
    "intent": "",
    "fields": [
      "ClientOrgId",
      "ClientOrgName",
      "ClientDynamicId"
    ],
    "criterion": [
      {
        "field": "ClientOrgId",
        "operator": "=",
        "value": clientOrgId
      }
    ],
    "sort": [
      {
        "field": "ClientOrgName",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0

  }
}