export const status = (theme, ownerState) => {
    const { color = "primary" } = ownerState;

    return {
        backgroundColor: theme.palette[color].main,
        color: 'white',
        height: '26px',
        padding: '0px 12px',
        borderRadius: '100px',
        fontSize: '10px',
        fontWeight: '700',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}
