import {
  restrictSpaceAtStart,
  VALIDATIONS,
  alphaNumericNameValidation,
} from "constants/validations";
import { isEmpty } from "lodash";

export const required = (v) => (v ? undefined : "* Required");

const email = (value) =>
  value && !VALIDATIONS.emailValidation.value.test(value)
    ? VALIDATIONS.emailValidation.error
    : undefined;
const alphabets = (value) =>
  value && VALIDATIONS.nameValidation.value.test(value)
    ? VALIDATIONS.nameValidation.error
    : undefined;
const restrictNumber = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(VALIDATIONS.onlyAlphabetValidation.value, "");
};
const domainValidate = (v) =>
  !isEmpty(v) &&
  (VALIDATIONS.noSubDomainValidation?.value.test(v)
    ? undefined
    : VALIDATIONS.noSubDomainValidation?.error);
const productValidate = (v) => {
  if (!isEmpty(v) && v?.label) {
    return alphaNumericNameValidation(v?.label)
  }
}

const titleValidation = (value) => !isEmpty(value) && (VALIDATIONS.titleValidation.value.test(value) ? undefined : VALIDATIONS.titleValidation.error);


export const requestAssessmentCategoryFields = [
  {
    name: "AssessmentRequestCategoryId",
    label: "Is this a Regular Assessment or a Pre-Assessment?",
    tstype: "togglebutton",
    validate: [required],
    required: true,
  },
];

export const requestAssessmentAssessmentVRASRequestTypeFields = [
  {
    name: "AssessmentVRASRequestType",
    tstype: "select",
    required: true,
    disableUnderline: true,
    validate: [required],
    variant: "standard",
  },
];

export const requestAssessmentTypeFields = [
  {
    name: "AssessmentRequestTypeId",
    label: "Is this a CORLcleared Assessment?",
    tstype: "togglebutton",
    validate: [required],
    required: true,
  },
];

export const requestAssessmentValidationFields = [
  {
    name: 'IsAssessmentValidated',
    label: 'What type of validation is this Assessment?',
    tstype: 'togglebutton',
    validate: [required],
    required: true,
  },
];

export const vendorOverviewFields = [
  {
    label: "Vendor Name",
    placeholder: "Vendor Name",
    name: "VendorOrgName",
    tstype: "text",
    variant: "standard",
    required: true,
    validate: [required],
    maxLength: 512,
    normalize: restrictSpaceAtStart,
  },
  {
    label: "Vendor Company URL",
    placeholder: "Vendor Company URL",
    name: "VendorUrl",
    tstype: "text",
    variant: "standard",
    required: true,
    validate: [required, domainValidate],
    maxLength: 1024,
  },
  {
    label: "Vendor Headquarters Country",
    placeholder: "Vendor Headquarters Country",
    name: "VendorHeadquarterCountry",
    tstype: "select",
    variant: "standard",
    disableUnderline: true,
  },
  {
    label: "Vendor State",
    placeholder: "Vendor State",
    name: "VendorState",
    tstype: "select",
    variant: "standard",
    disableUnderline: true,
  },
];

export const vendorContactFields = [
  {
    label: "First Name",
    placeholder: "First Name",
    name: "VendorContactFirstName",
    tstype: "text",
    variant: "standard",
    required: true,
    maxLength: 64,
    validate: [required, alphabets],
    normalize: restrictNumber,
  },
  {
    label: "Last Name",
    placeholder: "Last Name",
    name: "VendorContactLastName",
    tstype: "text",
    variant: "standard",
    required: true,
    maxLength: 128,
    validate: [required, alphabets],
    normalize: restrictNumber,
  },
  {
    label: "Title",
    placeholder: "Title",
    name: "VendorContactTitle",
    tstype: "text",
    maxLength: 526,
    validate: [titleValidation],
    variant: "standard",
  },
  {
    label: "Phone Number",
    placeholder: "Phone Number",
    name: "VendorContactPhone",
    tstype: "number",
    variant: "standard",
    maxNumberLength: 15,
  },
  {
    label: "Email",
    placeholder: "Email",
    name: "VendorContactEmail",
    tstype: "text",
    variant: "standard",
    required: true,
    maxLength: 320,
    validate: [required, email],
  },
];

export const productInformationVendorProductRequestFields = [
  {
    label: 'Is this request for a vendor product or service? *',
    placeholder: 'Is this request for a vendor product or service?',
    name: 'ProductCategoryId',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
    required: true,
    validate: [required],
  },
]

export const productInformationMedicalDeviceFields = [
  {
    label: 'Is the product a medical device? *',
    placeholder: 'Is the product a medical device?',
    name: 'IsProductMedicalDevice',
    tstype: 'select',
    variant: 'standard',
    disableUnderline: true,
    required: true,
    validate: [required],
  }
]

export const productInformationLocationFields = [
  {
    label: 'At what location (e.g., facility name, division name, service line name, location name) is this product/service being implemented?',
    placeholder: 'At what location (e.g., facility name, division name, service line name, location name) is this product/service being implemented?',
    name: 'ProductLocation',
    tstype: 'text',
    variant: 'standard',
  }
]

export const productInformationProductNameFields = [
  {
    label: 'Product or Service Name *',
    placeholder: 'Product or Service Name',
    noOptionsText: "No Vendor Products Found",
    name: 'Product',
    tstype: "autocompleteWithFreeSolo",
    variant: "standard",
    required: true,
    validate: [required, productValidate],
    maxLength: 128,
  },
]

export const productInformationModelIdentifierFields = [
  {
    label: 'Model or Version Identifier',
    placeholder: 'Model or Version Identifier',
    name: 'ProductVersion',
    maxLength: 128,
    tstype: 'text',
    variant: 'standard',
  },
]

export const productInformationProductUrlFields = [
  {
    label: 'Product URL',
    placeholder: 'Product URL',
    name: 'ProductUrl',
    tstype: 'text',
    variant: 'standard',
    maxLength: 1024,
    validate: [domainValidate],
  },
]

export const productInformationDescriptionFields = [
  {
    label: 'Product or Service Description',
    placeholder: 'Product or Service Description',
    name: 'ProductDescription',
    tstype: 'text',
    variant: 'standard',
    multiline: true,
    maxLength: 1024,
    minRows: 3,
  },
];;

export const internalIdentifiersFields = [
  {
    label: "Sectors",
    placeholder: "Sectors",
    name: "Sectors",
    tstype: "text",
    variant: "standard",
  },
  {
    label: "Internal Assessment ID",
    placeholder: "Internal Assessment ID",
    name: "InternalAssessmentId",
    tstype: "text",
    variant: "standard",
    maxLength: 50,
  },
  {
    label: "Internal Client Vendor ID",
    placeholder: "Internal Client Vendor ID",
    name: "InternalClientVendorId",
    tstype: "text",
    variant: "standard",
    maxLength: 50,
  },
];
export const internalIdentifiersTwoSectionFields = [
  {
    label: "Business Domain",
    placeholder: "Business Domain",
    name: "BusinessDomain",
    tstype: "multipleselectdropdown",
    variant: "standard",
    disableUnderline: true,
  },
  {
    label: "Business Capability Group",
    placeholder: "Business Capability Group",
    name: "BusinessCapabilityGroup",
    tstype: "multipleselectdropdown",
    variant: "standard",
    disableUnderline: true,
  },
];

export const scopingInformationFields = [
  {
    name: "ScopingInformationId",
    label: "How will the scoping information be collected?",
    tstype: "togglebutton",
    validate: [required],
    required: true,
  },
];

export const securityInformationEvidenceFields = [
  {
    name: "SecurityControlInformationId",
    label: "How will Security Control Information and Evidence be collected?",
    tstype: "togglebutton",
    validate: [required],
    required: true,
  },
];

export const BusinessStakeHolderFields = [
  {
    label: "Business Stakeholder First Name",
    placeholder: "Business Stakeholder First Name",
    name: "StakeholderContactFirstName",
    tstype: "text",
    variant: "standard",
    maxLength: 64,
    validate: [alphabets],
    normalize: restrictNumber,
  },
  {
    label: "Business Stakeholder Last Name",
    placeholder: "Business Stakeholder Last Name",
    name: "StakeholderContactLastName",
    tstype: "text",
    variant: "standard",
    maxLength: 128,
    validate: [alphabets],
    normalize: restrictNumber,
  },
  {
    label: "Business Stakeholder Department",
    placeholder: "Business Stakeholder Department",
    name: "StakeholderContactDepartment",
    tstype: "text",
    maxLength: 184,
    variant: "standard",
  },
  {
    label: "Business Stakeholder Email",
    placeholder: "Business Stakeholder Email",
    name: "StakeholderContactEmail",
    tstype: "text",
    variant: "standard",
    maxLength: 320,
    validate: [email],
  },
  {
    label: "Business Stakeholder Phone",
    placeholder: "Business Stakeholder Phone",
    name: "StakeholderContactPhone",
    tstype: "number",
    variant: "standard",
    maxNumberLength: 15,
  },
];

export const singleSelectFields = [
  {
    name: "singleSelect",
    tstype: "select",
    variant: "standard",
    placeholder: "Please select",
    disableUnderline: true,
  },
];

export const multiSelectFields = [
  {
    name: "multiSelect",
    tstype: "multipleselectdropdown",
    variant: "standard",
    placeholder: "Select all that apply",
    disableUnderline: true,
  },
];

export const assessmentHelpRequestField = [
  {
    label: "Help Request *",
    placeholder: "Ticket Type",
    name: "TicketTypeId",
    tstype: "select",
    variant: "standard",
    disableUnderline: true,
    required: true,
    validate: [required],
  },
]

export const assessmentUpdateField = [
  {
    label: "Assessment Update *",
    placeholder: "Assessment Update",
    name: "AssessmentUpdateId",
    tstype: "select",
    variant: "standard",
    disableUnderline: true,
    required: true,
    validate: [required],
  },
]

export const assessmentHelpAdditionalInformationField = [
  {
    label: "Additional Information",
    placeholder: "Additional Information",
    name: "Description",
    tstype: "text",
    variant: "standard",
    multiline: true,
    minRows: 3,
  },
]

export const fileDescriptionField = [
  {
    label: "File Description",
    placeholder: "Add file description",
    name: "fileDescription",
    tstype: "text",
    variant: "standard",
    multiline: true,
    minRows: 1,
  },
];

export const commentsFields = [
  {
    label: "Additional Comments",
    placeholder: "Additional Comments",
    name: "ReviewComment",
    tstype: "text",
    variant: "standard",
    multiline: true,
    minRows: 3,
  },
];
