import axios from "axios";
import { setErrors } from "./errorHandler";
import { getErrorObject } from "utils/apiUtils";
import { NO_DATA_FOUND } from "constants/errorMessage";
import { getVendorsListSearchBody } from "utils/replaceVendorUtils";
import { getAssessmentRequestSearchBody } from "utils/assessmentRequestsUtils";
import { setAssessmentRequestStatus } from "./assessmentRequests";
import { ASSESSMENT_REQUEST_SUBMITTED } from "pages/AssessmentRequest/AssessmentRequestReview/constants";
import { NO_VENDOR_FOUND } from "pages/ReplaceVendor/constants";

export const SEARCH_VENDORS_LIST_LOADING = "@@replaceVendor/SEARCH_VENDORS_LIST_LOADING";
export const SEARCH_VENDORS_LIST_SUCCESS = "@@replaceVendor/SEARCH_VENDORS_LIST_SUCCESS";
export const SEARCH_VENDORS_LIST_FAILURE = "@@replaceVendor/SEARCH_VENDORS_LIST_FAILURE";

export const getVendorSearchList = (formData) => async (dispatch) => {
    dispatch({
        type: SEARCH_VENDORS_LIST_LOADING,
    });

    const reqBody = formData;

    const body = getVendorsListSearchBody(reqBody);
    try {
        const res = await axios.post(`/app/customer/vendor/fulltext/search`, body);
        if (res?.data?.ErrorCount > 0) {
            const errors = res?.data?.Errors.filter(
                (it) => it.Message !== NO_DATA_FOUND
            );
            const noDataError = res?.data?.Errors.filter(
                (it) => it.Message === NO_DATA_FOUND
            );
            if (noDataError) {
                dispatch(setNoVendorFound(NO_VENDOR_FOUND))
            }
            dispatch(setErrors(errors));
            dispatch({
                type: SEARCH_VENDORS_LIST_FAILURE,
                payload: [],
            });
        } else {
            dispatch({
                type: SEARCH_VENDORS_LIST_SUCCESS,
                payload: res?.data,
            });
            dispatch(setNoVendorFound(""))
        }
    } catch (err) {
        dispatch({
            type: SEARCH_VENDORS_LIST_FAILURE,
            payload: getErrorObject(err),
        });
    }
};

export const SET_VENDORS_LIST = "@@replaceVendor/SET_VENDORS_LIST";

export const setVendorSearchList = (val = []) => async (dispatch) => {
    dispatch({
        type: SET_VENDORS_LIST,
        payload: val,
    });
}

export const SEARCH_ASSESSMENT_REQUEST_LOADING =
    "@@replaceVendor/SEARCH_ASSESSMENT_REQUEST_LOADING";
export const SEARCH_ASSESSMENT_REQUEST_SUCCESS =
    "@@replaceVendor/SEARCH_ASSESSMENT_REQUEST_SUCCESS";
export const SEARCH_ASSESSMENT_REQUEST_FAILURE =
    "@@replaceVendor/SEARCH_ASSESSMENT_REQUEST_FAILURE";

export const searchAssessmentRequest = (id) => async (dispatch) => {
    dispatch({
        type: SEARCH_ASSESSMENT_REQUEST_LOADING,
    });
    const body = getAssessmentRequestSearchBody(id)
    try {
        const res = await axios.post(`/serviceprovider/assessmentrequest/search`, body);
        if (res?.data?.ErrorCount > 0) {
            const errors = res?.data?.Errors.filter(
                (it) => it.Message !== NO_DATA_FOUND
            );
            dispatch(setErrors(errors));
            dispatch({
                type: SEARCH_ASSESSMENT_REQUEST_FAILURE,
                payload: {},
            });
        } else {
            dispatch({
                type: SEARCH_ASSESSMENT_REQUEST_SUCCESS,
                payload: res?.data,
            });
        }
    } catch (err) {
        dispatch({
            type: SEARCH_ASSESSMENT_REQUEST_FAILURE,
            payload: getErrorObject(err),
        });
    }
};

export const PUT_ASSESSMENT_REQUEST_LOADING =
    "@@replaceVendor/PUT_ASSESSMENT_REQUEST_LOADING";
export const PUT_ASSESSMENT_REQUEST_SUCCESS =
    "@@replaceVendor/PUT_ASSESSMENT_REQUEST_SUCCESS";
export const PUT_ASSESSMENT_REQUEST_FAILURE =
    "@@replaceVendor/PUT_ASSESSMENT_REQUEST_FAILURE";

export const updateAssessmentRequest = (formData, statusId, callback) => async (dispatch) => {
    dispatch({
        type: PUT_ASSESSMENT_REQUEST_LOADING,
    });

    let setStatusFormData = {
        "AssessmentRequestId": formData?.AssessmentRequestId,
        "AssessmentStatus": statusId,
        "ReviewComment": `VendorName changed - ${formData?.ReplacementReasonForVendor}`
    }

    const tempFormData = {
        ...formData,
        AssessmentStatusId: statusId,
    }

    let body = new FormData();
    Object.keys(tempFormData).forEach((key) => {
        body.append(key, JSON.stringify(tempFormData[key]));
    });


    try {
        const res = await axios.put(`/serviceprovider/assessmentrequest/${formData?.AssessmentRequestId}`, body, {
            headers: {
                "Content-type": "multipart/form-data",
            },
        });
        if (res?.data?.ErrorCount > 0) {
            const errors = res?.data?.Errors.filter(
                (it) => it.Message !== NO_DATA_FOUND
            );
            dispatch(setErrors(errors));
            dispatch({
                type: PUT_ASSESSMENT_REQUEST_FAILURE,
                payload: {},
            });
        } else {
            dispatch({
                type: PUT_ASSESSMENT_REQUEST_SUCCESS,
                payload: res?.data,
            });
            if (setStatusFormData?.AssessmentStatus !== ASSESSMENT_REQUEST_SUBMITTED) {
                dispatch(setAssessmentRequestStatus(setStatusFormData))
            }
            if (callback) {
                callback();
            }
        }
    } catch (err) {
        dispatch({
            type: PUT_ASSESSMENT_REQUEST_FAILURE,
            payload: getErrorObject(err),
        });
    }
};

export const SET_NO_VENDOR_FOUND = "@@replaceVendor/SET_NO_VENDOR_FOUND";

export const setNoVendorFound = (val = "") => async (dispatch) => {
    dispatch({
        type: SET_NO_VENDOR_FOUND,
        payload: val
    });
}