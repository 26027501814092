import { VENDOR_APP_ID } from "constants/users"
import { isEmpty } from "lodash"

export const searchVendorUsersBody = (vendorOrgId) => {
    return{
        "intent": "",
        "fields": [
           "UserId",
           "UserEmail",
           "FirstName",
           "MiddleName",
           "LastName",
           "Title",
           "NamePrefix",
           "NameSuffix",
           "ProfilePictureUrl",
           "ExternalIdpId",
           "VendorAppUserList",
           "VendorOrgName"
        ],
        "criterion": [ 
            {
              "Field":"VendorOrgId",
              "Operator": "=",
              "Value" : vendorOrgId
            }
            ],
        "sort": [ {
          "field": "FirstName",
          "order": "ASC",
        },],
        "page": 0,
        "pageSize": 0
      }
}



export const searchVendorProfileSpecificUsersBody = (searchValue, vendorOrgId) => {
    return{
        "intent": "",
      "fields": [
        "UserId",
        "UserEmail",
        "FirstName",
        "MiddleName",
        "LastName",
        "NamePrefix",
        "NameSuffix",
        "ProfilePictureUrl",
        "ExternalIdpId",
        "VendorAppUserList",
        "VendorOrgName"
      ],
      "criterion": [
        {
          "field": "FirstName",
          "operator":  "startswith",
          "value": searchValue,
        },
        {
          "field": "VendorOrgId",
          "operator":  "=",
          "value": vendorOrgId,
          }
      ],
      "sort": [
        {
          "field": "FirstName",
          "order": "ASC",
        },
      ],
      "page": 0,
      "pageSize": 0,
    }
}

export const searchVendorUserRoleListBody = (searchText) => {
  const criterion = [];
  if (!isEmpty(searchText)) {
    criterion.push({
      "field": "UserRoleName",
      "operator": "startswith",
      "value": searchText
    })
  }
  return {
    "intent": "",
    "fields": [
      "UserRoleId",
      "AppId",
      "UserRoleName",
      "AppName"
    ],
    "criterion": [
      ...criterion,
        {
            "field": "AppId",
            "operator": "=",
            "value": VENDOR_APP_ID
        },
        {
          "field": "Active",
          "operator": "=",
          "value": 1
        }
    ],
    "sort": []
  }
}

export const searchUserRoleListOfVendorUserBody = (userId) => {
  return {
    "intent": "",
    "fields": [
      "UserRoleId",
      "AppId",
      "UserRoleName",
      "AppName"
    ],
    "criterion": [
      {
        "field": "AppId",
        "operator": "=",
        "value": VENDOR_APP_ID
      },
      {
        "field": "UserId",
        "operator": "=",
        "value": userId
      }
    ],
    "sort": []
  }
}