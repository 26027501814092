import {
    POST_VENDOR_USER_TO_LIST_LOADING,
    POST_VENDOR_USER_TO_LIST_SUCCESS,
    POST_VENDOR_USER_TO_LIST_FAILURE,
    PUT_VENDOR_USER_TO_LIST_LOADING,
    PUT_VENDOR_USER_TO_LIST_SUCCESS,
    PUT_VENDOR_USER_TO_LIST_FAILURE,
    SET_VENDOR_USERS_LOADING,
    SET_VENDOR_USERS_SUCCESS,
    SET_VENDOR_USERS_FAILURE,
    SEARCH_VENDOR_USERS_LOADING,
    SEARCH_VENDOR_USERS_SUCCESS,
    SEARCH_VENDOR_USERS_FAILURE,
    SEARCH_VENDOR_SPECIFIC_USER_LOADING,
    SEARCH_VENDOR_SPECIFIC_USER_SUCCESS,
    SEARCH_VENDOR_SPECIFIC_USER_FAILURE,
    DELETE_VENDOR_USER_LOADING,
    DELETE_VENDOR_USER_SUCCESS,
    DELETE_VENDOR_USER_FAILURE,
    POST_RESEND_INVITE_USER_LOADING,
    POST_RESEND_INVITE_USER_FAILURE,
    POST_RESEND_INVITE_USER_SUCCESS
  } from "../actions/vendorUsers";
  import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { UPDATED_SUCCESSFULLY_MESSAGE, SAVE_SUCCESSFULLY_MESSAGE, ERROR_MESSAGE, DELETED_USER_SUCCESSFULLY_MESSAGE, RESEND_INVITE_SUCCESSFULLY } from "components/Common/AlertMessages";
  
  const initialState = {
    loading: false,
    userDetails: {},
  };
  
  export default function vendorUsers(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case SEARCH_VENDOR_USERS_LOADING: {
        return {
          ...state,
          loading: true,
        };
      }
      case SEARCH_VENDOR_USERS_SUCCESS: {
        return {
          ...state,
          loading: false,
          userDetails: {
            ...state.userDetails,
            vendorUserDetailsList: payload,
          },
        };
      }
      case SEARCH_VENDOR_USERS_FAILURE: {
        return {
          ...state,
          loading: false,
          userDetails: {},
        };
      }
      case SEARCH_VENDOR_SPECIFIC_USER_LOADING: {
        return {
          ...state,
          loading: true, 
        };
      }
      case SEARCH_VENDOR_SPECIFIC_USER_SUCCESS: {
          return {
            ...state,
            loading: false,
            userDetails: {
              ...state.userDetails,
              vendorUserDetailsList: payload,
            },
          };
      }
      case SEARCH_VENDOR_SPECIFIC_USER_FAILURE: {
        return {
          ...state,
          loading: false,         
          userDetails: {},
        };
      }
      case POST_VENDOR_USER_TO_LIST_LOADING: {
        return {
          ...state,
          loading: true,
        };
      }
      case POST_VENDOR_USER_TO_LIST_SUCCESS: {
        const userDetailsClone = cloneDeep(state.userDetails);
        const userList = userDetailsClone.vendorUserDetailsList ?? [];
        userList.push(payload);
        toast.success(SAVE_SUCCESSFULLY_MESSAGE)
        return {
          ...state,
          loading: false,
          userDetails: {
            ...state.userDetails,
            vendorUserDetailsList: userList,
            userToBeSelected: payload.UserId,
          },
        };
      }
      case POST_VENDOR_USER_TO_LIST_FAILURE: {
        return {
          ...state,
          loading: false,
        };
      }
      case PUT_VENDOR_USER_TO_LIST_LOADING: {
        return {
          ...state,
          loading: true,
        };
      }
      case PUT_VENDOR_USER_TO_LIST_SUCCESS: {
        let userDetailsClone = cloneDeep(state.userDetails);
        let userList = userDetailsClone.vendorUserDetailsList ?? [];
        userList[payload.formData.itemToBeUpdatedIndex] =
          payload.formData.editedFormData;
          toast.success(UPDATED_SUCCESSFULLY_MESSAGE)
        return {
          ...state,
          loading: false,
          userDetails: {
            ...state.userDetails,
            vendorUserDetailsList: userList,
            userToBeSelected: null,
          },
        };
      }
      case PUT_VENDOR_USER_TO_LIST_FAILURE: {
        return {
          ...state,
          loading: false,
        };
      }
      case SET_VENDOR_USERS_LOADING: {
        return {
          ...state,
          loading: true,
        };
      }
      case SET_VENDOR_USERS_SUCCESS: {
        return {
          ...state,
          loading: false,
          userDetails: {
            ...state.userDetails,
            ...payload,
          },
        };
      }
      case SET_VENDOR_USERS_FAILURE: {
        return {
          ...state,
          loading: false,
        };
      }
      case DELETE_VENDOR_USER_LOADING: {
        return {
          ...state,
          loading: true
        }
      }
      case DELETE_VENDOR_USER_FAILURE: {
        toast.error(ERROR_MESSAGE)
        return {
          ...state,
          loading: false
        }
      }
      case DELETE_VENDOR_USER_SUCCESS: {
        toast.success(DELETED_USER_SUCCESSFULLY_MESSAGE);
        return {
          ...state,
          loading: false
        }
      }
      case POST_RESEND_INVITE_USER_LOADING: {
        return {
          ...state,
          loading: true
        }
      }
      case POST_RESEND_INVITE_USER_FAILURE: {
        toast.error(ERROR_MESSAGE)
        return {
          ...state,
          loading: false
        }
      }
      case POST_RESEND_INVITE_USER_SUCCESS: {
        toast.success(RESEND_INVITE_SUCCESSFULLY);
        return {
          ...state,
          loading: false
        }
      }
      default:
        return state;
    }
  }
  