import {
    SEARCH_VENDORS_LIST_LOADING,
    SEARCH_VENDORS_LIST_SUCCESS,
    SEARCH_VENDORS_LIST_FAILURE,
    SET_VENDORS_LIST,
    SEARCH_ASSESSMENT_REQUEST_LOADING,
    SEARCH_ASSESSMENT_REQUEST_SUCCESS,
    SEARCH_ASSESSMENT_REQUEST_FAILURE,
    PUT_ASSESSMENT_REQUEST_LOADING,
    PUT_ASSESSMENT_REQUEST_SUCCESS,
    PUT_ASSESSMENT_REQUEST_FAILURE,
    SET_NO_VENDOR_FOUND
} from "actions/replaceVendor";
import { ASSESSMENT_REQUEST_UPDATED_SUCCESSFULLY, ERROR_MESSAGE } from "components/Common/AlertMessages";
import { toast } from "react-toastify";

const initialState = {
    loading: false,
    vendorSearchList: [],
    assessmentRequest: {},
    noVendorFound: ""
};

export default function replaceVendor(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case SEARCH_VENDORS_LIST_LOADING:
        case SEARCH_ASSESSMENT_REQUEST_LOADING:
        case PUT_ASSESSMENT_REQUEST_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case SEARCH_VENDORS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                vendorSearchList: payload?.Data?.Rows,
            };
        }

        case SEARCH_VENDORS_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                vendorSearchList: [],
            };
        }

        case SET_VENDORS_LIST: {
            return {
                ...state,
                vendorSearchList: payload
            }
        }

        case SEARCH_ASSESSMENT_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                assessmentRequest: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0]
            }
        }

        case SEARCH_ASSESSMENT_REQUEST_FAILURE: {
            return {
                ...state,
                loading: false,
                assessmentRequest: {}
            }
        }

        case PUT_ASSESSMENT_REQUEST_SUCCESS: {
            toast.success(ASSESSMENT_REQUEST_UPDATED_SUCCESSFULLY)
            return {
                ...state,
                loading: false,

            }
        }

        case PUT_ASSESSMENT_REQUEST_FAILURE: {
            toast.error(ERROR_MESSAGE)
            return {
                ...state,
                loading: false,
            }
        }

        case SET_NO_VENDOR_FOUND: {
            return {
                ...state,
                noVendorFound: payload
            }
        }

        default:
            return state;
    }
}
