export const SET_MENU = '@navabr/SET_MENU';

export const setMenu = (menuList) => async dispatch => {

    let list = [];

    list = menuList?.filter((menu) => {
        return menu.ParentAppMenuId === 0;
    })

    list.forEach(listMenu => {
        listMenu.SubMenuList = [];
        menuList.forEach(menu => {
            if (menu.ParentAppMenuId == listMenu.AppMenuId) {
                listMenu.SubMenuList.push(menu);
            }
        })
    })

    list.sort((a, b) => a.DisplaySequenceNumber - b.DisplaySequenceNumber);

    dispatch({
        type: SET_MENU,
        payload: list
    })
}

export const SET_MENU_PANEL_ID = '@navabr/SET_MENU_PANEL_ID';

export const setMenuPanelId = (menuPanelId = 0) => async dispatch => {
    dispatch({
        type: SET_MENU_PANEL_ID,
        payload: menuPanelId
    })
}