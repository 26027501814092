export const searchCommentsBody = () => {
    return {
    "intent": "",
    "fields": [
        "ReviewLogId",
        "EntityId",
        "Status",
        "ReviewComment",
        "Active",
        "InsertUserId",
        "InsertTimestamp",
        "FirstName",
        "LastName"
    ],
    "criterion": [],
    "sort": [],
    "page": 0,
    "pageSize": 0
    }
}