export const getIncidentListSearchBody = () => {
    return {
      "intent": "",
      "fields": 
      [
        "IncidentName",
	      "DateFirstReported",
	      "Description",
	      "IncidentType",
	      "IsQuestionnaireRequired",
	      "Active",
        "UpdateTimeStamp",
        "Status"
       ],
      "criterion": [],
      "sort": [
        {
          "field": "UpdateTimeStamp",
          "order": "DESC"
        }
      ],
      "page": 0,
      "pageSize": 0
    
    }
}

export const getIncidentListSearchBodyOnName = (searchIncidentName) => {
  return {
    "intent": "",
    "fields": 
    [
      "IncidentName",
      "DateFirstReported",
      "Description",
      "IncidentType",
      "IsQuestionnaireRequired",
      "Active",
      "UpdateTimeStamp",
      "Status"
     ],
    "criterion": 
     [
      {
          "field": "IncidentName",
          "operator": "startswith",
          "value": searchIncidentName
      }
    ],
    "sort": [
      {
        "field": "UpdateTimeStamp",
        "order": "DESC"
      }
    ],
    "page": 0,
    "pageSize": 0
  
  }
}