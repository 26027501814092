import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "./components";
import ThemeConfig from "./theme";
import Router from "./routes";
import NavBar from "./components/NavBar/NavBar";
import ErrorHandlerModal from "./components/ErrorHandlerModal/ErrorHandlerModal";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSessionDetails } from "./utils/localStorage";
import {
  getSession,
  setSessionDetails,
  verifySession,
} from "./actions/session";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useIdleTimer } from "react-idle-timer";
import {
  IDLE_TIMEOUT_MESSAGE,
  IDLE_TIMER_SYNC_TIMERS,
  IDLE_TIMER_THROTTLE,
  IDLE_TIMER_TIMEOUT,
  TOKEN_CHECK_TIME_INTERVAL,
  TOKEN_UPDATE_TIME_WINDOW,
} from "constants/constants";
import CommonFooter from "components/CommonFooter";
import { isTokenExpired } from "constants/tokenValidity";
import { datadogRum } from "@datadog/browser-rum";

const App = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  let history = useHistory();
  const dispatch = useDispatch();

  const [showNavbar, setShowNavbar] = useState(true);
  const details = useSelector((state) => state.session.details);
  const [isTokenExpire, setIsTokenExpire] = useState(false);

  axios.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("corl-token") || "";
      const isExpired = isTokenExpired(token);

      if (isExpired) {
        setIsTokenExpire(true);
        if (
          config?.url === "/app/usersession" &&
          (config?.method === "post" || config?.method === "get") &&
          window.localStorage.getItem("sessionInProgress") !== "yes"
        ) {
          const data = {
            appName: process.env.REACT_APP_NAME,
          };
          await dispatch(
            getSession(data, getAccessTokenSilently, null, () => {
              history.push({
                pathname: "/unauthorized",
                state: {
                  text: "Your session is expired. You have been logged out. Please login again.",
                },
              });
            })
          );
          window.location.reload();
          isExpired = false;
          setIsTokenExpire(false);
          setShowNavbar(true);
        } else if (
          config?.url === "/app/usersession" &&
          (config?.method === "post" || config?.method === "get")
        ) {
          return config;
        } else if (window.localStorage.getItem("sessionInProgress") === "yes") {
          throw new axios.Cancel("cancel");
        } else {
          const data = {
            appName: process.env.REACT_APP_NAME,
          };
          await dispatch(
            getSession(data, getAccessTokenSilently, null, () => {
              history.push({
                pathname: "/unauthorized",
                state: {
                  text: "Your session is expired. You have been logged out. Please login again.",
                },
              });
            })
          );
          window.location.reload();
          isExpired = false;
          setIsTokenExpire(false);
          setShowNavbar(true);
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(undefined, function (error) {
    let errors = error?.response?.data?.Errors;
    let errorList = errors?.map((err) => err?.Message);

    if (error.response.status === 401) {
      localStorage.removeItem("corl-token");
      setShowNavbar(false);
      history.push({
        pathname: "/unauthorized",
        state: {
          text: "Your session is expired. You have been logged out. Please login again.",
        },
      });
    } else if (error.response.status === 500) {
      if (errorList.length > 0) {
        const item = errorList?.find(
          (it) =>
            it.toLowerCase().includes("session has expired") ||
            it.toLowerCase().includes("unauthorized access")
        );
        if (item) {
          localStorage.removeItem("corl-token");
          setShowNavbar(true);
          history.push({
            pathname: "/error",
            state: {
              errors: errorList,
              logoutUser: true,
            },
          });
        }
      } else {
        setShowNavbar(true);
        history.push({
          pathname: "/error",
          state: {
            errors: errorList,
            logoutUser: false,
          },
        });
      }
    }
    return Promise.reject(error);
  });

  const onIdle = () => {
    history.push({
      pathname: "/error",
      state: {
        idleErrorMessage: IDLE_TIMEOUT_MESSAGE,
        logoutUser: true,
      },
    });
  };

  const IdleTimer = useIdleTimer({
    onIdle,
    timeout: IDLE_TIMER_TIMEOUT,
    throttle: IDLE_TIMER_THROTTLE,
    crossTab: true,
    leaderElection: true,
    syncTimers: IDLE_TIMER_SYNC_TIMERS,
  });

  useEffect(() => {
    if (isNil(localStorage.getItem("corl-token"))) {
      setShowNavbar(false);
    } else {
      dispatch(verifySession());
      setShowNavbar(true);
    }
  }, [details]);

  useEffect(() => {
    const sessionDetails = getSessionDetails();
    dispatch(setSessionDetails(sessionDetails));
  }, []);

  useEffect(() => {
    const envName = process.env.REACT_APP_ENVIRONMENT;
    datadogRum.init({
      applicationId: 'e18584ac-f3fe-489a-9e15-e5661809bb5f',
      clientToken: 'pubabcd0f5d88c51d868dd9616bc0744a53',
      site: 'datadoghq.com',
      service:'corl-corl-portal',
      env: envName,
      // Specify a version number to identify the deployed version of your application in Datadog 
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'mask-user-input'
    });
    
    if (envName !== 'DEVELOPMENT') {
      datadogRum.startSessionReplayRecording();
    }

    const interval = setInterval(() => {
      //check the token expiry beforehand
      //if the token will be expiring in TOKEN_UPDATE_TIME_WINDOW,
      //Obtain a new token and create a new session
      const token = localStorage.getItem("corl-token") || "";
      const willBeExpired = isTokenExpired(token, TOKEN_UPDATE_TIME_WINDOW);

      if (willBeExpired) {
        const data = {
          appName: process.env.REACT_APP_NAME,
        };
        dispatch(
          getSession(
            data,
            getAccessTokenSilently,
            null,
            () => {
              history.push({
                pathname: "/unauthorized",
                state: {
                  text: "Your session is expired. You have been logged out. Please login again.",
                },
              });
            },
            true
          )
        );
      }
    }, TOKEN_CHECK_TIME_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <ThemeConfig>
      {isTokenExpire ? (
        <Loading />
      ) : (
        <div id="app" className="d-flex flex-column h-100">
          {showNavbar && <NavBar />}
          <div className="flex-grow-1 app-main-content">
            <Router />
          </div>
          {showNavbar && <CommonFooter />}
        </div>
      )}
      <ErrorHandlerModal />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
        theme="light"
        bodyClassName="toastBody"
      />
    </ThemeConfig>
  );
};

export default App;
