import {
    GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST,
    GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS,
    GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL,
    SET_QUESTIONNAIRE_TEMPLATE_DETAILS
 } from '../actions/getQuestionnaireTemplate';

 import { format } from 'date-fns';
import { isNil } from 'lodash';


 const initialState = {
    loading: false,
    questionnaireDetails: {}
 }
 
 const convertToLocalTime = (str) => {
    const globalTime          =   new Date(`${str}`+'Z');
    const formattedTime       =   format((globalTime), 'MM/dd/yy')
    return formattedTime
}


 export default function (state = initialState, action) {
    const { type, payload } = action;
    switch(type) {
        case GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }

        case GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                questionnaireDetails: {
                    ...payload?.Data,
                    CreatedDate: !isNil(payload?.Data.InsertTimestamp) && convertToLocalTime(payload?.Data.InsertTimestamp),
                    LastEdited :  !isNil(payload?.Data.LastUpdated) && convertToLocalTime(payload?.Data.LastUpdated)
                }
            }
        }

        case SET_QUESTIONNAIRE_TEMPLATE_DETAILS: {
            return {
                ...state,
                questionnaireDetails: payload
            }
        }

        case GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL: {
            return {
                ...state,
                loading: false
            }
        }
        default : return state;
    }
 }