import { TRUE } from "constants/constants";
import { isEmpty } from "lodash";

export const getVendorsListSearchBody = (formData = {}) => {
    let namwWebsiteUrl = formData?.VendorName;
    if (!isEmpty(formData?.WebsiteUrl)) {
        namwWebsiteUrl = `+${namwWebsiteUrl} ${formData?.WebsiteUrl}`
    }
    return {
        "Intent": "",
        "Fields": [
            "VendorOrgId",
            "VendorOrgName",
            "DomainName",
            "FullTextSearch",
            "IsAssociatedWithCustomer",
            "CustomerVendorRowLimit",
            "AssessmentRequestVendorRowLimit"
        ],
        "Criterion": [
            {
                "Field": "Org_DomainName",
                "Operator": "=",
                "Value": namwWebsiteUrl
            },
            {
                "Field": "IsVerified",
                "Operator": "=",
                "Value": TRUE
            },
            {
                "Field": "Active",
                "Operator": "=",
                "Value": TRUE
            }
        ],
        "Sort": [],
        "Page": 0,
        "PageSize": 0
    }
}