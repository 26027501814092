export const modalStyle = () => {
    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
    };
};


export const buttonRightMargin = () => {
    return {
      marginRight: "10px",
    };
  };
  

export const buttonStyle = () => {
    return {
        display : "flex",
        justifyContent : "end",
        alignItems : "center"
    };
};

export const errorData = (theme) => {
    return {
        mt : 2,
        mb : 2,
        fontFamily : `${theme.typography.fontFamily}`
    };
};

export const spaceBottom = (ownerState) => {
    return {
      marginBottom: `${ownerState}px`,
    };
  };

export const spaceLeft = (ownerState) => {
  return {
    marginLeft: `${ownerState}px`,
  };
};
