import { DRAFT_STATUS, QA_APPROVED_STATUS, READY_FOR_QA_STATUS } from '../pages/IncidentBuilder/constants'

export const getIncidentListSearchBody = () => {
    return {
      "intent": "",
      "fields": 
      [
        "IncidentName",
	    "IncidentType",
        "Status"
       ],
      "criterion": 
       [
        {
            "field": "IncidentStatusId",
            "operator": "in",
            "value": `${DRAFT_STATUS}, ${READY_FOR_QA_STATUS}, ${QA_APPROVED_STATUS}`    
          }
      ],
      "sort": [],
      "page": 0,
      "pageSize": 0
    
    }
}

export const getQuestionnaireListSearchBody = () => {
  return {
    intent: "",
    fields: [
      "Status",
      "QuestionnaireTemplateName",
      "QuestionnaireTemplateType",
      "ReferenceId",
      "ReferenceName",
      "QuestionCount",
      "LastUpdated",
      "QuestionnaireTemplateId",
      "LastEditedUserId",
      "LastEditedUserName"
    ],
    criterion: [],
    "sort": [
      {
        "field": "LastUpdated",
        "order": "DESC"
      }
    ],
    page: 0,
    pageSize: 0,
  };
}

export const getQuestionnaireListSearchBodyOnName = (searchQuestionnaireName) => {
return {
  "intent": "",
  "fields": [
    "Status",
    "QuestionnaireTemplateName",
    "QuestionnaireTemplateType",
    "ReferenceId",
    "ReferenceName",
    "QuestionCount",
    "LastUpdated",
    "QuestionnaireTemplateId",
    "LastEditedUserId",
    "LastEditedUserName"
  ],
  "criterion": 
   [
    {
        "field": "QuestionnaireTemplateName",
        "operator": "startswith",
        "value": searchQuestionnaireName
    }
  ],
  "sort": [
    {
      "field": "LastUpdated",
      "order": "DESC"
    }
  ],
  "page": 0,
  "pageSize": 0

}
}