// src/auth/protected-route.js

import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired,  } from "@auth0/auth0-react";
import { Loading } from "../components/index";
import { useSelector } from "react-redux";
import { ROUTE_CLAIMS_LIST } from "constants/userClaims";
import { hasClaims } from "hooks/useHasClaims";
import { isEmpty, isUndefined } from "lodash";
import { AccessDenied } from "components/AccessDenied";

const ProtectedRoute = ({ component, ...args }) => {
  const { path } = args;
  const userClaimList = useSelector(state => state?.session?.details?.FeatureActionClaimList);
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    const currentRoute = ROUTE_CLAIMS_LIST.find(item => path === item.route);
    if (!isEmpty(currentRoute) && currentRoute?.claims?.length > 0 && !isUndefined(userClaimList)) {
      if (!hasClaims(currentRoute?.claims, userClaimList)) {
        setAccessDenied(true);
      }
    }
  }, [userClaimList, path]);

  if (accessDenied) {
    return <Route component={AccessDenied} path={path} {...args} />
  }

  return (
  <Route
    component={withAuthenticationRequired(
      component, {
      onRedirecting: () => <Loading />,
    })}
    {...args}
  />
)}

export default ProtectedRoute;
