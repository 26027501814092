import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';


export const SEARCH_CUSTOMERS_PRODUCTS_LIST_LOADING = '@@customersAndProducts/SEARCH_CUSTOMERS_PRODUCTS_LIST_LOADING';
export const SEARCH_CUSTOMERS_PRODUCTS_LIST_SUCCESS = '@@customersAndProducts/SEARCH_CUSTOMERS_PRODUCTS_LIST_SUCCESS';
export const SEARCH_CUSTOMERS_PRODUCTS_LIST_FAILURE = '@@customersAndProducts/SEARCH_CUSTOMERS_PRODUCTS_LIST_FAILURE';

export const getCustomersAndProductsList = (vendorOrgId) => async (dispatch) => {

    dispatch({ type: SEARCH_CUSTOMERS_PRODUCTS_LIST_LOADING });
    try {
        const response = await axios.get(`app/vendor/customerproduct?vendorOrgId=${vendorOrgId}`);
        if (response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: SEARCH_CUSTOMERS_PRODUCTS_LIST_FAILURE,
                payload: []
            });
        }
        else {
            dispatch({
                type: SEARCH_CUSTOMERS_PRODUCTS_LIST_SUCCESS,
                payload: response.data
            });
        }

    } catch (err) {
        dispatch({
            type: SEARCH_CUSTOMERS_PRODUCTS_LIST_FAILURE,
            payload: getErrorObject(err)
        });
    }

};


export const SET_SELECTED_CUSTOMER_AND_PRODUCT = "@@customersAndProducts/SET_SELECTED_CUSTOMER_AND_PRODUCT";

export const setSelectedCustomerAndProduct = (val) => async dispatch => {
    dispatch({
        type: SET_SELECTED_CUSTOMER_AND_PRODUCT,
        payload: val
    });
}
