import bannerImage from "../../assets/images/corl-pattern.jpg";

export const breadcrumbLabel = () => {
    return {
        fontFamily : "Roboto",
        fontWeight : "700",
        fontSize : "14px",
        lineHeight: "16px",
        letterSpacing: "0.05em",
        color : "primary.main",
        "&:hover" : {
            color : "primary.main"
          }
    }
}

export const buttonsGrid = (theme) => {
    return {
      alignItems: "center",
      display: 'flex',
      justifyContent: 'end',
      "& .isDisabled" : {
        backgroundColor : `${theme.palette.primary.contrastText}`
      }
    };
};

  export const bannerContainerWrap = () => {
    return {
        height: '125px',
        display : "flex" ,
        padding: "0px 80px",
        flexDirection : "column",
        justifyContent : "center",
        backgroundImage: `url(${bannerImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    }
}
  
  
export const breadCrumbs = () => {
  return {
    marginBottom: "17px",
  };
};

export const bannerTitleStyles = (theme, breadcrumbs) => {
  return {
      fontWeight: "300",
      fontSize: "30px !important",
      lineHeight: breadcrumbs ? "32px" : "",
      fontFamily: "Roboto",
      color: theme.palette.common.white,
      overflowWrap: "break-word",
      letterSpacing: "0.05em",
      textAlign: "left",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
      };
};

export const bannerButtonStyles = () => {
  return {
    fontSize:'12px', 
    lineHeight: '18px !important', 
    fontFamily: 'Roboto' 
  };
};

