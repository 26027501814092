import { isArray, isEmpty, isString, omit } from "lodash";

const getMappedSearchCriterion = (searchData) => {
    const criterion = [];
    Object.keys(searchData)?.forEach(key => {
        if (isString(searchData[key]) && !isEmpty(searchData[key])) {
            criterion.push(
                {
                    "Field": key,
                    "Operator": "startswith",
                    "Value": searchData[key]
                }
            )
        }
        if (isArray(searchData[key]) && searchData[key]?.length > 0) {
            criterion.push(
                {
                    "Field": key,
                    "Operator": "in",
                    "Value": searchData[key].join(',')
                }
            )
        }
    });
    return criterion;
}

const getAssessmentSortBody = (sortModel) => {
    let sort = [];
    sortModel?.map((item) => {
        let obj = {};
        if (item?.field === "LastStatusActivity") {
            obj = {
                field: "LastUpdated",
                order: item?.sort?.toUpperCase()
            }
        }
        else {
            obj = {
                ...item,
                order: item?.sort?.toUpperCase()
            }
        }
        const omittedData = omit(obj, "sort")
        sort.push(omittedData)
    })

    return sort
}

export const getAssessmentQueueSearchBody = (searchData = {}) => {
    let criterion = [];
    if (!isEmpty(searchData)) {
        criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    }
    return {
        "intent": "",
        "fields": [
            "AssessmentRequestId",
            "AssessmentRequestType",
            "AssessmentStatus",
            "ClientOrgId",
            "ClientOrgName",
            "VendorOrgId",
            "VendorOrgName",
            "ProductName",
            "ProductType",
            "ProductCategory",
            "InsertTimestamp",
            "IsVerified"
        ],
        "criterion": criterion,
        "sort": [
            {
                field: "InsertTimestamp",
                order: "DESC",
            },
        ],
        "page": 0,
        "pageSize": 0
    }
}

export const getAssessmentListBody = (searchData = {}, page, pageSize, sortModel = []) => {
    let criterion = [];
    if (!isEmpty(searchData)) {
        criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    }

    let sort = getAssessmentSortBody(sortModel);

    return {
        "intent": "",
        "fields": [
            "ClientOrgId",
            "AssessmentName",
            "ClientId",
            "ProductId",
            "ProductName",
            "VendorId",
            "VendorName",
            "MasterStepName",
            "AssessmentStatus",
            "AssessmentStage",
            "AssessmentStartDate",
            "AssessmentCompletedDate",
            "DynVendorAccountId",
            "AssessmentDisplayStatus",
            "Owner",
            "Domain",
            "CapabilityGroup",
            "LastUpdated",
            "ClientName",
            "AssessmentTypeId",
            "DeliveryStatus",
            "DeliveryStatusName",
            "ParentAssessmentId"
        ],
        "criterion": criterion,
        "sort": sort.length > 0 ? sort : [
            {
                "field": "AssessmentStartDate",
                "order": "DESC"
            }
        ],
        "page": page,
        "pageSize": pageSize
    }
}

export const getVendorListDataSearchBody = (page, searchValue) => {
    const criterion = [];
    if (!isEmpty(searchValue)) {
        criterion.push({
            field: "VendorOrgName",
            operator: "startswith",
            value: searchValue,
        });
    }
    return {
        "intent": "",
        "fields": [
            "VendorOrgId",
            "VendorOrgName"
        ],
        "criterion": criterion,
        "sort": [
            {
                "field": "VendorOrgName",
                "order": "ASC"
            }
        ],
        "page": page,
        "pageSize": 20,
    };
};

export const getClientListDataSearchBody = (page, searchValue) => {
    const criterion = [];
    if (!isEmpty(searchValue)) {
        criterion.push({
            field: "ClientOrgName",
            operator: "startswith",
            value: searchValue,
        });
    }
    return {
        "intent": "",
        "fields": [
            "ClientOgId",
            "ClientOrgName",
        ],
        "criterion": criterion,
        "sort": [
            {
                "field": "ClientOrgName",
                "order": "ASC"
            }
        ],
        "page": page,
        "pageSize": 20,
    };
};

export const getAssesmmentRequestProductListSearchBody = (page, searchValue) => {
    const criterion = [];
    if (!isEmpty(searchValue)) {
        criterion.push({
            field: "ProductName",
            operator: "startswith",
            value: searchValue,
        });
    }
    return {
        "intent": "",
        "fields": [
            "ProductVendorId",
            "ProductName",
            "ProductNameForFilter"
        ],
        "Criterion": criterion,
        "sort": [
            {
                field: "ProductName",
                order: "ASC",
            },
        ],
        "page": page,
        "pageSize": 20
    }
}

export const getAssessmentConfigurationBody = () => {
    return {
        "intent": "",
        "fields": [
            "MasterStepName",
            "AssessmentDisplayStatus",
            "Status"
        ],
        "criterion": [],
        "sort": [],
        "page": 0,
        "pageSize": 0
    }
}

export const getAssessmentListCountBody = (searchData = {}, sortModel) => {
    let criterion = [];
    if (!isEmpty(searchData)) {
        criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    }

    return {
        "intent": "",
        "fields": [
            "ClientOrgId",
            "AssessmentName",
            "ClientId",
            "ClientName",
            "ProductId",
            "ProductName",
            "VendorId",
            "VendorName",
            "MasterStepName",
            "AssessmentTypeId",
            "CountOfAssessment",
            "DeliveryStatus",
            "DeliveryStatusName",
            "ParentAssessmentId"
        ],
        "criterion": criterion,
        "sort": [],
        "page": 0,
        "pageSize": 0
    }
}


export const getCustomerVendorProductListSearchBody = (vendorOrgId, searchText = "", productVendorId = 0) => {
    const criterion = [
        {
            "Field": "VendorOrgId",
            "Operator": "=",
            "Value": vendorOrgId
        }
    ]
    if (searchText) {
        criterion.push({
            "Field": "ProductName",
            "Operator": "=",
            "Value": searchText
        })
    }
    if (productVendorId) {
        criterion.push({
            "Field": "ProductVendorId",
            "Operator": "=",
            "Value": productVendorId
        })
    }
    return {
        "Intent": "",
        "Fields": [
            "ProductName",
            "ProductType",
            "ProductGroup",
            "ProductVendorRole",
            "Description",
            "CustomerVendorId",
            "ProductVendorId",
            "CustomerOrgId",
            "CustomerVendorProductId",
            "FullTextSearch"
        ],
        "Criterion": criterion,
        "Sort": [],
        "Page": 0,
        "PageSize": 0
    }
}