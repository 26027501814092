export const USER_ID = 0
export const CORL_USER_ROLE = [{"UserRoleId":1001}];
export const VENDOR_USER_ROLE_ID = 2001
export const CLIENT_USER_ROLE_ID = 3001
export const CORL_APP_ID = 1
export const VENDOR_APP_ID = 2
export const CLIENT_APP_ID = 3
export const SEND_EMAIL = true
export const EDIT = "edit";
export const NEW = "new";
export const YES = "yes";
export const NO = "no";
export const RESEND_INVITE_CONFIRMATION = "Are you sure you want to reset the user's password and resend an invitation to ";

export const GUARDRAIL_CONFIRMATION_NEW_ASSOCIATION = "You are currently associated to [CLIENT/VENDOR] CURRENT_ASSOCIATION. Proceeding will remove the association from CURRENT_ASSOCIATION, and add an association to NEW_ASSOCIATION."
export const GUARDRAIL_CONFIRMATION_FIRST_LAUNCH = "This action shall launch the portal for [CLIENT/VENDOR] NEW_ASSOCIATION. Proceeding will associate you with NEW_ASSOCIATION."
export const GUARDRAIL_CONFIRMATION_SAME_ASSOCIATION_LAUNCH = "This action shall launch the portal for [CLIENT/VENDOR] NEW_ASSOCIATION."