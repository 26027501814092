export const getAppLookupBody = (lookupData) => {
    const fields = [];
    
    lookupData.forEach((field)  => {
        fields.push(field)    
    });

    return{
        "intent": "",
        "fields": fields,
        "Criterion": [],
        "sort": [],
        "page": 0,
        "pageSize": 0
    }
}

export const getUserDomainRuleLookupBody = () => {
    return {
        "intent": "",
        "fields": [
            "UserDomainRuleType"
        ],
        "Criterion": [],
        "sort": [],
        "page": 0,
        "pageSize": 0
    }
}