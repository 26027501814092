import {
  SET_CONTACTS_LOADING,
  SET_CONTACTS_SUCCESS,
  SET_CONTACTS_FAILURE,
  GET_VENDOR_PROFILE_CONTACTS_LOADING,
  GET_VENDOR_PROFILE_CONTACTS_SUCCESS,
  GET_VENDOR_PROFILE_CONTACTS_FAILURE,
  SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING,
  SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS,
  SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE,
  RESET_CONTACTS_LISTS,
} from "../actions/vendorProfileContacts";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  contactDetails: {},
};

export const vendorProfileContact = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_VENDOR_PROFILE_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_VENDOR_PROFILE_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          vendorContactDetailsList: payload,
        },
      };
    }
    case GET_VENDOR_PROFILE_CONTACTS_FAILURE: {
      toast.error(payload);
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS: {
        return {
          ...state,
          loading: false,
          contactDetails: {
            ...state.contactDetails,
            vendorContactDetailsList: payload,
          },
        };
    }
    case SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE: {
      toast.error(payload);
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          vendorContactDetailsList: [],
        },
      };
    }
    case SET_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_CONTACTS_SUCCESS: {
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          ...payload,
        },
      };
    }
    case SET_CONTACTS_FAILURE: {
      toast.error(payload.message);
      return {
        ...state,
        loading: false,
      };
    }
    case RESET_CONTACTS_LISTS: {
      return {
        ...state,
        loading: false,
        contactDetails: {},
      };
    }
    default:
      return state;
  }
};
