// ----------------------------------------------------------------------

export default function List(theme) {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0
        }
      }
    },
    MuiListItemButton:{
      styleOverrides:{
        root:{
          "&.Mui-selected,&.Mui-selected:hover":{
            backgroundColor: theme.palette.grey[300],
          },
          padding:'8px'
        }
      }
    }
  };
}
