import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal, Button, Typography } from "@mui/material";
import {
  modalStyle,
  buttonStyle,
  errorData,
} from "./customErrorHandlerModalStyles";
import { clearCustomErrors } from "../../actions/customErrorHandler";
import {
  buttonRightMargin,
  spaceBottom,
} from "./customErrorHandlerModalStyles";
import { YES, NO, OK, postLaunchErrorMessage } from "../Outreach/constants";

const CustomErrorHandlerModal = (props) => {
  const { No = NO, Yes = YES } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [hideSelectedOption, setHideSelectOptions] = useState(false);
  const [updatedErrorMessage, setUpdatedErrorMessage] = useState('');
  const errorList = useSelector((state) => state.customErrorHandler.list);

  useEffect(() => {
    errorList.map((item, index) => {
      if (item.Message.includes(postLaunchErrorMessage)) {
        setHideSelectOptions(true)
        setUpdatedErrorMessage(item.Message)
      }
    })
  }, [errorList])

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props?.open]);

  useEffect(() => {
    setOpen(errorList?.length > 0);
  }, [errorList]);

  const onClose = () => {
    setOpen(false);
    dispatch(clearCustomErrors());
    if (props?.close) {
      props.close()
    }
  };

  const optionSelected = () => {
    if (props.optionSelected) {
      props.optionSelected();
    }
    onClose();
  };

  return (
    <Box>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          <ul>
            {
              hideSelectedOption ?
                <Box>
                  <Typography
                    variant="body1"
                    sx={(theme) => errorData(theme)}
                  >
                    {updatedErrorMessage}
                  </Typography>
                </Box>
                :
                errorList?.length > 1
                  ? errorList?.map((error, index) => (
                    <li key={index}>
                      <Typography
                        variant="body1"
                        sx={(theme) => errorData(theme)}
                      >
                        {(error.Message).replaceAll('!', ".")}
                      </Typography>
                    </li>
                  ))
                  : errorList?.map((error, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      sx={(theme) => errorData(theme)}
                    >
                      {(error.Message).replaceAll('!', ".")}
                    </Typography>
                  ))
            }
          </ul>

          {
            !hideSelectedOption ? (
              <div>
                <Typography variant="body1" sx={() => spaceBottom("20")}>
                  {props.question}
                </Typography>
                <Box sx={buttonStyle}>
                  <Box sx={buttonStyle}>
                    <Button
                      sx={buttonRightMargin}
                      color="info"
                      onClick={onClose}
                    >
                      {No}
                    </Button>
                  </Box>
                  <Box sx={buttonStyle}>
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => optionSelected()}
                    >
                      {Yes}
                    </Button>
                  </Box>
                </Box>
              </div>) :
              (
                <div>
                  <Box sx={buttonStyle}>
                    <Button
                      variant="contained"
                      color="info"
                      onClick={onClose}
                    >
                      {OK}
                    </Button>
                  </Box>
                </div>
              )
          }

        </Box>
      </Modal>
    </Box>
  );
};

export default CustomErrorHandlerModal;
