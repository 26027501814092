import { format } from "date-fns";

export const convertToUTCDate = (localDate) => {
  localDate += "T23:59:00";
  const utcDate = new Date(new Date(localDate).toUTCString());
  return utcDate;
};

export const convertToUTCDateTime = (localDateTime) => {
  const utcDateTime = new Date(new Date(localDateTime).toUTCString());
  return utcDateTime;
};

export const convertToLocalDate = (utcDateTimeAsStr, dateFormat) => {
  let dateOnly = utcDateTimeAsStr.split("T")[0] + "T00:00:00";
  const localDateTime = new Date(`${dateOnly}` + "Z");
  const formattedDateTime = format(localDateTime, dateFormat);
  return formattedDateTime;
};

export const convertToLocalTime = (utcDateTimeAsStr) => {
  const localDateTime = new Date(`${utcDateTimeAsStr}` + "Z");
  const formattedDateTime = format(localDateTime, "MM/dd/yy");
  return formattedDateTime;
};

export const convertToLocalDateTime = (utcDateTimeAsStr, dateFormat) => {
  const localDateTime = new Date(`${utcDateTimeAsStr}` + "Z");
  const formattedDateTime = format(localDateTime, dateFormat);
  return formattedDateTime;
};

export const convertToDateAndTime = (dateTimeString, dateFormat) => {
  const localDateTime = new Date(`${dateTimeString}`);
  let hours = localDateTime.getHours();
  let minutes = localDateTime.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime =
    format(localDateTime, dateFormat) +
    " " +
    hours +
    ":" +
    minutes +
    " " +
    ampm;
  return strTime;
};
