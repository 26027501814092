export const NO_DATA_FOUND = "No data found";
export const DOMAIN_ERROR_MESSAGE = [
  {
    Type: "E",
    Message: "The email ID should be of the company domain only.",
    Source: null,
  },
];
export const NO_CONTACTS_FOUND = [
  {
    Type: "I",
    Message: "No Contacts found, please add Contacts.",
  },
];
export const NO_USERS_FOUND = [
  {
    Type: "I",
    Message: "No Users found, please add user.",
  },
];
export const NO_CUSTOMERS_FOUND = [
  {
    Type: "I",
    Message: "No customers associated, please add Customers.",
  },
];
export const CANCEL_PRODUCT_FORM_CONFIRMATION =
  "This will close the page without saving the changes. Are you sure?";
export const DELETE_PRODUCT_GROUP_CONFIRMATION =
  "This action will delete the Product Group. Are you sure?";

export const PRODUCT_ADDED_SUCCESSFULLY = "Product Added Successfully";
export const PRODUCT_UPDATED_SUCCESSFULLY = "Product Updated Successfully"
export const PRODUCT_DELETED_SUCCESSFULLY = "Product Deleted Successfully"

export const DELETE_PRODUCT_CONFIRMATION =
  "This action will delete the Product. Are you sure?";

export const CANCEL_FILE_UPLOAD_CONFIRMATION = "This action will not save the changes. Are you sure?";


