import {
  GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING,
  GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
  GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  GET_COUNTRY_LIST_LOADING,
  GET_COUNTRY_LIST_SUCCESS,
  GET_STATE_LIST_SUCCESS,
  GET_STATE_LIST_FAILURE,
  GET_STATE_LIST_LOADING,
  GET_YES_NO_LIST_LOADING,
  GET_YES_NO_LIST_SUCCESS,
  GET_YES_NO_LIST_FAILURE,
  GET_CONTRACT_INDUSTRY_LIST_LOADING,
  GET_CONTRACT_INDUSTRY_LIST_SUCCESS,
  GET_CONTRACT_INDUSTRY_LIST_FAILURE,
  GET_CONTRACT_LANE_TYPE_LIST_LOADING,
  GET_CONTRACT_LANE_TYPE_LIST_SUCCESS,
  GET_CONTRACT_LANE_TYPE_LIST_FAILURE,
  GET_CUSTOMER_SUPPORT_LEVEL_LOADING,
  GET_CUSTOMER_SUPPORT_LEVEL_SUCCESS,
  GET_CUSTOMER_SUPPORT_LEVEL_FAILURE,
  GET_CUSTOMER_SUCCESS_TIER_LOADING,
  GET_CUSTOMER_SUCCESS_TIER_SUCCESS,
  GET_CUSTOMER_SUCCESS_TIER_FAILURE,
  GET_STAKEHOLDER_PORTAL_LOADING,
  GET_STAKEHOLDER_PORTAL_SUCCESS,
  GET_STAKEHOLDER_PORTAL_FAILURE,
  GET_EXECUTIVE_SUMMARY_CONFIG_SUCCESS,
  GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE,
  GET_EXECUTIVE_SUMMARY_CONFIG_LOADING,
  GET_DYNAMIC_VENDOR_ROSTER_CONFIG_LOADING,
  GET_DYNAMIC_VENDOR_ROSTER_CONFIG_SUCCESS,
  GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE,
  GET_CYBER_RISK_SCORE_LOADING,
  GET_CYBER_RISK_SCORE_SUCCESS,
  GET_CYBER_RISK_SCORE_FAILURE,
  GET_VENDOR_COLLABORATION_SCORE_LOADING,
  GET_VENDOR_COLLABORATION_SCORE_SUCCESS,
  GET_VENDOR_COLLABORATION_SCORE_FAILURE,
  ASSESSMENT_REQUEST_TYPE_LOADING,
  ASSESSMENT_REQUEST_CATEGORY_LOADING,
  ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING,
  GET_CLIENT_VENDOR_INVENTORY_LOADING,
  GET_CLIENT_VENDOR_INVENTORY_SUCCESS,
  GET_CLIENT_VENDOR_INVENTORY_FAILURE,
  ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING,
  ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING,
  ASSESSMENT_REQUEST_TYPE_SUCCESS,
  ASSESSMENT_REQUEST_CATEGORY_SUCCESS,
  ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS,
  ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS,
  ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS,
  ASSESSMENT_REQUEST_TYPE_FAILURE,
  ASSESSMENT_REQUEST_CATEGORY_FAILURE,
  ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE,
  ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE,
  ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE,
  GET_DOMAIN_LIST_LOADING,
  GET_DOMAIN_LIST_FAILURE,
  GET_CAPABILITY_GROUP_LIST_LOADING,
  GET_CAPABILITY_GROUP_LIST_FAILURE,
  GET_DOMAIN_LIST_SUCCESS,
  GET_CAPABILITY_GROUP_LIST_SUCCESS,
  ASSESSMENT_REQUEST_FILE_TYPE_LOADING,
  ASSESSMENT_REQUEST_FILE_TYPE_FAILURE,
  ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS,
  GET_ASSESSMENT_REQUEST_STATUS_LIST_LOADING,
  GET_ASSESSMENT_REQUEST_STATUS_LIST_SUCCESS,
  GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE,
  GET_ASSESSMENT_REQUEST_TYPE_LIST_LOADING,
  GET_ASSESSMENT_REQUEST_TYPE_LIST_SUCCESS,
  GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE,
  GET_REMEDIATION_PREFERENCES_LOADING,
  GET_REMEDIATION_PREFERENCES_SUCCESS,
  GET_REMEDIATION_PREFERENCES_FAILURE,
  GET_PRODUCT_TYPE_LIST_LOADING,
  GET_PRODUCT_TYPE_LIST_SUCCESS,
  GET_PRODUCT_TYPE_LIST_FAILURE,
  GET_DOMAIN_RULE_LOOKUP_SUCCESS,
  GET_DOMAIN_RULE_LOOKUP_LOADING,
  GET_DOMAIN_RULE_LOOKUP_FAILURE,
  GET_RESPONSE_CATEGORY_LIST_LOADING,
  GET_RESPONSE_CATEGORY_LIST_SUCCESS,
  GET_RESPONSE_CATEGORY_LIST_FAILURE,
  GET_VENDOR_ROLE_LIST_LOADING,
  GET_VENDOR_ROLE_LIST_SUCCESS,
  GET_VENDOR_ROLE_LIST_FAILURE,
  GET_VALIDATION_PREFERENCES_LOADING,
  GET_VALIDATION_PREFERENCES_SUCCESS,
  GET_VALIDATION_PREFERENCES_FAILURE,
  GET_PRODUCT_COMPONENT_TYPE_LOADING,
  GET_PRODUCT_COMPONENT_TYPE_SUCCESS,
  GET_PRODUCT_COMPONENT_TYPE_FAILURE,
  GET_VENDOR_REPLACEMENT_REASON_LOADING,
  GET_VENDOR_REPLACEMENT_REASON_SUCCESS,
  GET_VENDOR_REPLACEMENT_REASON_FAILURE,
  GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_LOADING,
  GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_SUCCESS,
  GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_FAILURE,
  GET_PRODUCT_COMPONENT_DATA_LOCATION_SUCCESS,
  GET_PRODUCT_COMPONENT_DATA_LOCATION_LOADING,
  GET_PRODUCT_COMPONENT_DATA_LOCATION_FAILURE,
  GET_EVIDENCE_LOOKUP_LOADING,
  GET_EVIDENCE_LOOKUP_SUCCESS,
  GET_EVIDENCE_LOOKUP_FAILURE,
  GET_ASSESSMENT_VRAS_REQUST_LOADING,
  GET_ASSESSMENT_VRAS_REQUST_SUCCESS,
  GET_ASSESSMENT_VRAS_REQUST_FAILURE
} from "actions/lookup";
import { getActiveInactiveLabelList, getSelectOptionsList, getValueLabelList } from "utils/arrayUtils";

const initialState = {
  numberOfEmployeesRange: [],
  statesList: [],
  countriesList: [],
  yesNoList: [],
  rawYesNoList: [],
  contractIndustryList: [],
  laneType: [],
  customerSupportLevel: [],
  customerSuccessTier: [],
  stakeHolderPortal: [],
  executiveSummary: [],
  dynamicVendorRoster: [],
  cyberRiskScore: [],
  vendorCollaborationScore: [],
  assessmentType: [],
  clientVendorInventory: [],
  assessmentCategory: [],
  productType: [],
  assessmentRequestTypeList: [],
  assessmentRequestStatusList: [],
  scopingInformation: [],
  securityControlInformation: [],
  domainLabelList: [],
  capabilityGroupLabelList: [],
  clientFileType: [],
  remediationPreferences: [],
  vendorRoles: [],
  userDomainRuleType: [],
  responseCategoryType: [],
  productTypes: [],
  validationPreferences: [],
  productComponentType: [],
  assessmentRequestValidationPreferences: [],
  loading: false,
  productComponentDataLocation: [],
  productComponentMedicalDevice: [],
  evidenceType: [],
  subType: [],
  fileType: [],
  assessmentVRASRequestType: [],
  VendorReplacementReasonList: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        numberOfEmployeesRange: getValueLabelList(payload?.Data),
      };
    }

    case GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_STATE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_STATE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        statesList: getValueLabelList(payload?.Data, 'label'),
      };
    }

    case GET_STATE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_COUNTRY_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        countriesList: [],
      };
    }

    case GET_COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        countriesList: getValueLabelList(payload?.Data, 'label'),
      };
    }

    case GET_COUNTRY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        countriesList: [],
      };
    }

    case GET_YES_NO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_YES_NO_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        yesNoList: getValueLabelList(payload?.Data, 'label'),
        rawYesNoList: getValueLabelList(payload?.Data),
        vendorStatusList: getActiveInactiveLabelList(payload?.Data)
      };
    }

    case GET_YES_NO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_CONTRACT_INDUSTRY_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CONTRACT_INDUSTRY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        contractIndustryList: getValueLabelList(payload?.Data),
      };
    }

    case GET_CONTRACT_INDUSTRY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_CONTRACT_LANE_TYPE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CONTRACT_LANE_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        laneType: getValueLabelList(payload?.Data),
      };
    }

    case GET_CONTRACT_LANE_TYPE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_CUSTOMER_SUPPORT_LEVEL_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_CUSTOMER_SUPPORT_LEVEL_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerSupportLevel: getValueLabelList(payload)
      }
    }

    case GET_CUSTOMER_SUPPORT_LEVEL_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case GET_CUSTOMER_SUCCESS_TIER_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_CUSTOMER_SUCCESS_TIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerSuccessTier: getValueLabelList(payload)
      }
    }

    case GET_CUSTOMER_SUCCESS_TIER_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case GET_STAKEHOLDER_PORTAL_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_STAKEHOLDER_PORTAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        stakeHolderPortal: getValueLabelList(payload)
      }
    }

    case GET_STAKEHOLDER_PORTAL_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case GET_EXECUTIVE_SUMMARY_CONFIG_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_EXECUTIVE_SUMMARY_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        executiveSummary: getValueLabelList(payload)
      }
    }

    case GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case GET_DYNAMIC_VENDOR_ROSTER_CONFIG_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_DYNAMIC_VENDOR_ROSTER_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        dynamicVendorRoster: getValueLabelList(payload)
      }
    }

    case GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case GET_CYBER_RISK_SCORE_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_CYBER_RISK_SCORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        cyberRiskScore: getValueLabelList(payload)
      }
    }

    case GET_CYBER_RISK_SCORE_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case GET_VENDOR_COLLABORATION_SCORE_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_VENDOR_COLLABORATION_SCORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorCollaborationScore: getValueLabelList(payload)
      }
    }

    case GET_VENDOR_COLLABORATION_SCORE_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case ASSESSMENT_REQUEST_TYPE_LOADING:
    case ASSESSMENT_REQUEST_CATEGORY_LOADING:
    case ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING:
    case ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING:
    case ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING:
    case GET_DOMAIN_LIST_LOADING:
    case GET_CAPABILITY_GROUP_LIST_LOADING:
    case ASSESSMENT_REQUEST_FILE_TYPE_LOADING:
    case GET_REMEDIATION_PREFERENCES_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_REQUEST_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentType: getValueLabelList(payload),
      };
    }
    case ASSESSMENT_REQUEST_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentCategory: getValueLabelList(payload),
      };
    }
    case ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        productType: getValueLabelList(payload),
      };
    }
    case ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        scopingInformation: getValueLabelList(payload),
      };
    }
    case ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        securityControlInformation: getValueLabelList(payload),
      };
    }
    case GET_DOMAIN_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        domain: getValueLabelList(payload?.Data, "label"),
        domainLabelList: getValueLabelList(payload?.Data, "label"),
      };
    }
    case GET_CAPABILITY_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        capabilityGroup: getValueLabelList(payload?.Data, "label"),
        capabilityGroupLabelList: getValueLabelList(payload?.Data, "label"),
      };
    }
    case ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        clientFileType: payload,
      };
    }
    case ASSESSMENT_REQUEST_TYPE_FAILURE:
    case ASSESSMENT_REQUEST_CATEGORY_FAILURE:
    case ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE:
    case ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE:
    case ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE:
    case GET_DOMAIN_LIST_FAILURE:
    case GET_CAPABILITY_GROUP_LIST_FAILURE:
    case ASSESSMENT_REQUEST_FILE_TYPE_FAILURE:
    case GET_REMEDIATION_PREFERENCES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_CLIENT_VENDOR_INVENTORY_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_ASSESSMENT_REQUEST_TYPE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_ASSESSMENT_REQUEST_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentRequestTypeList: getValueLabelList(payload?.Data)
      }
    }

    case GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentRequestTypeList: payload
      }
    }

    case GET_ASSESSMENT_REQUEST_STATUS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_ASSESSMENT_REQUEST_STATUS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentRequestStatusList: getValueLabelList(payload?.Data)
      }
    }

    case GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentRequestStatusList: payload
      }
    }



    case GET_CLIENT_VENDOR_INVENTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        clientVendorInventory: getValueLabelList(payload)
      }
    }

    case GET_CLIENT_VENDOR_INVENTORY_FAILURE: {
      return {
        ...state,
        loading: false,
        clientVendorInventory: []
      }
    }
    case GET_REMEDIATION_PREFERENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        remediationPreferences: getValueLabelList(payload, "label")
      }
    }

    case GET_DOMAIN_RULE_LOOKUP_LOADING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_DOMAIN_RULE_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
        userDomainRuleType: []
      }
    }
    case GET_DOMAIN_RULE_LOOKUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDomainRuleType: getValueLabelList(payload?.UserDomainRuleType)
      }
    }
    case GET_PRODUCT_TYPE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PRODUCT_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productTypes: getValueLabelList(payload?.Data?.ProductType),
      };
    }
    case GET_PRODUCT_TYPE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_VENDOR_ROLE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_VENDOR_ROLE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorRoles: getValueLabelList(payload?.Data?.ProductVendorRole),
      };
    }
    case GET_VENDOR_ROLE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_RESPONSE_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        responseCategoryType: payload?.Data,
      };
    }
    case GET_RESPONSE_CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        responseCategoryType: []
      };
    }
    case GET_VALIDATION_PREFERENCES_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_VALIDATION_PREFERENCES_SUCCESS: {
      const assessmentRequestValidationPreferences = getValueLabelList(payload).slice(0, 2)
      return {
        ...state,
        loading: false,
        validationPreferences: getValueLabelList(payload),
        assessmentRequestValidationPreferences: assessmentRequestValidationPreferences
      }
    }

    case GET_VALIDATION_PREFERENCES_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case GET_PRODUCT_COMPONENT_TYPE_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_PRODUCT_COMPONENT_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        productComponentType: getValueLabelList(payload)
      }
    }

    case GET_PRODUCT_COMPONENT_TYPE_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case GET_PRODUCT_COMPONENT_DATA_LOCATION_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_PRODUCT_COMPONENT_DATA_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        productComponentDataLocation: getValueLabelList(payload)
      }
    }

    case GET_VENDOR_REPLACEMENT_REASON_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        productComponentMedicalDevice: getValueLabelList(payload)
      }
    }

    case GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case GET_VENDOR_REPLACEMENT_REASON_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_VENDOR_REPLACEMENT_REASON_SUCCESS: {
      return {
        ...state,
        loading: false,
        VendorReplacementReasonList: getSelectOptionsList(payload, "Label", "Label")
      }
    }

    case GET_PRODUCT_COMPONENT_DATA_LOCATION_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    
    case GET_ASSESSMENT_VRAS_REQUST_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_ASSESSMENT_VRAS_REQUST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentVRASRequestType: getValueLabelList(payload)
      }
    }

    case GET_ASSESSMENT_VRAS_REQUST_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case GET_EVIDENCE_LOOKUP_LOADING: {
      return {
        ...state,
        loading: true,              
      }
    }
    case GET_EVIDENCE_LOOKUP_SUCCESS: {
      return {
        ...state,
        loading: false,   
        evidenceType: getValueLabelList(payload.EvidenceType),
        subType: getValueLabelList(payload.EvidenceSubType),
        fileType: getValueLabelList(payload.FileType)           
      }
    }
    case GET_EVIDENCE_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false, 
        evidenceType: [],
        subType: [],
        fileType: []               
      }
    }

    default:
      return state;
  }
}