import { faCalendarDay, faCircleDot, faSquareCheck, faT, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Language, List, Visibility, DateRange } from '@mui/icons-material';

export const GENERAL_SIDEBAR_MENU = 'general';
export const QUESTIONS_SIDEBAR_MENU = 'questions';
export const REVIEW_SIDEBAR_MENU = 'review';

export const QUESTIONNAIRE_TEMPLATE_SIDEBAR_MENUS = [
    { label: 'General', value: GENERAL_SIDEBAR_MENU, icon: Language },
    { label: 'Questions', value: QUESTIONS_SIDEBAR_MENU, icon: List },
    { label: 'Review', value: REVIEW_SIDEBAR_MENU, icon: Visibility }
];

export const QUESTIONNAIRE_TEMPLATE_TYPE_INCIDENT = 3;
export const QUESTIONNAIRE_STATUS_LOOKUP_ENTITY_NAME = "QuestionnaireTemplateStatus";
export const DRAFT_STATUS_ID = 1;
export const DRAFT_STATUS = "Draft"
export const READY_FOR_QA_STATUS = "Ready For QA"
export const QA_REJECTED_STATUS = "QA Rejected"
export const QA_APPROVED_STATUS = "QA Approved"
export const CLIENT_REJECTED_STATUS = "Client Rejected"
export const CLIENT_APPROVED_STATUS = "Client Approved"


export const QUESTIONNAIRE_TEMPLATE_TYPE_LIST = [
    { value: 1, label: "Scoping", disabled: true } ,
    { value: 2, label: "Control", disabled: true }, 
    { value: 3, label: "Incident Response", disabled: false }
];

export const ANSWER_TYPES_RESPONSES_FOR = [1, 2]

export const ANSWER_TYPES = [
    {
        value: 1,
        label: 'Single Select',
        icon: faCircleDot
    },
    {
        value: 2,
        label: 'Multi-Select',
        icon: faSquareCheck
    },
    {
        value: 3,
        label: 'Date',
        icon: faCalendarDay
    },
    {
        value: 4,
        label: 'Free Text',
        icon: faT
    }
]

export const POSITIVE_RESPONSE_SCORE = 1;
export const NEGATIVE_RESPONSE_SCORE = -1;

export const FREE_TEXT_QUESTION_RESPONSE_TYPE_ID = 4;
export const FILE_UPLOAD_QUESTION_RESPONSE_TYPE_ID = 5;