import { DELETE_SUCCESSFULLY_MESSAGE, ERROR_MESSAGE, UPLOADED_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { DELETE_EVIDENCE_DOCUMENTS_FAILURE, DELETE_EVIDENCE_DOCUMENTS_LOADING, DELETE_EVIDENCE_DOCUMENTS_SUCCESS, SEARCH_EVIDENCE_DETAILS_FAILURE, SEARCH_EVIDENCE_DETAILS_LOADING, SEARCH_EVIDENCE_DETAILS_SUCCESS, UPLOAD_EVIDENCE_FILE_FAILURE, UPLOAD_EVIDENCE_FILE_LOADING, UPLOAD_EVIDENCE_FILE_SUCCESS } from 'actions/evidenceManagement';
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    uploadedFiles: [],
    evidenceList: [],
};

export default function(state = initialState, action) {
    const {type, payload} = action;
    switch(type) {
        case UPLOAD_EVIDENCE_FILE_LOADING: {
            return {
              ...state,
              loading: true,
            };
          }
          case UPLOAD_EVIDENCE_FILE_SUCCESS: {
            toast.success(UPLOADED_SUCCESSFULLY_MESSAGE);
            return {
              ...state,
              loading: false,
              uploadedFiles: payload
            };
          }
          case UPLOAD_EVIDENCE_FILE_FAILURE: {
            toast.error(ERROR_MESSAGE);
            return {
              ...state,
              loading: false,
            };
          }
          case SEARCH_EVIDENCE_DETAILS_LOADING: {
            return {
              ...state,
              loading: true,
            }
          }
          case SEARCH_EVIDENCE_DETAILS_SUCCESS: {
            return {
              ...state,
              loading: false,
              evidenceList: payload
            }
          }
          case SEARCH_EVIDENCE_DETAILS_FAILURE: {
            return {
              ...state,
              loading: false,
              evidenceList: []
            }
          }          
          case DELETE_EVIDENCE_DOCUMENTS_LOADING: {
            return {
              ...state,
              loading: true
            }
          }
          case DELETE_EVIDENCE_DOCUMENTS_SUCCESS: {
            toast.success(DELETE_SUCCESSFULLY_MESSAGE)
            return {
              ...state,
              loading: false,
            }
          }
          case DELETE_EVIDENCE_DOCUMENTS_FAILURE: {
            toast.error(ERROR_MESSAGE)
            return {
              ...state,
              loading: false,
            }
          }

          default:
            return state;
    }
}