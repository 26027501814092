export const footerWrapper = (theme) => {
    return {

    }
}

export const footerSection1 = (theme) => {
    return {
        height: '148px',
        backgroundColor: theme.palette.footer.light,
        display: 'flex',
        alignItems: 'center',
        padding: '40px 80px'
    }
}
export const footerLogo = () => {
    return {
        width: "200px",
        height: "auto"
    }
}

export const footerSection2 = (theme) => {
    return {
        height: '56px',
        backgroundColor: theme.palette.footer.main,
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.primary.contrastText
    }
}

export const moveToTop = (theme) => {
    return {
        height: '56px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.info.dark,
        }
    }
}

export const copyrightStyles = (theme) => {
    return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '16px 80px'
    }
}

export const copyrightSymbolStyle = () => {
    return {
        margin: '0px 5px'
    }
}