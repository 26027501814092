export const VENDORS_PRODUCTS_TAB_OPTIONS = {
  PRODUCTS: "PRODUCTS",
  PRODUCTGROUPS: "PRODUCT GROUPS",
};

export const VENDORS_PRODUCTS_TAB_LIST = [
  {
    label: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
    value: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
  },
  {
    label: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTGROUPS,
    value: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTGROUPS,
  },
];

export const ERROR_MESSAGE = "Something went wrong, Please try again";

export const PRODUCT_GROUP_ADDED_SUCCESSFULLY =
  "Product Group Added Successfully";
export const PRODUCT_GROUP_UPDATED_SUCCESSFULLY =
  "Product Group Updated Successfully";
export const PRODUCT_GROUP_DELETED_SUCCESSFULLY =
  "Product Group Deleted Successfully";

export const NO_PRODUCTS_AVAILABLE = "No Products Available";

export const FORCE_DELETE_PRODUCT_CONFIRMATION_MESSAGE =
  "Are you sure you want to delete all associated data? Please confirm.";

export const FORCE_DELETE_PRODUCTGROUP_CONFIRMATION_MESSAGE =
  "This product group has products associated with it. Are you sure to delete this product group?";

export const NO_PRODUCT_GROUP_ASSOCIATED = 0;

export const DEASSOCIATE_PRODUCTS_FROM_PRODUCTGROUP_ERROR_MESSAGE =
  "deassociate products from productgroup";

export const DELETE_PRODUCT_COMPONENT_CONFIRMATION = "Are you sure you want to delete the component?";
export const ADD_COMPONENT = "Add Component";
export const EDIT_COMPONENT = "Edit Component";

