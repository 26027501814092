import {
  POST_CORL_USER_TO_LIST_LOADING,
  POST_CORL_USER_TO_LIST_SUCCESS,
  POST_CORL_USER_TO_LIST_FAILURE,
  PUT_CORL_USER_TO_LIST_LOADING,
  PUT_CORL_USER_TO_LIST_SUCCESS,
  PUT_CORL_USER_TO_LIST_FAILURE,
  SET_CORL_USERS_LOADING,
  SET_CORL_USERS_SUCCESS,
  SET_CORL_USERS_FAILURE,
  SEARCH_CORL_USERS_LOADING,
  SEARCH_CORL_USERS_SUCCESS,
  SEARCH_CORL_USERS_FAILURE,
  SEARCH_USER_ROLE_LIST_LOADING,
  SEARCH_USER_ROLE_LIST_SUCCESS,
  SEARCH_USER_ROLE_LIST_FAILURE,
  SEARCH_USER_ROLE_LIST_OF_USER_LOADING,
  SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS,
  SEARCH_USER_ROLE_LIST_OF_USER_FAILURE,
  POST_USER_ROLE_TO_USER_LOADING,
  POST_USER_ROLE_TO_USER_SUCCESS,
  POST_USER_ROLE_TO_USER_FAILURE,
  DELETE_CORL_USER_LOADING,
  DELETE_CORL_USER_SUCCESS,
  DELETE_CORL_USER_FAILURE,
  POST_RESEND_INVITE_TO_USER_LOADING,
  POST_RESEND_INVITE_TO_USER_FAILURE,
  POST_RESEND_INVITE_TO_USER_SUCCESS,
  SET_CORL_USER_FORM_EDITABLE
} from "../actions/corlUsers";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { DELETED_USER_SUCCESSFULLY_MESSAGE, ERROR_MESSAGE, RESEND_INVITE_SUCCESSFULLY, SAVE_SUCCESSFULLY_MESSAGE, UPDATED_SUCCESSFULLY_MESSAGE, USER_ROLE_ADDED_SUCCESSFULLY, USER_ROLE_REMOVED_SUCCESSFULLY } from "components/Common/AlertMessages";

const initialState = {
  loading: false,
  userRoleListOfUserLoading: false,
  userDetails: {},
  userRoleList: [],
  userRoleListOfUser: [],
  isCORLUserFormEditable: false
};

const initialUserRoleList = [
  {
    id: 1,
    CustomerOrgId: 1,
    CustomerOrgName: "Client Admin"
  },
  {
    id: 2,
    CustomerOrgId: 2,
    CustomerOrgName: "CORL Admin"
  },
  {
    id: 3,
    CustomerOrgId: 3,
    CustomerOrgName: "Vendor Admin"
  },
  {
    id: 4,
    CustomerOrgId: 4,
    CustomerOrgName: "Vendor Reader"
  },
  {
    id: 5,
    CustomerOrgId: 5,
    CustomerOrgName: "Vendor Editor"
  },
  {
    id: 6,
    CustomerOrgId: 6,
    CustomerOrgName: "Client Reader"
  }
]

export default function corlUsers(state = initialState, action) {
  const { type, payload, isEditable } = action;

  switch (type) {
    case SEARCH_CORL_USERS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_CORL_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDetails: {
          ...state.userDetails,
          corlUserDetailsList: payload,
        },
      };
    }

    case SEARCH_CORL_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        userDetails:{}
      };
    }

    case SEARCH_USER_ROLE_LIST_LOADING: 
    case POST_USER_ROLE_TO_USER_LOADING: {
      return {
        ...state,
        loading: true
      }
    }

    case SEARCH_USER_ROLE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        userRoleList: payload?.Data?.Rows
      }
    }

    case SEARCH_USER_ROLE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        userRoleList: []
      }
    }

    case POST_USER_ROLE_TO_USER_SUCCESS: {
      toast.success(USER_ROLE_ADDED_SUCCESSFULLY)
      return {
        ...state,
        loading: false
      }
    }

    case POST_USER_ROLE_TO_USER_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case SEARCH_USER_ROLE_LIST_OF_USER_LOADING: {
      return {
        ...state,
        userRoleListOfUserLoading: true
      }
    }

    case SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS: {
      return {
        ...state,
        userRoleListOfUserLoading: false,
        userRoleListOfUser: payload?.Data?.Rows.map((item, index) => {
          return {
              ...item,
              id: index
          }
      })
      }
    }

    case SEARCH_USER_ROLE_LIST_OF_USER_FAILURE: {
      return {
        ...state,
        userRoleListOfUserLoading: false,
        userRoleListOfUser: []
      }
    }

    case POST_CORL_USER_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_CORL_USER_TO_LIST_SUCCESS: {
      const userDetailsClone = cloneDeep(state.userDetails);
      const userList = userDetailsClone.corlUserDetailsList ?? [];
      userList.push(payload);
      toast.success(SAVE_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false,
        userDetails: {
          ...state.userDetails,
          corlUserDetailsList: userList,
          userToBeSelected: payload.UserId,
        },
      };
    }

    case POST_CORL_USER_TO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case PUT_CORL_USER_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case PUT_CORL_USER_TO_LIST_SUCCESS: {
      let userDetailsClone = cloneDeep(state.userDetails);
      let userList = userDetailsClone.corlUserDetailsList ?? [];
      userList[payload.formData.itemToBeUpdatedIndex] =
        payload.formData.editedFormData;
        toast.success(UPDATED_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false,
        userDetails: {
          ...state.userDetails,
          corlUserDetailsList: userList,
          userToBeSelected: null,
        },
      };
    }

    case PUT_CORL_USER_TO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_CORL_USERS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_CORL_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDetails: {
          ...state.userDetails,
          ...payload,
        },
      };
    }
    case SET_CORL_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_CORL_USER_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case DELETE_CORL_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false
      }
    }
    case DELETE_CORL_USER_SUCCESS: {
      toast.success(DELETED_USER_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false
      }
    }

    case POST_RESEND_INVITE_TO_USER_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case POST_RESEND_INVITE_TO_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false
      }
    }
    case POST_RESEND_INVITE_TO_USER_SUCCESS: {
      toast.success(RESEND_INVITE_SUCCESSFULLY);
      return {
        ...state,
        loading: false
      }
    }

    case SET_CORL_USER_FORM_EDITABLE : {
      return {
        ...state,
        isCORLUserFormEditable: isEditable
      }
    }
    
    default:
      return state;
  }
}
