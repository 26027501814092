export const INCIDENT_BUILDER_LOOKUP_ENTITY_NAME = "IncidentType";
export const INCIDENT_STATUS_LOOKUP_ENTITY_NAME = "IncidentStatus";
export const INCIDENT_SEVERITY_LOOKUP_ENTITY_NAME = "Severity";

export const INCIDENT_REQUIRED_VALUES = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
    { value: 3, label: "TBD" }
];

export const DEFAULT_SEVERITY = 3;
export const DRAFT_STATUS = 1;
export const READY_FOR_QA_STATUS = 2
export const QA_REJECTED_STATUS = 30
export const QA_APPROVED_STATUS = 31
export const CLOSED_STATUS = 99
