import { SET_CUSTOM_ERRORS, CLEAR_CUSTOM_ERRORS } from "../actions/customErrorHandler";

const initialState = {
    list: []
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_CUSTOM_ERRORS: {
            return {
                ...state,
                list: payload
            }
        }

        case CLEAR_CUSTOM_ERRORS: {
            return {
                ...state,
                list: []
            }
        }

        default:
            return state;
    }

}