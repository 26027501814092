import { SET_USER_DATA } from "../actions/userProfile";
import userImage from "../assets/images/user-image.png";

const initialState = {
  value: {
    image: userImage,
    name: "Mark Quintana",
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_DATA: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
