import { Box } from '@mui/material'
import { Link } from "react-router-dom";
import { headerLogo, logo } from './NavBarStyles'
import React from 'react'
import headerLogoImg from "../../assets/images/corl-logo-color.svg";

const Branding = () => {
  return (
    <>
      <Box sx={headerLogo}>
        <Link to={"/"}>
          <Box
            component="img"
            alt="CORL logo"
            src={headerLogoImg}
            sx={theme => logo(theme)}
          />
        </Link>
      </Box>
    </>
  )
}

export default Branding