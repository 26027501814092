import { TRUE } from "constants/constants";
import { isArray, isEmpty, isString } from "lodash";

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      criterion.push({
        "Field": key,
        "Operator": "contains",
        "Value": searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        "Field": key,
        "Operator": "in",
        "Value": searchData[key].join(","),
      });
    }
  });
  return criterion;
};

export const getVendorInformationSearchBody = (VendorOrgName) => {
  const criterion = [];
  if (!isEmpty(VendorOrgName)) {
    criterion.push({
      "field": "VendorOrgName",
      "operator": "startswith",
      "value": VendorOrgName
    })
  }
  return {
    'intent': "",
    'fields': [,
      "Active",
      "VendorOrgId",
      "VendorOrgName",
      "DomainName",
      "YearFounded",
      "NumberOfEmployeesRange",
      "RegisteredBusinessAddressLine1",
      "RegisteredBusinessAddressLine2",
      "RegisteredBusinessAddressCity",
      "RegisteredBusinessAddressState",
      "RegisteredBusinessAddressZip",
      "Phone",
    ],
    'criterion': criterion,
    'sort': [
      {
        "field": "VendorOrgName",
        "order": "ASC"
      }
    ],
    'page': 0,
    'pageSize': 0,
  };
};

export const getAllVendorsInformationSearchBody = (formData) => {
  let criterion = [];
  let fields = [
    "Active",
    "VendorOrgId",
    "VendorOrgName",
    "DomainName",
    "YearFounded",
    "NumberOfEmployeesRange",
    "RegisteredBusinessAddressLine1",
    "RegisteredBusinessAddressLine2",
    "RegisteredBusinessAddressCity",
    "RegisteredBusinessAddressState",
    "RegisteredBusinessAddressZip",
    "IsVerified",
    "Phone",
    "AllVendors",
    "RestrictInActiveUnVerified"
  ]
  if (!isEmpty(formData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(formData)];
  }
  return {
    'intent': "",
    'fields': fields,
    'criterion': criterion,
    'sort': [
      {
        "field": "VendorOrgName",
        "order": "ASC"
      }
    ],
    'page': 0,
    'pageSize': 0,
  };
};

export const getUnverifiedVendorInformationSearchBody = (formData) => {
  let criterion = [
    {
      "field": "IsVerified",
      "operator": "=",
      "value": 0
    },
    {
      "Field": "Active",
      "Operator": "=",
      "Value": TRUE
    }
  ];
  let fields = [
    "Active",
    "VendorOrgId",
    "VendorOrgName",
    "DomainName",
    "YearFounded",
    "NumberOfEmployeesRange",
    "RegisteredBusinessAddressLine1",
    "RegisteredBusinessAddressLine2",
    "RegisteredBusinessAddressCity",
    "RegisteredBusinessAddressState",
    "RegisteredBusinessAddressZip",
    "IsVerified",
    "Phone",
  ]
  if (!isEmpty(formData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(formData)];
  }
  return {
    'intent': "",
    'fields': fields,
    'criterion': criterion,
    'sort': [
      {
        "field": "VendorOrgName",
        "order": "ASC"
      }
    ],
    'page': 0,
    'pageSize': 0,
  };
};

export const obtainCompanyInfoReqBody = (vendorOrgId) => {
  return {
    intent: "",
    fields: [
      "VendorOrgId",
      "VendorOrgName",
      "DomainName",
      "YearFounded",
      "NumberOfEmployeesRange",
      "RegisteredBusinessAddressLine1",
      "RegisteredBusinessAddressLine2",
      "RegisteredBusinessAddressCity",
      "RegisteredBusinessAddressState",
      "RegisteredBusinessAddressCountry",
      "RegisteredBusinessAddressZip",
      "Phone",
      "PhoneCode",
      "MainPhone",
      "PhoneExtension",
    ],
    criterion: [
      {
        "Field": "VendorOrgId",
        "Operator": "=",
        "Value": vendorOrgId
      }
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
}