import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import {
  SEARCH_VENDOR_CUSTOMER_LIST_LOADING,
  SEARCH_VENDOR_CUSTOMER_LIST_SUCCESS,
  SEARCH_VENDOR_CUSTOMER_LIST_FAILURE,
  SET_SELECTED_CUSTOMER,
  SEARCH_CUSTOMER_PRODUCTS_LOADING,
  SEARCH_CUSTOMER_PRODUCTS_SUCCESS,
  SEARCH_CUSTOMER_PRODUCTS_FAILURE,
  SEARCH_CUSTOMER_LIST_LOADING,
  SEARCH_CUSTOMER_LIST_SUCCESS,
  SEARCH_CUSTOMER_LIST_FAILURE,
  SEARCH_PRODUCT_GROUP_LIST_LOADING,
  SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
  SEARCH_PRODUCT_GROUP_LIST_FAILURE,
  SEARCH_PRODUCT_LIST_LOADING,
  SEARCH_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAILURE,
  ADD_CUSTOMER_TO_LIST_LOADING,
  ADD_CUSTOMER_TO_LIST_SUCCESS,
  ADD_CUSTOMER_TO_LIST_FAILURE,
  ADD_PRODUCT_TO_CUSTOMER_LOADING,
  ADD_PRODUCT_TO_CUSTOMER_SUCCESS,
  ADD_PRODUCT_TO_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_FROM_LIST_LOADING,
  DELETE_CUSTOMER_FROM_LIST_SUCCESS,
  DELETE_CUSTOMER_FROM_LIST_FAILURE,
  DELETE_PRODUCT_FROM_CUSTOMER_LOADING,
  DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS,
  DELETE_PRODUCT_FROM_CUSTOMER_FAILURE,
} from "actions/vendorCustomers";
import {
  CUSTOMER_ADDED_SUCCESSFULLY_MESSAGE,
  CUSTOMER_DELETED_SUCCESSFULLY_MESSAGE,
  ERROR_MESSAGE,
  PRODUCT_ADDED_SUCCESSFULLY_MESSAGE,
  PRODUCT_REMOVED_SUCCESSFULLY_MESSAGE
} from "components/Common/AlertMessages";
import { getSelectOptionsList, getValueLabelList } from "utils/arrayUtils";

const initialState = {
  loading: false,
  vendorCustomerList: [],
  vendorCustomerProductList: [],
  selectedCustomer: {},
  customerList: [],
  productGroupList: [],
  productGroupProductList: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case SEARCH_VENDOR_CUSTOMER_LIST_LOADING:
    case SEARCH_CUSTOMER_PRODUCTS_LOADING:
    case SEARCH_CUSTOMER_LIST_LOADING:
    case SEARCH_PRODUCT_GROUP_LIST_LOADING:
    case SEARCH_PRODUCT_LIST_LOADING:
    case ADD_CUSTOMER_TO_LIST_LOADING:
    case ADD_PRODUCT_TO_CUSTOMER_LOADING:
    case DELETE_CUSTOMER_FROM_LIST_LOADING:
    case DELETE_PRODUCT_FROM_CUSTOMER_LOADING: {
      return {
        ...state,
        loading: true
      }
    }

    case SEARCH_VENDOR_CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorCustomerList: payload?.Data?.Rows
      }
    }

    case SEARCH_VENDOR_CUSTOMER_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorCustomerList: []
      }
    }

    case SET_SELECTED_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: payload
      }
    }

    case SEARCH_CUSTOMER_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorCustomerProductList: payload?.Data?.Rows?.map((item, index) => {
          return {
            ...item,
            Id: index
          }
        })
      }
    }

    case SEARCH_CUSTOMER_PRODUCTS_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorCustomerProductList: []
      }
    }

    case SEARCH_CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        customerList: getSelectOptionsList(payload?.Data?.Rows, "CustomerOrgName", "CustomerOrgId")
      }
    }

    case SEARCH_CUSTOMER_LIST_FAILURE: {
      return {
        ...state,
        customerList: []
      }
    }

    case SEARCH_PRODUCT_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productGroupList: getSelectOptionsList(payload?.Data?.Rows, "ProductGroupName", "ProductGroupId")
      }
    }

    case SEARCH_PRODUCT_GROUP_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productGroupList: []
      }
    }

    case SEARCH_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productGroupProductList: getSelectOptionsList(payload?.Data?.Rows, "ProductName", "ProductVendorId")
      }
    }

    case SEARCH_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productGroupProductList: []
      }
    }

    case ADD_CUSTOMER_TO_LIST_SUCCESS: {
      toast.success(CUSTOMER_ADDED_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false
      }
    }

    case ADD_CUSTOMER_TO_LIST_FAILURE:
    case ADD_PRODUCT_TO_CUSTOMER_FAILURE:
    case DELETE_CUSTOMER_FROM_LIST_FAILURE:
    case DELETE_PRODUCT_FROM_CUSTOMER_FAILURE: {
      toast.error(ERROR_MESSAGE)
      return {
        ...state,
        loading: false
      }
    }

    case ADD_PRODUCT_TO_CUSTOMER_SUCCESS: {
      toast.success(PRODUCT_ADDED_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false
      }
    }

    case DELETE_CUSTOMER_FROM_LIST_SUCCESS: {
      toast.success(CUSTOMER_DELETED_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        loading: false
      }
    }

    case DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS: {
      toast.success(PRODUCT_REMOVED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false
      }
    }

    default:
      return state;
  }
}
