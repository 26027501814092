import axios from "axios";
import { NO_DATA_FOUND } from "constants/errorMessage";
import { getErrorObject } from "utils/apiUtils";
import { searchClientUserRoleListBody, searchUserRoleListOfClientUserBody } from "utils/clientUtil";
import { searchUserRoleListOfVendorUserBody, searchVendorUserRoleListBody } from "utils/vendorUsersUtils";
import { setErrors } from "./errorHandler";

export const SEARCH_CLIENT_USER_ROLE_LIST_LOADING = "@@userRoles/SEARCH_CLIENT_USER_ROLE_LIST_LOADING";
export const SEARCH_CLIENT_USER_ROLE_LIST_SUCCESS = "@@userRoles/SEARCH_CLIENT_USER_ROLE_LIST_SUCCESS";
export const SEARCH_CLIENT_USER_ROLE_LIST_FAILURE = "@@userRoles/SEARCH_CLIENT_USER_ROLE_LIST_FAILURE";

export const searchClientUserRoleList = (searchText = "") => async (dispatch) => {

    dispatch({
        type : SEARCH_CLIENT_USER_ROLE_LIST_LOADING
    })

    const body = searchClientUserRoleListBody(searchText)

    try {
        let response = await axios.post(`/userrole/search`, body);
        if (response?.data?.ErrorCount > 0) {
            const errors = response?.data?.Errors.filter(
              (errorItem) => errorItem.Message !== NO_DATA_FOUND
            );
            dispatch({
              type: SEARCH_CLIENT_USER_ROLE_LIST_FAILURE,
              payload: [],
            });
             dispatch(setErrors(errors));
          } else {
            dispatch({
              type: SEARCH_CLIENT_USER_ROLE_LIST_SUCCESS,
              payload: response?.data,
            });
          }
    } catch(err) {
        dispatch({
            type: SEARCH_CLIENT_USER_ROLE_LIST_FAILURE,
            payload: getErrorObject(err)
        })
    }
}

export const SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_LOADING = "@@userRoles/SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_LOADING";
export const SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_SUCCESS = "@@userRoles/SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_SUCCESS";
export const SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_FAILURE = "@@userRoles/SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_FAILURE";
  
export const searchUserRoleListOfClientUser = (userId) => async (dispatch) => {
    dispatch({ type: SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_LOADING });
    
    const body = searchUserRoleListOfClientUserBody(userId);
  
    try {
        let response = await axios.post(`/userrole/search`, body);
        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter(
            (errorItem) => errorItem.Message !== NO_DATA_FOUND
          );
          dispatch({
            type: SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_FAILURE,
            payload: [],
          });
           dispatch(setErrors(errors));
        } else {
          dispatch({
            type: SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_SUCCESS,
            payload: response?.data,
          });
        }
    }catch(err) {
        dispatch({
          type : SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_FAILURE,
          payload: getErrorObject(err)
        })
    }
}

export const POST_USER_ROLE_TO_CLIENT_USER_LOADING = "@@userRoles/POST_USER_ROLE_TO_CLIENT_USER_LOADING";
export const POST_USER_ROLE_TO_CLIENT_USER_SUCCESS = "@@userRoles/POST_USER_ROLE_TO_CLIENT_USER_SUCCESS";
export const POST_USER_ROLE_TO_CLIENT_USER_FAILURE = "@@userRoles/POST_USER_ROLE_TO_CLIENT_USER_FAILURE";
  
export const addUserRoleToClientUser = (userRoleId, reqBody, callback) => async (dispatch) => {
    dispatch({ type: POST_USER_ROLE_TO_CLIENT_USER_LOADING });
    
    const body = JSON.stringify(reqBody)
    try {
        let response = await axios.post(`/userrole/${userRoleId}/user/association`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: POST_USER_ROLE_TO_CLIENT_USER_FAILURE,
            payload: [],
          });
           dispatch(setErrors(response?.data?.Errors));
        } else {
          dispatch({
            type: POST_USER_ROLE_TO_CLIENT_USER_SUCCESS,
            payload: response?.data,
          });
          dispatch(searchUserRoleListOfClientUser(reqBody?.UserId))
        }
        if(callback) {
          callback()
        }
    }catch(err) {
        dispatch({
          type : POST_USER_ROLE_TO_CLIENT_USER_FAILURE,
          payload: getErrorObject(err)
        })
    }
}

export const SEARCH_VENDOR_USER_ROLE_LIST_LOADING = "@@userRoles/SEARCH_VENDOR_USER_ROLE_LIST_LOADING";
export const SEARCH_VENDOR_USER_ROLE_LIST_SUCCESS = "@@userRoles/SEARCH_VENDOR_USER_ROLE_LIST_SUCCESS";
export const SEARCH_VENDOR_USER_ROLE_LIST_FAILURE = "@@userRoles/SEARCH_VENDOR_USER_ROLE_LIST_FAILURE";

export const searchVendorUserRoleList = (searchText = "") => async (dispatch) => {

    dispatch({
        type : SEARCH_VENDOR_USER_ROLE_LIST_LOADING
    })

    const body = searchVendorUserRoleListBody(searchText)

    try {
        let response = await axios.post(`/userrole/search`, body);
        if (response?.data?.ErrorCount > 0) {
            const errors = response?.data?.Errors.filter(
              (errorItem) => errorItem.Message !== NO_DATA_FOUND
            );
            dispatch({
              type: SEARCH_VENDOR_USER_ROLE_LIST_FAILURE,
              payload: [],
            });
             dispatch(setErrors(errors));
          } else {
            dispatch({
              type: SEARCH_VENDOR_USER_ROLE_LIST_SUCCESS,
              payload: response?.data,
            });
          }
    } catch(err) {
        dispatch({
            type: SEARCH_VENDOR_USER_ROLE_LIST_FAILURE,
            payload: getErrorObject(err)
        })
    }
}

export const SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_LOADING = "@@userRoles/SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_LOADING";
export const SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_SUCCESS = "@@userRoles/SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_SUCCESS";
export const SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_FAILURE = "@@userRoles/SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_FAILURE";
  
export const searchUserRoleListOfVendorUser = (userId) => async (dispatch) => {
    dispatch({ type: SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_LOADING });
    
    const body = searchUserRoleListOfVendorUserBody(userId);
  
    try {
        let response = await axios.post(`/userrole/search`, body);
        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter(
            (errorItem) => errorItem.Message !== NO_DATA_FOUND
          );
          dispatch({
            type: SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_FAILURE,
            payload: [],
          });
           dispatch(setErrors(errors));
        } else {
          dispatch({
            type: SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_SUCCESS,
            payload: response?.data,
          });
        }
    }catch(err) {
        dispatch({
          type : SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_FAILURE,
          payload: getErrorObject(err)
        })
    }
}

export const POST_USER_ROLE_TO_VENDOR_USER_LOADING = "@@userRoles/POST_USER_ROLE_TO_VENDOR_USER_LOADING";
export const POST_USER_ROLE_TO_VENDOR_USER_SUCCESS = "@@userRoles/POST_USER_ROLE_TO_VENDOR_USER_SUCCESS";
export const POST_USER_ROLE_TO_VENDOR_USER_FAILURE = "@@userRoles/POST_USER_ROLE_TO_VENDOR_USER_FAILURE";
  
export const addUserRoleToVendorUser = (userRoleId, reqBody, callback) => async (dispatch) => {
    dispatch({ type: POST_USER_ROLE_TO_VENDOR_USER_LOADING });
    
    const body = JSON.stringify(reqBody)
    try {
        let response = await axios.post(`/userrole/${userRoleId}/user/association`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: POST_USER_ROLE_TO_VENDOR_USER_FAILURE,
            payload: [],
          });
           dispatch(setErrors(response?.data?.Errors));
        } else {
          dispatch({
            type: POST_USER_ROLE_TO_VENDOR_USER_SUCCESS,
            payload: response?.data,
          });
          dispatch(searchUserRoleListOfVendorUser(reqBody?.UserId))
        }
        if(callback) {
          callback()
        }
    }catch(err) {
        dispatch({
          type : POST_USER_ROLE_TO_VENDOR_USER_FAILURE,
          payload: getErrorObject(err)
        })
    }
}
