import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faNotes } from "@fortawesome/pro-solid-svg-icons";

export const ASSESSMENT_REQUEST_LIST_TAB_OPTIONS = {
  REQUESTS: "REQUESTS",
  ASSESSMENTS: "ASSESSMENTS",
};

export const ASSESSMENT_REQUEST_LIST_TAB_LIST = [
  {
    label: ASSESSMENT_REQUEST_LIST_TAB_OPTIONS.REQUESTS,
    value: ASSESSMENT_REQUEST_LIST_TAB_OPTIONS.REQUESTS,
  },
  {
    label: ASSESSMENT_REQUEST_LIST_TAB_OPTIONS.ASSESSMENTS,
    value: ASSESSMENT_REQUEST_LIST_TAB_OPTIONS.ASSESSMENTS,
  },
];

//assessment list
export const REMEDIATION_STAGE = "Remediation";
export const ACTIVE_STAGE = "Active";
export const COMPLETED_STAGE = "Completed";
export const NEW_ASSESSMENT_STATUS_ID = 1;

export const QUICK_DETAILS_TOGGLES = [
  {
    label: "DETAILS",
    value: "1",
    icons: faCircleInfo,
  },
  {
    label: "NOTES",
    value: "2",
    icons: faNotes,
    flip: "vertical",
  },
];
export const EXECUTIVE_SUMMARY_ENABLED = 2;

export const THRESHOLD = "1";
export const THRESHOLD_DESCRIPTION = "CORL pre-assessment";
export const SELECTED_VALUE_DETAILS = 1;
