import { faListCheck, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { faChartNetwork, faClipboardQuestion, faBullhorn } from "@fortawesome/pro-regular-svg-icons"
import { faUsers } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getIcon = icon => {
    switch(icon) {
        case 'faListCheck':  return faListCheck;
        case 'faShieldHalved':  return faShieldHalved;
        case 'faChartNetwork' : return faChartNetwork;
        case 'faUsers': return faUsers;
        case 'faClipboardQuestion': return faClipboardQuestion;
        case 'faBullhorn': return faBullhorn;
        default:  return faListCheck;
    }
}

export const getMenuIcon = (icon) => {
    // check first two chars
    // if (fa) the return <FontAwedpme icon
    //if http then <img src=...
    // mui then Muiicon
    let iconName = icon.substring(0, 2);

    if(iconName === "fa"){
        return <FontAwesomeIcon icon={getIcon(icon)} />
    }
    else if(iconName == "ht"){
        return <img src={icon} />
    }

}