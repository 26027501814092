import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Container } from '@mui/material';
import { appbar, appBarContainer } from './NavBarStyles';
import Branding from './Branding';
import Menu from './Menu';
import UserInfo from './UserInfo';
import { setMenuPanelId } from 'actions/navbar';

const NavBar = () => {
  const dispatch = useDispatch();
  const details = useSelector(state => state.session.details);
  const [pendoInitilized, setPendoInitialized] = useState(false);

  useEffect(() => {
    if (details?.UserEmail && details?.OrgName) {
      window.pendo.initialize({
        visitor: {
          id: details?.UserEmail,
          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },
        account: {
          id: details?.OrgName,
        },
      });
      setPendoInitialized(true);
    }
  }, [details]);
   return (
    <>
        <AppBar 
          position="static" 
          sx={appbar}
          onMouseLeave={() => dispatch(setMenuPanelId(0))}
        >
            <Container maxWidth="xl" sx={appBarContainer}>
                    <Branding />
                    <Menu />
                    <UserInfo />
            </Container>
        </AppBar>
    </>
  )
}

export default NavBar;