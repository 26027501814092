import axios from 'axios';
import { getErrorObject } from 'utils/apiUtils';
import { setErrors } from './errorHandler';
import { getAppLookupBody, getLookupBodyByFields, getUserDomainRuleLookupBody } from 'utils/lookupUtils';

export const GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING = "@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING";
export const GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS = "@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS";
export const GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE = "@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE";

export const getNumberOfEmployeeRange = () => async (dispatch) => {
  dispatch({
    type: GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=NumberOfEmployeesRange`);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_STATE_LIST_LOADING = "@@lookup/GET_STATE_LIST_LOADING";
export const GET_STATE_LIST_SUCCESS = "@@lookup/GET_STATE_LIST_SUCCESS";
export const GET_STATE_LIST_FAILURE = "@@lookup/GET_STATE_LIST_FAILURE";

export const getStatesList = (countryName) => async (dispatch) => {
  dispatch({
    type: GET_STATE_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=region?param=` + countryName);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_STATE_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: GET_STATE_LIST_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_STATE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_COUNTRY_LIST_LOADING = "@@lookup/GET_COUNTRY_LIST_LOADING";
export const GET_COUNTRY_LIST_SUCCESS = "@@lookup/GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_FAILURE = "@@lookup/GET_COUNTRY_LIST_FAILURE";

export const getCountriesList = () => async (dispatch) => {
  dispatch({
    type: GET_COUNTRY_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=Country`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_COUNTRY_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_COUNTRY_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COUNTRY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_YES_NO_LIST_LOADING = "@@lookup/GET_YES_NO_LIST_LOADING";
export const GET_YES_NO_LIST_SUCCESS = "@@lookup/GET_YES_NO_LIST_SUCCESS";
export const GET_YES_NO_LIST_FAILURE = "@@lookup/GET_YES_NO_LIST_FAILURE";

export const getYesNoList = () => async (dispatch) => {
  dispatch({
    type: GET_YES_NO_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=YesNo`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_YES_NO_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_YES_NO_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_YES_NO_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CONTRACT_INDUSTRY_LIST_LOADING = "@@lookup/GET_CONTRACT_INDUSTRY_LIST_LOADING";
export const GET_CONTRACT_INDUSTRY_LIST_SUCCESS = "@@lookup/GET_CONTRACT_INDUSTRY_LIST_SUCCESS";
export const GET_CONTRACT_INDUSTRY_LIST_FAILURE = "@@lookup/GET_CONTRACT_INDUSTRY_LIST_FAILURE";

export const getContractIndustryList = () => async (dispatch) => {
  dispatch({
    type: GET_CONTRACT_INDUSTRY_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=ContractIndustry`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CONTRACT_INDUSTRY_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CONTRACT_INDUSTRY_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CONTRACT_INDUSTRY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const GET_CONTRACT_LANE_TYPE_LIST_LOADING = "@@lookup/GET_CONTRACT_LANE_TYPE_LIST_LOADING";
export const GET_CONTRACT_LANE_TYPE_LIST_SUCCESS = "@@lookup/GET_CONTRACT_LANE_TYPE_LIST_SUCCESS";
export const GET_CONTRACT_LANE_TYPE_LIST_FAILURE = "@@lookup/GET_CONTRACT_LANE_TYPE_LIST_FAILURE";

export const getContractLaneTypeList = () => async (dispatch) => {
  dispatch({
    type: GET_CONTRACT_LANE_TYPE_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=LaneType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CONTRACT_LANE_TYPE_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CONTRACT_LANE_TYPE_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CONTRACT_LANE_TYPE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CUSTOMER_SUPPORT_LEVEL_LOADING = "@@lookup/GET_CUSTOMER_SUPPORT_LEVEL_LOADING";
export const GET_CUSTOMER_SUPPORT_LEVEL_SUCCESS = "@@lookup/GET_CUSTOMER_SUPPORT_LEVEL_SUCCESS";
export const GET_CUSTOMER_SUPPORT_LEVEL_FAILURE = "@@lookup/GET_CUSTOMER_SUPPORT_LEVEL_FAILURE";

export const getCustomerSupportLevel = () => async (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_SUPPORT_LEVEL_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CustomerSupportLevel`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CUSTOMER_SUPPORT_LEVEL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CUSTOMER_SUPPORT_LEVEL_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CUSTOMER_SUPPORT_LEVEL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CUSTOMER_SUCCESS_TIER_LOADING = "@@lookup/GET_CUSTOMER_SUCCESS_TIER_LOADING";
export const GET_CUSTOMER_SUCCESS_TIER_SUCCESS = "@@lookup/GET_CUSTOMER_SUCCESS_TIER_SUCCESS";
export const GET_CUSTOMER_SUCCESS_TIER_FAILURE = "@@lookup/GET_CUSTOMER_SUCCESS_TIER_FAILURE";

export const getCustomerSuccessTier = () => async (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_SUCCESS_TIER_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CustomerSuccessTier`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CUSTOMER_SUCCESS_TIER_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CUSTOMER_SUCCESS_TIER_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CUSTOMER_SUCCESS_TIER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_STAKEHOLDER_PORTAL_LOADING = "@@lookup/GET_STAKEHOLDER_PORTAL_LOADING";
export const GET_STAKEHOLDER_PORTAL_SUCCESS = "@@lookup/GET_STAKEHOLDER_PORTAL_SUCCESS";
export const GET_STAKEHOLDER_PORTAL_FAILURE = "@@lookup/GET_STAKEHOLDER_PORTAL_FAILURE";

export const getStakeHolderPortal = () => async (dispatch) => {
  dispatch({
    type: GET_STAKEHOLDER_PORTAL_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=StakeHolderPortal`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_STAKEHOLDER_PORTAL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_STAKEHOLDER_PORTAL_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_STAKEHOLDER_PORTAL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_EXECUTIVE_SUMMARY_CONFIG_LOADING = "@@lookup/GET_EXECUTIVE_SUMMARY_CONFIG_LOADING";
export const GET_EXECUTIVE_SUMMARY_CONFIG_SUCCESS = "@@lookup/GET_EXECUTIVE_SUMMARY_CONFIG_SUCCESS";
export const GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE = "@@lookup/GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE";

export const getExecutiveSummaryConfig = () => async (dispatch) => {
  dispatch({
    type: GET_EXECUTIVE_SUMMARY_CONFIG_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=ExecutiveSummarySetting`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_EXECUTIVE_SUMMARY_CONFIG_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_EXECUTIVE_SUMMARY_CONFIG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_DYNAMIC_VENDOR_ROSTER_CONFIG_LOADING = "@@lookup/GET_DYNAMIC_VENDOR_ROSTER_CONFIG_LOADING";
export const GET_DYNAMIC_VENDOR_ROSTER_CONFIG_SUCCESS = "@@lookup/GET_DYNAMIC_VENDOR_ROSTER_CONFIG_SUCCESS";
export const GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE = "@@lookup/GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE";

export const getDynamicVendorRosterConfig = () => async (dispatch) => {
  dispatch({
    type: GET_DYNAMIC_VENDOR_ROSTER_CONFIG_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=DynamicVendorRoaster`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_DYNAMIC_VENDOR_ROSTER_CONFIG_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DYNAMIC_VENDOR_ROSTER_CONFIG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_CYBER_RISK_SCORE_LOADING = "@@lookup/GET_CYBER_RISK_SCORE_LOADING";
export const GET_CYBER_RISK_SCORE_SUCCESS = "@@lookup/GET_CYBER_RISK_SCORE_SUCCESS";
export const GET_CYBER_RISK_SCORE_FAILURE = "@@lookup/GET_CYBER_RISK_SCORE_FAILURE";

export const getCyberRiskScore = () => async (dispatch) => {
  dispatch({
    type: GET_CYBER_RISK_SCORE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=CyberRiskScore`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CYBER_RISK_SCORE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CYBER_RISK_SCORE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CYBER_RISK_SCORE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_VENDOR_COLLABORATION_SCORE_LOADING = "@@lookup/GET_VENDOR_COLLABORATION_SCORE_LOADING";
export const GET_VENDOR_COLLABORATION_SCORE_SUCCESS = "@@lookup/GET_VENDOR_COLLABORATION_SCORE_SUCCESS";
export const GET_VENDOR_COLLABORATION_SCORE_FAILURE = "@@lookup/GET_VENDOR_COLLABORATION_SCORE_FAILURE";

export const getVendorCollaborationScore = () => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_COLLABORATION_SCORE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=VendorCollaborationScore`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_VENDOR_COLLABORATION_SCORE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_VENDOR_COLLABORATION_SCORE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VENDOR_COLLABORATION_SCORE_FAILURE,
      payload: getErrorObject(err)
    })
  }
}

export const ASSESSMENT_REQUEST_TYPE_LOADING =
  "@@lookup/ASSESSMENT_REQUEST_TYPE_LOADING";
export const ASSESSMENT_REQUEST_TYPE_SUCCESS =
  "@@lookup/ASSESSMENT_REQUEST_TYPE_SUCCESS";
export const ASSESSMENT_REQUEST_TYPE_FAILURE =
  "@@lookup/ASSESSMENT_REQUEST_TYPE_FAILURE";

export const getAssessmentRequestType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookUp/e=AssessmentRequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_CATEGORY_LOADING =
  "@@lookup/ASSESSMENT_REQUEST_CATEGORY_LOADING";
export const ASSESSMENT_REQUEST_CATEGORY_SUCCESS =
  "@@lookup/ASSESSMENT_REQUEST_CATEGORY_SUCCESS";
export const ASSESSMENT_REQUEST_CATEGORY_FAILURE =
  "@@lookup/ASSESSMENT_REQUEST_CATEGORY_FAILURE";

export const getAssessmentRequestCategory = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_CATEGORY_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentRequestCategory`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_CATEGORY_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_CATEGORY_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_CATEGORY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING =
  "@@lookup/ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING";
export const ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS =
  "@@lookup/ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS";
export const ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE =
  "@@lookup/ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE";

export const getAssessmentRequestProductCategory = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_LOADING,
  });
  try {
    const res = await axios.get(
      `/app/lookup/e=AssessmentRequestProductCategory`
    );
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_PRODUCT_CATEGORY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING =
  "@@lookup/ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING";
export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS =
  "@@lookup/ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS";
export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE =
  "@@lookup/ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE";

export const getAssessmentRequestScopingInformation =
  () => async (dispatch) => {
    dispatch({
      type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_LOADING,
    });
    try {
      const res = await axios.get(
        `/app/lookUp/e=AssessmentRequestScopingInformation`
      );
      if (res?.data?.ErrorCount > 0) {
        dispatch(setErrors(res?.data?.Errors));
        dispatch({
          type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_SUCCESS,
          payload: res?.data?.Data,
        });
      }
    } catch (err) {
      dispatch({
        type: ASSESSMENT_REQUEST_SCOPING_INFORMATION_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING =
  "@@lookup/ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING";
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS =
  "@@lookup/ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS";
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE =
  "@@lookup/ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE";

export const getAssessmentRequestSecurityControlInformation =
  () => async (dispatch) => {
    dispatch({
      type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_LOADING,
    });
    try {
      const res = await axios.get(
        `/app/lookup/e=AssessmentRequestSecurityControlInformation`
      );
      if (res?.data?.ErrorCount > 0) {
        dispatch(setErrors(res?.data?.Errors));
        dispatch({
          type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_SUCCESS,
          payload: res?.data?.Data,
        });
      }
    } catch (err) {
      dispatch({
        type: ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };


export const GET_DOMAIN_LIST_LOADING = '@@lookup/GET_DOMAIN_LIST_LOADING';
export const GET_DOMAIN_LIST_SUCCESS = '@@lookup/GET_DOMAIN_LIST_SUCCESS';
export const GET_DOMAIN_LIST_FAILURE = '@@lookup/GET_DOMAIN_LIST_FAILURE';

export const getDomainList = (clientOrgId) => async (dispatch) => {
  dispatch({ type: GET_DOMAIN_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/org/e=Domain?clientOrgId=${clientOrgId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_DOMAIN_LIST_FAILURE,
        payload: []
      })
    } else {
      dispatch({
        type: GET_DOMAIN_LIST_SUCCESS,
        payload: response?.data
      });
    }

  } catch (err) {
    dispatch({
      type: GET_DOMAIN_LIST_FAILURE,
      payload: getErrorObject(err)
    });
  }
}


export const GET_CLIENT_VENDOR_INVENTORY_LOADING = "@@lookup/GET_CLIENT_VENDOR_INVENTORY_LOADING";
export const GET_CLIENT_VENDOR_INVENTORY_SUCCESS = "@@lookup/GET_CLIENT_VENDOR_INVENTORY_SUCCESS";
export const GET_CLIENT_VENDOR_INVENTORY_FAILURE = "@@lookup/GET_CLIENT_VENDOR_INVENTORY_FAILURE";

export const getClientVendorInventory = () => async (dispatch) => {
  dispatch({
    type: GET_CLIENT_VENDOR_INVENTORY_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=ClientVendorInventory`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_CLIENT_VENDOR_INVENTORY_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CLIENT_VENDOR_INVENTORY_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CLIENT_VENDOR_INVENTORY_FAILURE,
      payload: getErrorObject(err)
    })
  }
}

export const GET_CAPABILITY_GROUP_LIST_LOADING = '@@lookup/GET_CAPABILITY_GROUP_LIST_LOADING';
export const GET_CAPABILITY_GROUP_LIST_SUCCESS = '@@lookup/GET_CAPABILITY_GROUP_LIST_SUCCESS';
export const GET_CAPABILITY_GROUP_LIST_FAILURE = '@@lookup/GET_CAPABILITY_GROUP_LIST_FAILURE';

export const getCapabilityGroupList = (clientOrgId) => async (dispatch) => {
  dispatch({ type: GET_CAPABILITY_GROUP_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/org/e=CapabilitiesGroup?clientOrgId=${clientOrgId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_CAPABILITY_GROUP_LIST_FAILURE,
        payload: []
      })
    } else {
      dispatch({
        type: GET_CAPABILITY_GROUP_LIST_SUCCESS,
        payload: response?.data
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CAPABILITY_GROUP_LIST_FAILURE,
      payload: getErrorObject(err)
    });
  }
};

export const GET_ASSESSMENT_REQUEST_TYPE_LIST_LOADING = "@@lookup/GET_ASSESSMENT_REQUEST_TYPE_LIST_LOADING";
export const GET_ASSESSMENT_REQUEST_TYPE_LIST_SUCCESS = "@@lookup/GET_ASSESSMENT_REQUEST_TYPE_LIST_SUCCESS";
export const GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE = "@@lookup/GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE";

export const getAssessmentRequestTypeList = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_REQUEST_TYPE_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=AssessmentRequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_REQUEST_TYPE_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_REQUEST_TYPE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUEST_FILE_TYPE_LOADING =
  "@@lookup/ASSESSMENT_REQUEST_FILE_TYPE_LOADING";
export const ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS =
  "@@lookup/ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS";
export const ASSESSMENT_REQUEST_FILE_TYPE_FAILURE =
  "@@lookup/ASSESSMENT_REQUEST_FILE_TYPE_FAILURE";

export const getAssessmentRequestFileType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUEST_FILE_TYPE_LOADING,
  });
  try {
    const res = await axios.get(
      `/app/lookup/e=ClientFileType`
    );
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUEST_FILE_TYPE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUEST_FILE_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUEST_FILE_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const GET_ASSESSMENT_REQUEST_STATUS_LIST_LOADING = "@@lookup/GET_ASSESSMENT_REQUEST_STATUS_LIST_LOADING";
export const GET_ASSESSMENT_REQUEST_STATUS_LIST_SUCCESS = "@@lookup/GET_ASSESSMENT_REQUEST_STATUS_LIST_SUCCESS";
export const GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE = "@@lookup/GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE";

export const getAssessmentRequestStatusList = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_REQUEST_STATUS_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=AssessmentRequestStatus`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_REQUEST_STATUS_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_REQUEST_STATUS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_REMEDIATION_PREFERENCES_LOADING = "@@lookup/GET_REMEDIATION_PREFERENCES_LOADING";
export const GET_REMEDIATION_PREFERENCES_SUCCESS = "@@lookup/GET_REMEDIATION_PREFERENCES_SUCCESS";
export const GET_REMEDIATION_PREFERENCES_FAILURE = "@@lookup/GET_REMEDIATION_PREFERENCES_FAILURE";

export const getRemediationPreferences = () => async (dispatch) => {
  dispatch({
    type: GET_REMEDIATION_PREFERENCES_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=RemediationPreferences`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_REMEDIATION_PREFERENCES_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_REMEDIATION_PREFERENCES_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_REMEDIATION_PREFERENCES_FAILURE,
      payload: getErrorObject(err)
    })
  }
}

export const GET_PRODUCT_TYPE_LIST_LOADING = "@@lookup/GET_PRODUCT_TYPE_LIST_LOADING";
export const GET_PRODUCT_TYPE_LIST_SUCCESS = "@@lookup/GET_PRODUCT_TYPE_LIST_SUCCESS";
export const GET_PRODUCT_TYPE_LIST_FAILURE = "@@lookup/GET_PRODUCT_TYPE_LIST_FAILURE";

export const getProductTypesList = () => async (dispatch) => {
  dispatch({
    type: GET_PRODUCT_TYPE_LIST_LOADING,
  });
  const body = getAppLookupBody(["ProductType"])
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_PRODUCT_TYPE_LIST_SUCCESS,
      payload: res?.data,
    });
  } catch {
    dispatch({
      type: GET_PRODUCT_TYPE_LIST_FAILURE,
    });
  }
};

export const GET_VENDOR_ROLE_LIST_LOADING = "@@lookup/GET_VENDOR_ROLE_LIST_LOADING";
export const GET_VENDOR_ROLE_LIST_SUCCESS = "@@lookup/GET_VENDOR_ROLE_LIST_SUCCESS";
export const GET_VENDOR_ROLE_LIST_FAILURE = "@@lookup/GET_VENDOR_ROLE_LIST_FAILURE";

export const getVendorRolesList = () => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_ROLE_LIST_LOADING,
  });
  const body = getAppLookupBody(["ProductVendorRole"])
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_VENDOR_ROLE_LIST_SUCCESS,
      payload: res?.data,
    });
  } catch {
    dispatch({
      type: GET_VENDOR_ROLE_LIST_FAILURE,
    });
  }
};



export const GET_DOMAIN_RULE_LOOKUP_LOADING = '@@lookup/GET_DOMAIN_RULE_LOOKUP_LOADING';
export const GET_DOMAIN_RULE_LOOKUP_SUCCESS = '@@lookup/GET_DOMAIN_RULE_LOOKUP_SUCCESS';
export const GET_DOMAIN_RULE_LOOKUP_FAILURE = '@@lookup/GET_DOMAIN_RULE_LOOKUP_FAILURE';

export const getUserDomainRuleLookupList = () => async (dispatch) => {
  dispatch({ type: GET_DOMAIN_RULE_LOOKUP_LOADING });
  try {
    const body = getUserDomainRuleLookupBody();
    const response = await axios.post(`/app/lookup/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_DOMAIN_RULE_LOOKUP_FAILURE,
        payload: []
      })
    } else {
      dispatch({
        type: GET_DOMAIN_RULE_LOOKUP_SUCCESS,
        payload: response?.data?.Data
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOMAIN_RULE_LOOKUP_FAILURE,
      payload: getErrorObject(err)
    });
  }
};

export const GET_RESPONSE_CATEGORY_LIST_LOADING = '@@lookup/GET_RESPONSE_CATEGORY_LIST_LOADING';
export const GET_RESPONSE_CATEGORY_LIST_SUCCESS = '@@lookup/GET_RESPONSE_CATEGORY_LIST_SUCCESS';
export const GET_RESPONSE_CATEGORY_LIST_FAILURE = '@@lookup/GET_RESPONSE_CATEGORY_LIST_FAILURE';

export const getResponseCategoryList = () => async (dispatch) => {
  dispatch({ type: GET_RESPONSE_CATEGORY_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=YesNo`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_RESPONSE_CATEGORY_LIST_FAILURE,
        payload: []
      })
    } else {
      dispatch({
        type: GET_RESPONSE_CATEGORY_LIST_SUCCESS,
        payload: response?.data
      });
    }

  } catch (err) {
    dispatch({
      type: GET_RESPONSE_CATEGORY_LIST_FAILURE,
      payload: getErrorObject(err)
    });
  }
};

export const GET_VALIDATION_PREFERENCES_LOADING = "@@lookup/GET_VALIDATION_PREFERENCES_LOADING";
export const GET_VALIDATION_PREFERENCES_SUCCESS = "@@lookup/GET_VALIDATION_PREFERENCES_SUCCESS";
export const GET_VALIDATION_PREFERENCES_FAILURE = "@@lookup/GET_VALIDATION_PREFERENCES_FAILURE";

export const getValidationPreferences = () => async (dispatch) => {
  dispatch({
    type: GET_VALIDATION_PREFERENCES_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=ValidationPreferences`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_VALIDATION_PREFERENCES_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_VALIDATION_PREFERENCES_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VALIDATION_PREFERENCES_FAILURE,
      payload: getErrorObject(err)
    })
  }
}


export const GET_PRODUCT_COMPONENT_TYPE_LOADING = "@@lookup/GET_PRODUCT_COMPONENT_TYPE_LOADING";
export const GET_PRODUCT_COMPONENT_TYPE_SUCCESS = "@@lookup/GET_PRODUCT_COMPONENT_TYPE_SUCCESS";
export const GET_PRODUCT_COMPONENT_TYPE_FAILURE = "@@lookup/GET_PRODUCT_COMPONENT_TYPE_FAILURE";

export const getProductComponentTypes = () => async (dispatch) => {
  dispatch({
    type: GET_PRODUCT_COMPONENT_TYPE_LOADING,
  });
  const body = getAppLookupBody(["ComponentType"])
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_PRODUCT_COMPONENT_TYPE_SUCCESS,
      payload: res?.data?.Data?.ComponentType,
    });
  } catch {
    dispatch({
      type: GET_PRODUCT_COMPONENT_TYPE_FAILURE,
    });
  }
};

export const GET_PRODUCT_COMPONENT_DATA_LOCATION_LOADING = "@@lookup/GET_PRODUCT_COMPONENT_DATA_LOCATION_LOADING";
export const GET_PRODUCT_COMPONENT_DATA_LOCATION_SUCCESS = "@@lookup/GET_PRODUCT_COMPONENT_DATA_LOCATION_SUCCESS";
export const GET_PRODUCT_COMPONENT_DATA_LOCATION_FAILURE = "@@lookup/GET_PRODUCT_COMPONENT_DATA_LOCATION_FAILURE";

export const getProductComponentDataLocation = () => async (dispatch) => {
  dispatch({
    type: GET_PRODUCT_COMPONENT_DATA_LOCATION_LOADING,
  });
  const body = getAppLookupBody(["ComponentDataLocation"])
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_PRODUCT_COMPONENT_DATA_LOCATION_SUCCESS,
      payload: res?.data?.Data?.ComponentDataLocation,
    });
  } catch {
    dispatch({
      type: GET_PRODUCT_COMPONENT_DATA_LOCATION_FAILURE,
    });
  }
};

export const GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_LOADING = "@@lookup/GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_LOADING";
export const GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_SUCCESS = "@@lookup/GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_SUCCESS";
export const GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_FAILURE = "@@lookup/GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_FAILURE";

export const getProductComponentMedicalDevice = () => async (dispatch) => {
  dispatch({
    type: GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_LOADING,
  });
  const body = getAppLookupBody(["MedicalDevice"])
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_SUCCESS,
      payload: res?.data?.Data?.MedicalDevice,
    });
  } catch {
    dispatch({
      type: GET_PRODUCT_COMPONENT_MEDICAL_DEVICE_FAILURE,
    });
  }
};

export const GET_EVIDENCE_LOOKUP_LOADING = "@@lookup/GET_EVIDENCE_LOOKUP_LOADING";
export const GET_EVIDENCE_LOOKUP_SUCCESS = "@@lookup/GET_EVIDENCE_LOOKUP_SUCCESS";
export const GET_EVIDENCE_LOOKUP_FAILURE = "@@lookup/GET_EVIDENCE_LOOKUP_FAILURE";

export const getEvidenceLookup = () => async (dispatch) => {
  dispatch({
    type: GET_EVIDENCE_LOOKUP_LOADING,
  });
  const body = getAppLookupBody(["EvidenceType", "EvidenceSubType", "FileType"])
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_EVIDENCE_LOOKUP_SUCCESS,
      payload: res?.data?.Data,
    });
  } catch {
    dispatch({
      type: GET_EVIDENCE_LOOKUP_FAILURE,
    });
  }
};

export const GET_VENDOR_REPLACEMENT_REASON_LOADING = "@@lookup/GET_VENDOR_REPLACEMENT_REASON_LOADING";
export const GET_VENDOR_REPLACEMENT_REASON_SUCCESS = "@@lookup/GET_VENDOR_REPLACEMENT_REASON_SUCCESS";
export const GET_VENDOR_REPLACEMENT_REASON_FAILURE = "@@lookup/GET_VENDOR_REPLACEMENT_REASON_FAILURE";

export const getVendorReplacementReason = () => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_REPLACEMENT_REASON_LOADING,
  });
  const body = getAppLookupBody(["VendorReplacementReason"])
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_VENDOR_REPLACEMENT_REASON_SUCCESS,
      payload: res?.data?.Data?.VendorReplacementReason,
    });
  } catch {
    dispatch({
      type: GET_VENDOR_REPLACEMENT_REASON_FAILURE,
    });
  }
};

export const GET_ASSESSMENT_VRAS_REQUST_LOADING = "@@lookup/GET_ASSESSMENT_VRAS_REQUST_LOADING";
export const GET_ASSESSMENT_VRAS_REQUST_SUCCESS = "@@lookup/GET_ASSESSMENT_VRAS_REQUST_SUCCESS";
export const GET_ASSESSMENT_VRAS_REQUST_FAILURE = "@@lookup/GET_ASSESSMENT_VRAS_REQUST_FAILURE";

export const getAssessmentVRASRequestType = () => async (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_VRAS_REQUST_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentVRASRequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_VRAS_REQUST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_VRAS_REQUST_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_VRAS_REQUST_FAILURE,
      payload: getErrorObject(err)
    })
  }
}