export const DEFAULT_REGISTERED_BUSINESS_ADDRESS_COUNTRY = "United States";
export const DEAFULT_US_PHONE_CODE = "+1";
export const MAX_ZIP_CODE_FOR_NON_US = 8;
export const MAX_ZIP_CODE_FOR_US = 5;
export const CONFIRM_VENDOR_MESSAGE = "This action will add the vendor. Are you sure?";
export const MERGE_VENDOR_MESSAGE = "This action will merge the selected vendor with the added vendor. Are you sure?";

//Alert messages
export const VENDOR_MERGED_SUCCESSFULLY = "Vendor merged successfully."
export const VENDOR_ADDDED_SUCCESSFULLY = "Vendor added successfully.";
