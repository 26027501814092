import axios from "axios";
import { getErrorObject } from "../utils/apiUtils";
import { setMenu } from "./navbar";

export const SESSION_REQUEST = "SESSION_REQUEST";
export const SESSION_SUCCESS = "SESSION_SUCCESS";
export const SESSION_FAIL = "SESSION_FAIL";

export const getSession =
  (
    formData = {},
    getAccessTokenSilently,
    callback,
    failedCallback = null,
    ignoreCache = false
  ) =>
  async (dispatch) => {
    dispatch({ type: SESSION_REQUEST });
    try {
      const token = await getAccessTokenSilently({ ignoreCache: ignoreCache });
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      localStorage.setItem("corl-token", token);
    } catch (ex) {
      dispatch({
        type: SESSION_FAIL,
        authError: true,
        payload: getErrorObject(ex),
      });
      if (failedCallback) {
        failedCallback();
      }
    }
    const body = JSON.stringify(formData);

    try {
      const res = await axios.post("/app/usersession", body);
      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: SESSION_FAIL,
          authError: true,
        });
      } else {
        dispatch({
          type: SESSION_SUCCESS,
          payload: res.data?.Data,
        });
        dispatch(setMenu(res.data?.Data?.MenuClaimList));
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: SESSION_FAIL,
        payload: getErrorObject(err),
      });
    }
  };

export const VERIFY_SESSION_REQUEST = "VERIFY_SESSION_REQUEST";
export const VERIFY_SESSION_SUCCESS = "VERIFY_SESSION_SUCCESS";
export const VERIFY_SESSION_FAIL = "VERIFY_SESSION_FAIL";

export const verifySession = () => async (dispatch) => {
  dispatch({ type: VERIFY_SESSION_REQUEST });
  try {
    const res = await axios.get("/app/usersession");
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: VERIFY_SESSION_FAIL,
        authError: true,
      });
    } else {
      dispatch({
        type: VERIFY_SESSION_SUCCESS,
        payload: res.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: VERIFY_SESSION_FAIL,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SESSION_DETAILS = "SET_SESSION_DETAILS";

export const setSessionDetails = (details) => async (dispatch) => {
  dispatch({
    type: SET_SESSION_DETAILS,
    payload: details,
  });
};

export const DELETE_SESSION = "DELETE_SESSION";

export const deleteSession = (callback) => async (dispatch) => {
  try {
    const res = await axios.delete("/app/usersession");
    dispatch({
      type: DELETE_SESSION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_SESSION,
      payload: getErrorObject(err),
    });
  } finally {
    if (callback) {
      callback();
    }
    return true;
  }
};
