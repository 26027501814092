import axios from "axios";
import { setErrors } from "./errorHandler";
import { getErrorObject } from "utils/apiUtils";
import { NO_DATA_FOUND } from "constants/errorMessage";
import { getSimilarVendorsListSearchBody, getVendorCompanyInfoBody } from "utils/verifyVendorUtils";
import { VENDOR_ENTITY } from "constants/constants";

export const SEARCH_VENDOR_COMPANY_INFORMATION_LOADING = "@@verifyVendor/SEARCH_VENDOR_COMPANY_INFORMATION_LOADING";
export const SEARCH_VENDOR_COMPANY_INFORMATION_SUCCESS = "@@verifyVendor/SEARCH_VENDOR_COMPANY_INFORMATION_SUCCESS";
export const SEARCH_VENDOR_COMPANY_INFORMATION_FAILURE = "@@verifyVendor/SEARCH_VENDOR_COMPANY_INFORMATION_FAILURE";

export const getVendorCompanyInformtion = (vendorOrgId) => async (dispatch) => {
    dispatch({
        type: SEARCH_VENDOR_COMPANY_INFORMATION_LOADING,
    });
    try {
        const body = getVendorCompanyInfoBody(vendorOrgId);
        const res = await axios.post("vendor/search", body);
        if (res?.data?.ErrorCount > 0) {
            const errors = res?.data?.Errors.filter(
                (it) => it.Message !== NO_DATA_FOUND
            );
            dispatch(setErrors(errors));
            dispatch({
                type: SEARCH_VENDOR_COMPANY_INFORMATION_FAILURE,
                payload: {},
            });
        } else {
            dispatch({
                type: SEARCH_VENDOR_COMPANY_INFORMATION_SUCCESS,
                payload: res?.data,
            });
        }
    } catch (err) {
        dispatch({
            type: SEARCH_VENDOR_COMPANY_INFORMATION_FAILURE,
            payload: getErrorObject(err),
        });
    }
};

export const SEARCH_SIMILAR_VENDORS_LIST_LOADING = "@@verifyVendor/SEARCH_SIMILAR_VENDORS_LIST_LOADING";
export const SEARCH_SIMILAR_VENDORS_LIST_SUCCESS = "@@verifyVendor/SEARCH_SIMILAR_VENDORS_LIST_SUCCESS";
export const SEARCH_SIMILAR_VENDORS_LIST_FAILURE = "@@verifyVendor/SEARCH_SIMILAR_VENDORS_LIST_FAILURE";

export const getSimilarVendorSearchList = (formData) => async (dispatch) => {
    dispatch({
        type: SEARCH_SIMILAR_VENDORS_LIST_LOADING,
    });

    const reqBody = {
        VendorName: formData?.VendorOrgName,
        WebsiteUrl: formData?.DomainName,
    }

    const body = getSimilarVendorsListSearchBody(reqBody);
    try {
        const res = await axios.post(`/app/customer/vendor/fulltext/search`, body);
        if (res?.data?.ErrorCount > 0) {
            dispatch({
                type: SEARCH_SIMILAR_VENDORS_LIST_FAILURE,
                payload: [],
            });
        } else {
            dispatch({
                type: SEARCH_SIMILAR_VENDORS_LIST_SUCCESS,
                payload: res?.data,
            });
        }
    } catch (err) {
        dispatch({
            type: SEARCH_SIMILAR_VENDORS_LIST_FAILURE,
            payload: getErrorObject(err),
        });
    }
};

export const CONFIRM_VENDOR_COMPANY_INFO_LOADING = "@@verifyVendor/CONFIRM_VENDOR_COMPANY_INFO_LOADING";
export const CONFIRM_VENDOR_COMPANY_INFO_SUCCESS = "@@verifyVendor/CONFIRM_VENDOR_COMPANY_INFO_SUCCESS";
export const CONFIRM_VENDOR_COMPANY_INFO_FAILURE = "@@verifyVendor/CONFIRM_VENDOR_COMPANY_INFO_FAILURE";

export const addVendorCompanyInformation =
    (id, callback) => async (dispatch) => {
        dispatch({
            type: CONFIRM_VENDOR_COMPANY_INFO_LOADING,
        });

        const body = {
            EntityName: VENDOR_ENTITY,
            ConfirmId: id
        };

        try {
            const res = await axios.post(`/serviceprovider/action/confirm`, body);
            if (res?.data?.ErrorCount > 0) {
                dispatch(setErrors(res?.data?.Errors));
                dispatch({
                    type: CONFIRM_VENDOR_COMPANY_INFO_FAILURE,
                    payload: {},
                });
            } else {
                dispatch({
                    type: CONFIRM_VENDOR_COMPANY_INFO_SUCCESS,
                    payload: res?.data,
                });

                if (callback) {
                    callback()
                }
            }
        } catch (err) {
            dispatch({
                type: CONFIRM_VENDOR_COMPANY_INFO_FAILURE,
                payload: getErrorObject(err),
            });
        }
    };


export const MERGE_VENDOR_LOADING = "@@verifyVendor/MERGE_VENDOR_LOADING";
export const MERGE_VENDOR_SUCCESS = "@@verifyVendor/MERGE_VENDOR_SUCCESS";
export const MERGE_VENDOR_FAILURE = "@@verifyVendor/MERGE_VENDOR_FAILURE";

export const mergeVendor =
    (id, formData, callback) => async (dispatch) => {
        dispatch({
            type: MERGE_VENDOR_LOADING,
        });
        const body = {
            EntityName: VENDOR_ENTITY,
            ToId: formData?.VendorOrgId,
            FromId: id
        };
        try {
            const res = await axios.post(`/serviceprovider/action/merge`, body);
            if (res?.data?.ErrorCount > 0) {
                dispatch(setErrors(res?.data?.Errors));
                dispatch({
                    type: MERGE_VENDOR_FAILURE,
                    payload: {},
                });
            } else {
                dispatch({
                    type: MERGE_VENDOR_SUCCESS,
                    payload: res?.data,
                });
                if (callback) {
                    callback()
                }
            }
        } catch (err) {
            dispatch({
                type: MERGE_VENDOR_FAILURE,
                payload: getErrorObject(err),
            });
        }
    };


export const PUT_VENDOR_COMPANY_INFO_LOADING = "@@verifyVendor/PUT_VENDOR_COMPANY_INFO_LOADING";
export const PUT_VENDOR_COMPANY_INFO_SUCCESS = "@@verifyVendor/PUT_VENDOR_COMPANY_INFO_SUCCESS";
export const PUT_VENDOR_COMPANY_INFO_FAILURE = "@@verifyVendor/PUT_VENDOR_COMPANY_INFO_FAILURE";

export const updateVendorCompanyInformation =
    (id, formData, callback) => async (dispatch) => {
        dispatch({
            type: PUT_VENDOR_COMPANY_INFO_LOADING,
        });

        const body = {
            VendorOrgId: id,
            VendorOrgName: formData?.VendorOrgName,
            DomainName: formData?.DomainName,
            RegisteredBusinessAddressLine1: formData?.RegisteredBusinessAddressLine1,
            RegisteredBusinessAddressLine2: formData?.RegisteredBusinessAddressLine2,
            RegisteredBusinessAddressCity: formData?.RegisteredBusinessAddressCity,
            RegisteredBusinessAddressState: formData?.RegisteredBusinessAddressState,
            RegisteredBusinessAddressCountry: formData?.RegisteredBusinessAddressCountry,
            RegisteredBusinessAddressZip: formData?.RegisteredBusinessAddressZip,
            PhoneCode: formData?.PhoneCode,
            MainPhone: formData?.MainPhone,
            PhoneExtension: formData?.PhoneExtension,
            NumberOfEmployeesRangeId: formData?.NumberOfEmployeesRangeId,
            YearFounded: formData?.YearFounded,
            LogoFileLocation: "",
            ZendeskOrgId: formData?.ZendeskOrgId,
            IsVerified: false,
            AddVendorToDynamics: false
        };

        try {
            const res = await axios.put(`/vendor/${id}`, body);
            if (res?.data?.ErrorCount > 0) {
                dispatch(setErrors(res?.data?.Errors));
                dispatch({
                    type: PUT_VENDOR_COMPANY_INFO_FAILURE,
                    payload: {},
                });
            } else {
                dispatch({
                    type: PUT_VENDOR_COMPANY_INFO_SUCCESS,
                    payload: res?.data?.Data,
                });
                if (callback) {
                    callback();
                }
            }
        } catch (err) {
            dispatch({
                type: PUT_VENDOR_COMPANY_INFO_FAILURE,
                payload: getErrorObject(err),
            });
        }
    };


export const SET_SIMILAR_VENDOR_LIST = "@@assessmentsRequests/SET_SIMILAR_VENDOR_LIST";

export const setSimilarVendorList =
    (list = [], callback) =>
        async (dispatch) => {
            dispatch({
                type: SET_SIMILAR_VENDOR_LIST,
                payload: list,
            });
        };