import { isNil } from "lodash";

export const getProductGroupListBody  = (vendorOrgId = null) => {
  const criterion = [];
  if(!isNil(vendorOrgId)){
    criterion.push({
      "field": "VendorOrgId",
      "operator": "=",
      "value": vendorOrgId
    })
  }
    return {
    "intent": "",
    "fields": [
      "ProductGroupName",
      "VendorOrgId",
      "Active"
    ],
    "criterion": criterion,
    "sort": [
      {
        "field": "ProductGroupName",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0
  }
};

export const getProductListBody  = (vendorOrgId = null) => {
  const criterion = [];
  if(!isNil(vendorOrgId)){
    criterion.push({
      "field": "VendorOrgId",
      "operator": "=",
      "value": vendorOrgId
    })
  }
  return  {
  "intent": "",
  "fields": [
    "ProductName",
    "ProductType",
    "ProductVendorRole",
    "ProductId",
    "ProductGroup",
    "ProductNameForVendor",
    "VendorOrgId"
  ],
  "criterion": criterion,
  "sort": [
    {
      "field": "ProductName",
      "order": "ASC"
    }
  ],
  "page": 0,
  "pageSize": 0
}
};

export const getSelectedProductBody  = (vendorOrgId = null, productId = null) => {
  const criterion = [];
  if(!isNil(vendorOrgId) && !isNil(productId)){
    criterion.push({
      "field": "VendorOrgId",
      "operator": "=",
      "value": vendorOrgId
    },{
      "field": "ProductId",
      "operator": "=",
      "value": productId
    })
  }
  return  {
  "intent": "",
  "fields": [
    "ProductName",
    "ProductType",
    "ProductVendorRole",
    "ProductId",
    "ProductGroup",
    "ProductNameForVendor",
    "CCDynVRASId",
  ],
  "criterion": criterion,
  "sort": [
    {
      "field": "ProductName",
      "order": "ASC"
    }
  ],
  "page": 0,
  "pageSize": 0
}
};

export const getProductComponentBody  = (productVendorId = null) => {
  const criterion = [];
  if(!isNil(productVendorId)){
    criterion.push({
      "field": "ProductVendorId",
      "operator": "=",
      "value": productVendorId
    })
  }
  return {
    "Intent": "",
    "Fields": [
        "ProductVendorId",
        "ProductComponentName",
        "ProductComponentType",
        "ProductComponentDataLocation",
        "ProductMedicalDevice",
        "DataLeavesClientSystem",
        "ProductName",
        "VendorOrgName"
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0
}
};

export const getCorlClearedAssessmentBody  = (data) => {
  return {
    "VendorOrgId": data?.VendorOrgId,
    "VendorOrgName": data?.VendorOrgName,
    "VendorUrl": data?.VendorUrl,
    "VendorContactFirstName": data?.VendorContactFirstName,
    "VendorContactLastName": data?.VendorContactLastName,
    "VendorContactEmail": data?.VendorContactEmail,
    "ProductVendorId": data?.ProductVendorId,
    "ProductName": data?.ProductName
};
};