import { isEmpty, isNil } from "lodash";

export const getAllPortalUserListSearchBody = (formData) => {
  const criterion = [];
  if (!isNil(formData?.AssociationType)) {
    criterion.push({
      field: "AppId",
      operator: "=",
      value: formData?.AssociationType,
    });
  }
  if (
    !isNil(formData?.OrganizationType) &&
    formData?.OrganizationType?.length > 0
  ) {
    criterion.push({
      Field: "OrgId",
      Operator: "in",
      Value: formData?.OrganizationType.join(","),
    });
  }
  if (!isNil(formData?.SearchValue)) {
    criterion.push({
      field: "UserEmailFirstName",
      operator: "startswith",
      value: formData?.SearchValue,
    });
  }
  return {
    intent: "",
    fields: [
      "OrgId",
      "OrgName",
      "UserId",
      "UserEmail",
      "FirstName",
      "MiddleName",
      "LastName",
      "UserRoleList",
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getOrganisationListSearchBody = (appId, page, searchValue) => {
  const criterion = [];
  if (appId !== 0) {
    criterion.push({
      field: "AppId",
      operator: "=",
      value: appId,
    });
  }
  if (!isEmpty(searchValue)) {
    criterion.push({
      field: "OrgName",
      operator: "startswith",
      value: searchValue,
    });
  }
  return {
    intent: "",
    fields: ["OrgName"],
    criterion: criterion,
    sort: [
      {
        field: "OrgName",
        order: "ASC",
      },
    ],
    page: page,
    pageSize: 20,
  };
};

export const getUserDetailsSearchBody = (userId) => {
  return {
    intent: "",
    fields: [
      "UserEmail",
      "FirstName",
      "MiddleName",
      "LastName",
      "NamePrefix",
      "NameSuffix",
      "ProfilePictureUrl",
      "ExternalIdpId",
      "VendorOrgId",
      "VendorOrgName",
      "ClientOrgId",
      "ClientOrgName",
      "ServiceProviderOrgId",
      "ServiceProviderOrgName",
      "AllUserList",
      "UserRoleList",
      "Active",
    ],
    criterion: [
      {
        field: "UserId",
        operator: "=",
        value: userId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
