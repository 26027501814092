import axios from "axios";
import { getErrorObject } from "utils/apiUtils";
import { setErrors } from "./errorHandler";
import {
  getAllPortalUserListSearchBody,
  getOrganisationListSearchBody,
  getUserDetailsSearchBody,
} from "utils/allUsersUtils";
import { NO_DATA_FOUND } from "constants/errorMessage";

export const SEARCH_ALL_PORTAL_USERS_LIST_LOADING =
  "@@allUsers/SEARCH_ALL_PORTAL_USERS_LIST_LOADING";
export const SEARCH_ALL_PORTAL_USERS_LIST_SUCCESS =
  "@@allUsers/SEARCH_ALL_PORTAL_USERS_LIST_SUCCESS";
export const SEARCH_ALL_PORTAL_USERS_LIST_FAILURE =
  "@@allUsers/SEARCH_ALL_PORTAL_USERS_LIST_FAILURE";

export const getAllPortalUserListData =
  (formData = null) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ALL_PORTAL_USERS_LIST_LOADING });
    try {
      const body = getAllPortalUserListSearchBody(formData);
      const response = await axios.post(`/user/org/search`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
          (it) => it.Message !== NO_DATA_FOUND
        );
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_ALL_PORTAL_USERS_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ALL_PORTAL_USERS_LIST_SUCCESS,
          payload: response?.data?.Data?.Rows,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ALL_PORTAL_USERS_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_ORGANISATION_LIST_LOADING =
  "@@allUsers/SEARCH_ORGANISATION_LIST_LOADING";
export const SEARCH_ORGANISATION_LIST_SUCCESS =
  "@@allUsers/SEARCH_ORGANISATION_LIST_SUCCESS";
export const SEARCH_ORGANISATION_LIST_FAILURE =
  "@@allUsers/SEARCH_ORGANISATION_LIST_FAILURE";

export const searchOrganisationListData =
  (appId = 0, page = 1, searchValue = "", callback) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ORGANISATION_LIST_LOADING });
    try {
      const body = getOrganisationListSearchBody(appId, page, searchValue);
      const response = await axios.post(`/org/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ORGANISATION_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ORGANISATION_LIST_SUCCESS,
          payload: response?.data?.Data,
          searchValue: searchValue,
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ORGANISATION_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_ORG_LIST_PAGE = "@@allusers/SET_ORG_LIST_PAGE";

export const setOrgListPage =
  (page = 1, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_ORG_LIST_PAGE,
      payload: page,
    });
    if (callback) {
      callback();
    }
  };

export const UPDATE_ORG_LIST = "@@allusers/UPDATE_ORG_LIST";

export const updateOrgList =
  (list = [], callback) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_ORG_LIST,
      payload: list,
    });
    if (callback) {
      callback();
    }
  };

export const CLEAR_ORG_LIST = "@@allusers/CLEAR_ORG_LIST";

export const clearOrgList = (callback) => async (dispatch) => {
  dispatch({
    type: CLEAR_ORG_LIST,
  });
  if (callback) {
    callback();
  }
};

export const UPDATE_SEARCH_LIST = "@@allUsers/UPDATE_SEARCH_LIST";

export const updateSearchList =
  (list = [], callback) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_LIST,
      payload: list,
    });
    if (callback) {
      callback();
    }
  };

export const SEARCH_USER_DETAILS_LOADING =
  "@@allUsers/SEARCH_USER_DETAILS_LOADING";
export const SEARCH_USER_DETAILS_SUCCESS =
  "@@allUsers/SEARCH_USER_DETAILS_SUCCESS";
export const SEARCH_USER_DETAILS_FAILURE =
  "@@allUsers/SEARCH_USER_DETAILS_FAILURE";

export const getUserDetailsData = (userId) => async (dispatch) => {
  dispatch({ type: SEARCH_USER_DETAILS_LOADING });
  try {
    const body = getUserDetailsSearchBody(userId);
    const response = await axios.post(`/user/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_USER_DETAILS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_USER_DETAILS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_USER_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_USER_DETAILS_LOADING =
  "@@allUsers/UPDATE_USER_DETAILS_LOADING";
export const UPDATE_USER_DETAILS_SUCCESS =
  "@@allUsers/UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE =
  "@@allUsers/UPDATE_USER_DETAILS_FAILURE";

export const updateUserDetails = (formData) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_DETAILS_LOADING,
  });

  let tempFormData = {
    UserId: formData?.UserId,
    UserEmail: formData?.UserEmail,
    FirstName: formData?.FirstName,
    MiddleName: formData?.MiddleName,
    LastName: formData?.LastName,
    NamePrefix: formData?.NamePrefix,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.put(`/user/${formData?.UserId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_USER_DETAILS_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_USER_DETAILS_SUCCESS,
        payload: response?.data,
      });
      dispatch(getUserDetailsData(formData?.UserId));
    }
  } catch {
    dispatch({
      type: UPDATE_USER_DETAILS_FAILURE,
    });
  }
};

export const DELETE_USER_FROM_AUTH0_LOADING =
  "@@allUsers/DELETE_USER_FROM_AUTH0_LOADING";
export const DELETE_USER_FROM_AUTH0_SUCCESS =
  "@@allUsers/DELETE_USER_FROM_AUTH0_SUCCESS";
export const DELETE_USER_FROM_AUTH0_FAILURE =
  "@@allUsers/DELETE_USER_FROM_AUTH0_FAILURE";

export const removeUserFromAuth0 = (userId, callback) => async (dispatch) => {
  dispatch({ type: DELETE_USER_FROM_AUTH0_LOADING });

  let reqBody = {
    UserId: userId,
  };

  const body = JSON.stringify(reqBody);
  try {
    const response = await axios.post(
      `/user/${userId}/action/unassignall`,
      body
    );
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: DELETE_USER_FROM_AUTH0_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_USER_FROM_AUTH0_SUCCESS,
        payload: response.data,
      });
      dispatch(getAllPortalUserListData());
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: DELETE_USER_FROM_AUTH0_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_USER_ASSOCIATION_LOADING =
  "@@allUsers/DELETE_USER_ASSOCIATION_LOADING";
export const DELETE_USER_ASSOCIATION_SUCCESS =
  "@@allUsers/DELETE_USER_ASSOCIATION_SUCCESS";
export const DELETE_USER_ASSOCIATION_FAILURE =
  "@@allUsers/DELETE_USER_ASSOCIATION_FAILURE";

export const removeUserAssociation =
  (userId, userRoleDetails, callback) => async (dispatch) => {
    dispatch({ type: DELETE_USER_ASSOCIATION_LOADING });

    let reqBody = {
      UserId: userId,
      Roles: [{ UserRoleId: userRoleDetails?.UserRoleId }],
      AppId: userRoleDetails?.AppId,
      OrgId: userRoleDetails?.OrgId,
    };

    const body = JSON.stringify(reqBody);
    try {
      const response = await axios.post(
        `/user/${userId}/action/unassign`,
        body
      );
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: DELETE_USER_ASSOCIATION_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: DELETE_USER_ASSOCIATION_SUCCESS,
          payload: response.data,
        });
        dispatch(getAllPortalUserListData());
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: DELETE_USER_ASSOCIATION_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_USER_DETAILS_FORM_EDITABLE =
  "@@allUsers/SET_USER_DETAILS_FORM_EDITABLE";

export const setUserDetailsFormEditable =
  (isEditable = false) =>
  async (dispatch) => {
    dispatch({
      type: SET_USER_DETAILS_FORM_EDITABLE,
      isEditable,
    });
  };

export const SET_USER_DETAILS = "@@allUsers/SET_USER_DETAILS";

export const setUserDetails =
  (data = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_USER_DETAILS,
      payload: data,
    });
  };

export const BLOCK_UNBLOCK_USER_FROM_AUTH0_LOADING =
  "@@allUsers/BLOCK_UNBLOCK_USER_FROM_AUTH0_LOADING";
export const BLOCK_UNBLOCK_USER_FROM_AUTH0_SUCCESS =
  "@@allUsers/BLOCK_UNBLOCK_USER_FROM_AUTH0_SUCCESS";
export const BLOCK_UNBLOCK_USER_FROM_AUTH0_FAILURE =
  "@@allUsers/BLOCK_UNBLOCK_USER_FROM_AUTH0_FAILURE";

export const blockUnblockUserFromAuth0 = (userId) => async (dispatch) => {
  dispatch({ type: BLOCK_UNBLOCK_USER_FROM_AUTH0_LOADING });

  try {
    const response = await axios.post(`/user/${userId}/action/block`);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: BLOCK_UNBLOCK_USER_FROM_AUTH0_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: BLOCK_UNBLOCK_USER_FROM_AUTH0_SUCCESS,
        payload: response.data,
      });
      dispatch(getUserDetailsData(userId));
    }
  } catch (err) {
    dispatch({
      type: BLOCK_UNBLOCK_USER_FROM_AUTH0_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
