export const appbar = () => {
    return {
        backgroundColor: "#FFFFFF",
        minHeight: "96px",
    };
};

export const appBarContainer = () => {
    return {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0px",
        gap: "10px",
        maxWidth: "unset !important",
        height: "96px",
    };
}

export const headerLogo = () => {
    return {
        display: "flex",
        marginRight: {
            md: "18px"
        },
        alignItems: "center",
        fontFamily: "monospace",
        fontWeight: 700,
        letterSpacing: ".3rem",
        color: "inherit",
        textDecoration: "none",
    };
};

export const linkWrapper = () => {
    return {
        width: {
            lg: 'fit-content',
            md: 'min-content',
        },
    }
}

export const menuListWrapper = () => {
    return {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: {
            lg: '32px',
            md: '16px',
        },
    };
};

export const menuWrapper = () => {
    return {
        "& .MuiBox-root": {
            position: "relative"
        }
    }
}

export const menuLinkWrapper = (theme) => {
    return {
        height: "96px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        color: `secondary.main`,
        cursor: "pointer",
        "& svg": {
            fontSize: "18px",
            color: "secondary.main",
            marginBottom: "2px",
            cursor: "pointer",
        },
        "&:hover": {
            color: `primary.main`,
        },
        "&:hover >svg": {
            color: "primary.main",
        },
        ".activeNavBar": {
            color: `primary.main`,
            "& svg": {
                color: "primary.main",
            },
        },
        "&.inactiveTab": {
            color: theme.palette.action.disabled,
            "P": {
                cursor: "auto",
            },
            "& svg": {
                color: theme.palette.action.disabled,
                cursor: "auto",
            },
            "&:hover": {
                cursor: "auto"
            }
        }
    };
};


export const menuLink = () => {
    return {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "14px",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        position: "relative",
        textAlign: "center",
    }
}


export const notificationsBoxWrapper = () => {
    return {
        display: "flex",
        flexGrow: 0,
        alignItems: "center",
    };
};

export const chatsStyle = () => {
    return {
        textAlign: "center",
    };
};

export const notificationsStyle = () => {
    return {
        textAlign: "center",
        marginLeft: "24px",
    };
};

export const notificationIcon = () => {
    return {
        height: "unset",
        width: "20px",
    };
}

export const countWrapper = () => {
    return {
        backgroundColor: `primary.main`,
        width: "23px",
        height: "15px",
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "10px",
        lineHeight: "13px",
        alignItems: "center",
        textAlign: "center",
        color: "#FFFFFF",
        display: "block",
        margin: "auto",
        borderRadius: "10px",
        paddingTop: "1px",
        marginTop: "8px",
    };
}

export const userIconStyle = () => {
    return {
        padding: "18px",
        height: "100%",
        "& svg": {
            fontSize: "24px",
            color: "secondary.main",
            marginBottom: "2px"
        },
        border: "0.14rem solid",
        borderColor: "secondary.main",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}

export const avatarStyle = () => {
    return {
        width: "60px",
        height: "60px",
        marginLeft: "24px",
        display: "inline-block",
        verticalAlign: "middle"
    };
};

export const userInfoBoxStyle = () => {
    return {
        margin: "auto 0 auto 12px",
        display: "flex",
        alignItems: "center",
    };
}

export const userNameStyles = () => {
    return {
        color: "#000000",
        maxWidth: "236px",
        whiteSpace: 'normal',
        wordWrap: 'normal',
        wordBreak: 'normal',
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "uppercase",
    }
}

export const userEmailStyles = () => {
    return {
        color: "#000000",
        maxWidth: "236px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }
}

export const userActionsBtnWrapper = () => {
    return {
        display: "flex",
        gap: "14px",
    }
}

export const userActionsBtn = () => {
    return {
        padding: "0px !important",
        margin: "0px !important",
        lineHeight: "unset !important",
        height: "unset !important",
        fontSize: "20px !important",
        color: "#145091 !important",
        outline: "none !important",
        minWidth: "unset !important",
        "& .btn": {
            fontSize: "16px",
            fontWeight: "500",
            color: "#194F90",
            textTransform: "capitalize",
            outline: "none",
            opacity: "1",
            padding: "0px",
            boxShadow: "none",
            "&:focus": {
                color: "#194F90",
                outline: "none !important",
                border: "none !important",
                boxShadow: "none !important"
            },
            "&:hover": {
                color: "#194F90",
            }
        },
        "&:hover": {
            outline: "none !important",
            border: "none !important",
            boxShadow: "none !important"
        },
    };
}


export const menuPanelWrapper = (theme, ownerState) => {
    const { showMenuPanel } = ownerState;

    return {
        backgroundColor: "#FFFFFF",
        minWidth: "356px",
        // maxWidth: "550px",
        minHeight: "250px",
        padding: "48px",
        position: "absolute",
        top: "96px",
        boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.2)",
        zIndex: 99,
        display: showMenuPanel ? "block" : "none",
        cursor: "auto"
    };
}

export const menuPanelLinksWrapper = () => {
    return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "24px",
        padding: "0px",
    };
}

export const navigationWrapper = () => {
    return {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "24px",
        cursor: "pointer",
    }
}

export const navigationLinkLable = () => {
    return {
        fontFamily: 'Roboto',
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: " 0.01em",
        color: `secondary.main`,
        width: "max-content",
        padding: "0 8px",
        "&:hover": {
            color: `primary.main`,
        }
    };
}

export const quickActionsBtn = () => {
    return {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "14px",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        width: "180px",
        height: "38px",
        padding: "12px 8px",
        display: "flex",
        justifyContent: "start",
        padding: "0 0 0 18px",
        iconSizeSmall: {
            '& > *:first-child': {
                fontSize: "12px",
            },
        }
    };
}

export const logo = (theme) => {
    return {
        width: '200px',
        height: "auto",
    }
}

export const userNameAvtar = () => {
    return {
        position: "relative",
        "&:hover": {
            background: "none"
        },
        height: "96px",
        minWidth: "68px",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    }
}

export const userNamePopover = () => {
    return {
        ".MuiPopover-paper": {
            top: "96px !important",
            left: "78% !important",
            padding: "24px 16px",
            borderRadius: "0",
            display: "flex",
            flexDirection: "column",
            gap: "14px"
        }
    }
}

export const userNameInfoWrapper = () => {
    return {
        display: "flex",
        gap: "12px"
    }
}

export const userNameBox = () => {
    return {
        display: "flex",
        flexDirection: "column",
        alignItems: "start"
    }
}

export const userInfoWrapper = (theme) => {
    return {
        position: "absolute",
        top: "96px",
        right: "-24px",
        backgroundColor: `${theme.palette.grey[0]}`,
        padding: "24px 16px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "max-content",
        maxWidth: "320px",
        boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.2)",
        zIndex: "999"
    }
}

export const linkBoxStyles = () => {
    return {
        "&:hover": {
            background: "none"
        },
        "&:focus": {
            outline: "none !important",
            border: "none !important",
            boxShadow: "none !important"
        },
        display: "flex !important",
        justifyContent: "start",
        paddingLeft: "0 !important",
    }
}

export const linkStyles = () => {
    return {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "14px",
        color: "#000",
        textTransform: "none"
    }
}

export const iconStyles = () => {
    return {
        marginRight: "5px",
        transform: 'translateY("1px")',
        width: "22px",
        height: "16px",
        fontWeight: "700 !important",
    }
}

export const avatarStyles = (theme) => {
    return {
        backgroundColor: theme.palette.primary.main,
        textTransform: "uppercase",
        cursor: "pointer"
    }
}