import {
    SEARCH_VENDOR_COMPANY_INFORMATION_LOADING,
    SEARCH_VENDOR_COMPANY_INFORMATION_SUCCESS,
    SEARCH_VENDOR_COMPANY_INFORMATION_FAILURE,
    SEARCH_SIMILAR_VENDORS_LIST_LOADING,
    SEARCH_SIMILAR_VENDORS_LIST_SUCCESS,
    SEARCH_SIMILAR_VENDORS_LIST_FAILURE,
    SET_SIMILAR_VENDOR_LIST,
    CONFIRM_VENDOR_COMPANY_INFO_LOADING,
    CONFIRM_VENDOR_COMPANY_INFO_SUCCESS,
    CONFIRM_VENDOR_COMPANY_INFO_FAILURE,
    MERGE_VENDOR_LOADING,
    MERGE_VENDOR_SUCCESS,
    MERGE_VENDOR_FAILURE,
    PUT_VENDOR_COMPANY_INFO_LOADING,
    PUT_VENDOR_COMPANY_INFO_SUCCESS,
    PUT_VENDOR_COMPANY_INFO_FAILURE
} from "actions/verifyVendor";
import { VENDOR_ADDDED_SUCCESSFULLY, VENDOR_MERGED_SUCCESSFULLY } from "pages/VerifyVendor/constants";
import { toast } from "react-toastify";

const initialState = {
    loading: false,
    vendorSearchLoading: false,
    companyProfile: {},
    similarVendorList: [],
};

export default function verifyVendor(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCH_VENDOR_COMPANY_INFORMATION_LOADING: {
            return {
                ...state,
                loading: true,
            }
        }

        case SEARCH_VENDOR_COMPANY_INFORMATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                companyProfile: payload?.Data?.Rows.length > 0 && payload?.Data?.Rows[0]
            }
        }

        case SEARCH_VENDOR_COMPANY_INFORMATION_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }

        case SEARCH_SIMILAR_VENDORS_LIST_LOADING: {
            return {
                ...state,
                vendorSearchLoading: true,
            };
        }

        case SEARCH_SIMILAR_VENDORS_LIST_SUCCESS: {
            return {
                ...state,
                vendorSearchLoading: false,
                similarVendorList: payload?.Data?.Rows,
            };
        }

        case SEARCH_SIMILAR_VENDORS_LIST_FAILURE: {
            return {
                ...state,
                vendorSearchLoading: false,
                similarVendorList: payload,
            };
        }

        case SET_SIMILAR_VENDOR_LIST: {
            return {
                ...state,
                similarVendorList: payload,
            }
        }

        case CONFIRM_VENDOR_COMPANY_INFO_LOADING: {
            return {
                ...state,
                loading: true
            }
        }

        case CONFIRM_VENDOR_COMPANY_INFO_SUCCESS: {
            toast.success(VENDOR_ADDDED_SUCCESSFULLY)
            return {
                ...state,
                loading: false
            }
        }

        case CONFIRM_VENDOR_COMPANY_INFO_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }

        case MERGE_VENDOR_LOADING: {
            return {
                ...state,
                loading: true
            }
        }

        case MERGE_VENDOR_SUCCESS: {
            toast.success(VENDOR_MERGED_SUCCESSFULLY)
            return {
                ...state,
                loading: false
            }
        }

        case MERGE_VENDOR_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }

        case PUT_VENDOR_COMPANY_INFO_LOADING: {
            return {
                ...state,
                loading: true
            }
        }

        case PUT_VENDOR_COMPANY_INFO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }

        case PUT_VENDOR_COMPANY_INFO_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }

        default:
            return state;
    }
}
