import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faCopyright } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import { footerSection1, footerSection2, footerWrapper, moveToTop, copyrightStyles, footerLogo, copyrightSymbolStyle } from "./footerStyles";
import corlLogo from "assets/images/corl-logo-color.svg";

const CommonFooter = () => {

    return (
        <Box sx={(theme) => footerWrapper(theme)}>
            <Box sx={(theme) => footerSection1(theme)}>
                <Box
                    component="img"
                    alt="CORL logo"
                    src={corlLogo}
                    sx={footerLogo}
                />
            </Box>
            <Box sx={(theme) => footerSection2(theme)}>
                <Box sx={(theme) => copyrightStyles(theme)}>
                    <Typography variant="body2"> 
                    Copyright 
                    <FontAwesomeIcon style={copyrightSymbolStyle()} icon={faCopyright}  />
                    CORL Technologies 2022-{new Date().getFullYear()} </Typography>
                </Box>
                <Box sx={(theme) => moveToTop(theme)} onClick={() => {window.scrollTo(0,0)}}>
                    <FontAwesomeIcon icon={faArrowUp} />Top
                </Box>
            </Box>
        </Box>
    )
}

export default CommonFooter;