import axios from "axios";
import { getErrorObject } from "../utils/apiUtils";
import {
  searchCustomerProductsReqBody,
  searchProductGroupReqBody,
  searchProductListReqBody,
  getCustomerSearchBody,
  searchVendorCustomerReqBody
} from "../utils/vendorProfileCustomersUtils";
import { setCustomErrors } from "./customErrorHandler";
import { setErrors } from "./errorHandler";
import { NO_DATA_FOUND } from "constants/errorMessage";

export const SEARCH_VENDOR_CUSTOMER_LIST_LOADING =
  "@@vendorProfileCustomers/SEARCH_VENDOR_CUSTOMER_LIST_LOADING";
export const SEARCH_VENDOR_CUSTOMER_LIST_SUCCESS =
  "@@vendorProfileCustomers/SEARCH_VENDOR_CUSTOMER_LIST_SUCCESS";
export const SEARCH_VENDOR_CUSTOMER_LIST_FAILURE =
  "@@vendorProfileCustomers/SEARCH_VENDOR_CUSTOMER_LIST_FAILURE";

export const searchVendorCustomerList = (id, searchValue, callback) => async (dispatch) => {
  dispatch({
    type: SEARCH_VENDOR_CUSTOMER_LIST_LOADING,
  });

  const body = JSON.stringify(searchVendorCustomerReqBody(id, searchValue));

  try {
    const response = await axios.post("/vendor/customer/search", body);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: SEARCH_VENDOR_CUSTOMER_LIST_FAILURE,
        payload: getErrorObject(),
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_CUSTOMER_LIST_SUCCESS,
        payload: response?.data,
      });
      if (callback) {
        callback(response?.data?.Data?.Rows);
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_CUSTOMER_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_CUSTOMER_TO_LIST_LOADING =
  "@@vendorProfileCustomers/ADD_CUSTOMER_TO_LIST_LOADING";
export const ADD_CUSTOMER_TO_LIST_SUCCESS =
  "@@vendorProfileCustomers/ADD_CUSTOMER_TO_LIST_SUCCESS";
export const ADD_CUSTOMER_TO_LIST_FAILURE =
  "@@vendorProfileCustomers/ADD_CUSTOMER_TO_LIST_FAILURE";

export const addCustomerToList = (reqBody, callback) => async (dispatch) => {
  const body = JSON.stringify(reqBody);

  dispatch({
    type: ADD_CUSTOMER_TO_LIST_LOADING,
  });

  try {
    const response = await axios.post("/vendor/customer", body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CUSTOMER_TO_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ADD_CUSTOMER_TO_LIST_SUCCESS,
        payload: response,
      });
      dispatch(searchVendorCustomerList(reqBody?.VendorOrgId, "", (customerList) => {
        dispatch(setSelectedCustomer({
          ...reqBody,
          CustomerVendorId: response?.data?.Data?.Success?.CustomerVendorId,
          CustomerOrgName: customerList?.find(customer => customer.CustomerOrgId === reqBody?.CustomerOrgId)?.CustomerOrgName
        }))
      }));
      if (callback) {
        callback()
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_CUSTOMER_TO_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_CUSTOMER_FROM_LIST_LOADING =
  "@@vendorProfileCustomers/DELETE_CUSTOMER_FROM_LIST_LOADING";
export const DELETE_CUSTOMER_FROM_LIST_SUCCESS =
  "@@vendorProfileCustomers/DELETE_CUSTOMER_FROM_LIST_SUCCESS";
export const DELETE_CUSTOMER_FROM_LIST_FAILURE =
  "@@vendorProfileCustomers/DELETE_CUSTOMER_FROM_LIST_FAILURE";

export const deleteCustomerFromList =
  (vendorOrgId, customerId, callback) => async (dispatch) => {
    dispatch({
      type: DELETE_CUSTOMER_FROM_LIST_LOADING,
    });

    try {
      const response = await axios.delete(`/vendor/customer/${customerId}`);
      if (response?.data?.ErrorCount > 0) {
        dispatch(setErrors(response?.data?.Errors));
        dispatch({
          type: DELETE_CUSTOMER_FROM_LIST_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: DELETE_CUSTOMER_FROM_LIST_SUCCESS
        });
        dispatch(searchVendorCustomerList(vendorOrgId, ""));
        dispatch(setSelectedCustomer({}));
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: DELETE_CUSTOMER_FROM_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_PRODUCT_GROUP_LIST_LOADING =
  "@@vendorProfileCustomers/SEARCH_PRODUCT_GROUP_LIST_LOADING";
export const SEARCH_PRODUCT_GROUP_LIST_SUCCESS =
  "@@vendorProfileCustomers/SEARCH_PRODUCT_GROUP_LIST_SUCCESS";
export const SEARCH_PRODUCT_GROUP_LIST_FAILURE =
  "@@vendorProfileCustomers/SEARCH_PRODUCT_GROUP_LIST_FAILURE";

export const searchProductGroupList = (id) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_GROUP_LIST_LOADING });
  let body = JSON.stringify(searchProductGroupReqBody(id));
  try {
    const response = await axios.post("vendor/productgroup/search", body);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: SEARCH_PRODUCT_GROUP_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_GROUP_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCT_LIST_LOADING =
  "@@vendorProfileCustomers/SEARCH_PRODUCT_LIST_LOADING";
export const SEARCH_PRODUCT_LIST_SUCCESS =
  "@@vendorProfileCustomers/SEARCH_PRODUCT_LIST_SUCCESS";
export const SEARCH_PRODUCT_LIST_FAILURE =
  "@@vendorProfileCustomers/SEARCH_PRODUCT_LIST_FAILURE";

export const searchProductList = (vendorOrgId, productGroupId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_LIST_LOADING });
  let body = JSON.stringify(searchProductListReqBody(vendorOrgId, productGroupId));
  try {
    const response = await axios.post("vendor/product/search", body);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: SEARCH_PRODUCT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_CUSTOMER_PRODUCTS_LOADING =
  "@@vendorProfileCustomers/SEARCH_CUSTOMER_PRODUCTS_LOADING";
export const SEARCH_CUSTOMER_PRODUCTS_SUCCESS =
  "@@vendorProfileCustomers/SEARCH_CUSTOMER_PRODUCTS_SUCCESS";
export const SEARCH_CUSTOMER_PRODUCTS_FAILURE =
  "@@vendorProfileCustomers/SEARCH_CUSTOMER_PRODUCTS_FAILURE";

export const searchCustomerProducts = (vendorOrgId, customerOrgId, customerVendorId) => async (dispatch) => {

  const body = JSON.stringify(searchCustomerProductsReqBody(vendorOrgId, customerVendorId));

  dispatch({
    type: SEARCH_CUSTOMER_PRODUCTS_LOADING,
  });

  try {
    const response = await axios.post(
      `vendor/customer/${customerOrgId}/product/search`,
      body
    );
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: SEARCH_CUSTOMER_PRODUCTS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_CUSTOMER_PRODUCTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CUSTOMER_PRODUCTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_PRODUCT_TO_CUSTOMER_LOADING =
  "@@vendorProfileCustomers/ADD_PRODUCT_TO_CUSTOMER_LOADING";
export const ADD_PRODUCT_TO_CUSTOMER_SUCCESS =
  "@@vendorProfileCustomers/ADD_PRODUCT_TO_CUSTOMER_SUCCESS";
export const ADD_PRODUCT_TO_CUSTOMER_FAILURE =
  "@@vendorProfileCustomers/ADD_PRODUCT_TO_CUSTOMER_FAILURE";

export const addProductToCustomer =
  (customerId, productId, callback) => async (dispatch) => {
    let reqBody = {
      CustomerVendorId: customerId,
      ProductVendorId: productId,
    };

    const body = JSON.stringify(reqBody);

    dispatch({
      type: ADD_PRODUCT_TO_CUSTOMER_LOADING,
    });

    try {
      const response = await axios.post(
        `vendor/customer/${customerId}/product`,
        body
      );
      if (response?.data?.ErrorCount > 0) {
        dispatch(setErrors(response?.data?.Errors));
        dispatch({
          type: ADD_PRODUCT_TO_CUSTOMER_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: ADD_PRODUCT_TO_CUSTOMER_SUCCESS,
          payload: {
            response: response?.data,
          }
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: ADD_PRODUCT_TO_CUSTOMER_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const DELETE_PRODUCT_FROM_CUSTOMER_LOADING =
  "@@vendorProfileCustomers/DELETE_PRODUCT_FROM_CUSTOMER_LOADING";
export const DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS =
  "@@vendorProfileCustomers/DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS";
export const DELETE_PRODUCT_FROM_CUSTOMER_FAILURE =
  "@@vendorProfileCustomers/DELETE_PRODUCT_FROM_CUSTOMER_FAILURE";

export const removeProductFromCustomer =
  (productId, customerId, forcedState, callback) => async (dispatch) => {
    dispatch({
      type: DELETE_PRODUCT_FROM_CUSTOMER_LOADING,
    });

    let url = `vendor/customer/${customerId}/product/${productId}`;
    if (forcedState) {
      url = `${url}?forceDelete=true`
    }

    try {
      const response = await axios.delete(url);
      if (response?.data?.ErrorCount > 0) {
        dispatch(setCustomErrors(response?.data?.Errors));
        dispatch({
          type: DELETE_PRODUCT_FROM_CUSTOMER_FAILURE,
          payload: {}
        });
      } else {
        dispatch({
          type: DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS,
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: DELETE_PRODUCT_FROM_CUSTOMER_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };


export const SEARCH_CUSTOMER_LIST_LOADING =
  "@@vendorProfileCustomers/SEARCH_CUSTOMER_LIST_LOADING";
export const SEARCH_CUSTOMER_LIST_SUCCESS =
  "@@vendorProfileCustomers/SEARCH_CUSTOMER_LIST_SUCCESS";
export const SEARCH_CUSTOMER_LIST_FAILURE =
  "@@vendorProfileCustomers/SEARCH_CUSTOMER_LIST_FAILURE";

export const getCustomerList = (searchText = "") => async (dispatch) => {
  dispatch({ type: SEARCH_CUSTOMER_LIST_LOADING });

  const body = getCustomerSearchBody(searchText);

  try {
    const res = await axios.post(`/customer/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_CUSTOMER_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_CUSTOMER_LIST_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CUSTOMER_LIST_FAILURE,
      payload: getErrorObject(err)
    })
  }
}

export const SET_SELECTED_CUSTOMER =
  "@@vendorProfileCustomers/SET_SELECTED_CUSTOMER";

export const setSelectedCustomer = (data) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_CUSTOMER,
    payload: data
  })
}