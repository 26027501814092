export const SET_ERRORS = '@errorhandler/SET_ERRORS';
export const setErrors = (list) => async dispatch => {
    dispatch({
        type: SET_ERRORS,
        payload: list
    })
}


export const CLEAR_ERRORS = '@errorhandler/CLEAR_ERRORS';
export const clearErrors = () => async dispatch => {
    dispatch({
        type: CLEAR_ERRORS
    })
}