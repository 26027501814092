import axios from "axios";
import { getErrorObject } from "utils/apiUtils";
import { getConfigValuesBody } from "utils/configUtils";

export const GET_CONFIG_VALUE_REQUEST = '@config/GET_CONFIG_VALUE_REQUEST';
export const GET_CONFIG_VALUE_SUCCESS = '@config/GET_CONFIG_VALUE_SUCCESS';
export const GET_CONFIG_VALUE_FAILURE = '@config/GET_CONFIG_VALUE_FAILURE';

export const getConfigValue = (keys) => async (dispatch) => {
  dispatch({ type: GET_CONFIG_VALUE_REQUEST });
  try {
    const body = getConfigValuesBody(keys);
    const response = await axios.post(`/app/config/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_CONFIG_VALUE_FAILURE,
        payload: []
      });
    } else {
      dispatch({
        type: GET_CONFIG_VALUE_SUCCESS,
        payload: response?.data?.Data
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CONFIG_VALUE_FAILURE,
      payload: getErrorObject(err)
    });
  }
};