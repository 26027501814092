export const generalInformationTitle = (theme) => {
  return {
    fontFamily: `${theme.typography.fontFamily}`,
  };
};

export const spaceTop = (ownerState) => {
  return {
    marginTop: `${ownerState}px`,
  };
};

export const userNameWrapper = () => {
  return {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  };
};

export const userNamespacing = () => {
  return {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "auto auto auto",
    columnGap: "20px",
  };
};

export const userForm = () => {
  return {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  };
};

export const usersCancelButtonStyle = () => {
  return {
    marginRight: "8px",
  };
};

export const questionstree = () => {
  return {
    width: "100%",
    marginTop: "20px",
    marginRight: "55px",
    alignItems: "center",
    justifyContent: "center",
    display: "inline-block",
  };
};

export const vendorContent = () => {
  return {
    width: "90%",
    display: "flex",
    alignItems: "center",
    overflow:'hidden', 
    textOverflow: "ellipsis", 
    marginRight: "8px",
    gap: '4px'
  };
};

export const textwrap = () => {
  return {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };
};

export const iconbuttonStyles = (ownerState) => {
  return {
    padding: "8px 10px",
    height: 27,
    width: 30,
    borderRadius: "5px",
    minWidth: "unset",
    marginRight: `${ownerState}px`,
  };
};

export const listScrollBarHeight = () => {
  return {
    height: 270,
  };
};

export const menuListIcon = () => {
  return {
    height: 24,
    width: 20,
    marginRight: "3px",
  };
};

export const buttonRightMargin = () => {
  return {
    marginRight: "10px",
  };
};

export const buttonStyle = () => {
  return {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    paddingTop: "10px",
  };
};

export const modalStyle = () => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 12,
    p: 4,
    overflow: "auto",
  };
};

export const usersPagePadding = () => {
  return {
    padding: "40px 80px 40px 80px",
  };
}

export const userRolesContainer = () => {
  return {
    marginTop : "24px"
  }
}

export const userRoleTitle = () => {
  return {
    fontSize: "14px"
  }
}

export const userRoleAutoCompleteFieldStyle = () => {
  return {
      margin: '0px 0px 12px 0px'
  }
}

export const userRoleAutoCompleteStyle = () => {
  return {
    marginTop: "16px",
    width: "90%"
  }
}

export const userRoleAutocompleteContainer = () => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "16px",
    height: "76px",
  }
}

export const selectUserRoleBox = () => {
  return {
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
  }
}

export const addUserRole = () => {
  return {
    marginLeft: "auto"
  }
}

export const removeIconbuttonStyles = (ownerState) => {
  return {
    padding: "8px 10px",
    height: 27,
    width: 30,
    borderRadius: "5px",
    minWidth: "unset",
    marginRight: `${ownerState}px`,
  };
};

export const resendCloseStyle = () => {
  return {
    float: "right",
    cursor: "pointer",
  };
};