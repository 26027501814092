import { isArray, isEmpty, isString } from "lodash";

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: "startswith",
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: "in",
        Value: searchData[key].join(","),
      });
    }
  });
  return criterion;
};

export const getEvidenceDetailsBody = (searchData, vendorOrgId = 0) => {
  let criterion = [
    {
      Field: "VendorOrgId",
      Operator: "=",
      Value: vendorOrgId,
    },
  ];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: "",
    fields: [
      "VendorOrgId",
      "VendorOrgName",
      "DocumentName",
      "EvidenceType",
      "SubType",
      "FileType",
      "VendorFileUploadLogId",
      "VendorComments",
      "InsertTimestamp",
    ],
    criterion: criterion,
    sort: [
      {
        "field": "DocumentName",
        "order": "ASC",
      }
    ],
    page: 0,
    pageSize: 0,
  };
};
