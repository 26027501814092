export const USER_CLAIMS = {
    ASSESSMENT_BOARD_READ:'ASSESSMENT_BOARD_READ',
    ASSESSMENT_READ:'ASSESSMENT_READ',
    ASSESSMENT_READ:'ASSESSMENT_READ',
    ASSESSMENT_REQUEST_READ:'ASSESSMENT_REQUEST_READ',
    ASSESSMENT_SUBMIT:'ASSESSMENT_SUBMIT',
    ASSESSMENT_UPDATE:'ASSESSMENT_UPDATE',
    CLIENT_CREATE:'CLIENT_CREATE',
    CLIENT_DELETE:'CLIENT_DELETE',
    CLIENT_IMPORT:'CLIENT_IMPORT',
    CLIENT_READ:'CLIENT_READ',
    CLIENT_SEARCH:'CLIENT_SEARCH',
    CLIENT_UPDATE:'CLIENT_UPDATE',
    CLIENT_USER_CREATE:'CLIENT_USER_CREATE',
    CLIENT_USER_CREATE:'CLIENT_USER_CREATE',
    CLIENT_USER_DELETE:'CLIENT_USER_DELETE',
    CLIENT_USER_DELETE:'CLIENT_USER_DELETE',
    CLIENT_USER_IMPORT:'CLIENT_USER_IMPORT',
    CLIENT_USER_IMPORT:'CLIENT_USER_IMPORT',
    CLIENT_USER_READ:'CLIENT_USER_READ',
    CLIENT_USER_READ:'CLIENT_USER_READ',
    CLIENT_USER_SEARCH:'CLIENT_USER_SEARCH',
    CLIENT_USER_SEARCH:'CLIENT_USER_SEARCH',
    CLIENT_USER_UPDATE:'CLIENT_USER_UPDATE',
    CLIENT_USER_UPDATE:'CLIENT_USER_UPDATE',
    CORL_ASSESSMENTS_READ:'CORL_ASSESSMENTS_READ',
    CORL_DASHBOARD_READ:'CORL_DASHBOARD_READ',
    CORL_USERS_DELETE:'CORL_USERS_DELETE',
    CORL_USERS_IMPORT:'CORL_USERS_IMPORT',
    CORL_USERS_READ:'CORL_USERS_READ',
    CORL_USERS_SEARCH:'CORL_USERS_SEARCH',
    CORL_USERS_UPDATE:'CORL_USERS_UPDATE',
    CORL_USER_CREATE:'CORL_USER_CREATE',
    DASHBOARD_READ:'DASHBOARD_READ',
    DASHBOARD_READ:'DASHBOARD_READ',
    INCIDENT_ANALYTICS_READ:'INCIDENT_ANALYTICS_READ',
    INCIDENT_CREATE:'INCIDENT_CREATE',
    INCIDENT_DELETE:'INCIDENT_DELETE',
    INCIDENT_READ:'INCIDENT_READ',
    INCIDENT_READ:'INCIDENT_READ',
    INCIDENT_REPORTS_READ:'INCIDENT_REPORTS_READ',
    INCIDENT_REPORT_CREATE:'INCIDENT_REPORT_CREATE',
    INCIDENT_REPORT_SEARCH:'INCIDENT_REPORT_SEARCH',
    INCIDENT_RESPONSE_SUBMIT:'INCIDENT_RESPONSE_SUBMIT',
    INCIDENT_RESPONSE_UPDATE:'INCIDENT_RESPONSE_UPDATE',
    INCIDENT_SEARCH:'INCIDENT_SEARCH',
    INCIDENT_SEARCH:'INCIDENT_SEARCH',
    INCIDENT_UPDATE:'INCIDENT_UPDATE',
    OUTREACH_CREATE:'OUTREACH_CREATE',
    OUTREACH_DELETE:'OUTREACH_DELETE',
    OUTREACH_READ:'OUTREACH_READ',
    OUTREACH_SEARCH:'OUTREACH_SEARCH',
    OUTREACH_UPDATE:'OUTREACH_UPDATE',
    PROFILE_READ:'PROFILE_READ',
    PROFILE_READ:'PROFILE_READ',
    PROFILE_UPDATE:'PROFILE_UPDATE',
    PROFILE_UPDATE:'PROFILE_UPDATE',
    QUESTIONNAIRE_TEMPLATE_ADD_QUESTION:'QUESTIONNAIRE_TEMPLATE_ADD_QUESTION',
    QUESTIONNAIRE_TEMPLATE_CHANGE_STATUS:'QUESTIONNAIRE_TEMPLATE_CHANGE_STATUS',
    QUESTIONNAIRE_TEMPLATE_CREATE:'QUESTIONNAIRE_TEMPLATE_CREATE',
    QUESTIONNAIRE_TEMPLATE_DELETE_QUESTION:'QUESTIONNAIRE_TEMPLATE_DELETE_QUESTION',
    QUESTIONNAIRE_TEMPLATE_READ:'QUESTIONNAIRE_TEMPLATE_READ',
    QUESTIONNAIRE_TEMPLATE_READ_QUESTION:'QUESTIONNAIRE_TEMPLATE_READ_QUESTION',
    QUESTIONNAIRE_TEMPLATE_SEARCH:'QUESTIONNAIRE_TEMPLATE_SEARCH',
    QUESTIONNAIRE_TEMPLATE_UPDATE:'QUESTIONNAIRE_TEMPLATE_UPDATE',
    QUESTION_CREATE:'QUESTION_CREATE',
    QUESTION_DELETE:'QUESTION_DELETE',
    QUESTION_LOGIC_RULE_ADD:'QUESTION_LOGIC_RULE_ADD',
    QUESTION_LOGIC_RULE_DELETE:'QUESTION_LOGIC_RULE_DELETE',
    QUESTION_LOGIC_RULE_READ:'QUESTION_LOGIC_RULE_READ',
    QUESTION_LOGIC_RULE_UPDATE:'QUESTION_LOGIC_RULE_UPDATE',
    QUESTION_LOGIC_RULE_UPDATE:'QUESTION_LOGIC_RULE_UPDATE',
    QUESTION_METADATA_ADD:'QUESTION_METADATA_ADD',
    QUESTION_METADATA_DELETE:'QUESTION_METADATA_DELETE',
    QUESTION_METADATA_READ:'QUESTION_METADATA_READ',
    QUESTION_METADATA_UPDATE:'QUESTION_METADATA_UPDATE',
    QUESTION_METADATA_UPDATE:'QUESTION_METADATA_UPDATE',
    QUESTION_READ:'QUESTION_READ',
    QUESTION_RESPONSE_OPTION_ADD:'QUESTION_RESPONSE_OPTION_ADD',
    QUESTION_RESPONSE_OPTION_DELETE:'QUESTION_RESPONSE_OPTION_DELETE',
    QUESTION_RESPONSE_OPTION_READ:'QUESTION_RESPONSE_OPTION_READ',
    QUESTION_RESPONSE_OPTION_UPDATE:'QUESTION_RESPONSE_OPTION_UPDATE',
    QUESTION_RESPONSE_OPTION_UPDATE:'QUESTION_RESPONSE_OPTION_UPDATE',
    QUESTION_SEARCH:'QUESTION_SEARCH',
    QUESTION_UPDATE:'QUESTION_UPDATE',
    ROLES_CREATE:'ROLES_CREATE',
    ROLES_DELETE:'ROLES_DELETE',
    ROLES_IMPORT:'ROLES_IMPORT',
    ROLES_READ:'ROLES_READ',
    ROLES_SEARCH:'ROLES_SEARCH',
    ROLES_UPDATE:'ROLES_UPDATE',
    VENDORS_CREATE:'VENDORS_CREATE',
    VENDORS_DELETE:'VENDORS_DELETE',
    VENDORS_LIST_READ:'VENDORS_LIST_READ',
    VENDORS_LIST_SEARCH:'VENDORS_LIST_SEARCH',
    VENDORS_READ:'VENDORS_READ',
    VENDORS_SEARCH:'VENDORS_SEARCH',
    VENDORS_UPDATE:'VENDORS_UPDATE',
    VENDOR_CREATE:'VENDOR_CREATE',
    VENDOR_DELETE:'VENDOR_DELETE',
    VENDOR_IMPORT:'VENDOR_IMPORT',
    VENDOR_READ:'VENDOR_READ',
    VENDOR_SEARCH:'VENDOR_SEARCH',
    VENDOR_UPDATE:'VENDOR_UPDATE',
    VENDOR_UPLOAD:'VENDOR_UPLOAD',
    VENDOR_USER_CREATE:'VENDOR_USER_CREATE',
    VENDOR_USER_CREATE:'VENDOR_USER_CREATE',
    VENDOR_USER_DELETE:'VENDOR_USER_DELETE',
    VENDOR_USER_DELETE:'VENDOR_USER_DELETE',
    VENDOR_USER_IMPORT:'VENDOR_USER_IMPORT',
    VENDOR_USER_IMPORT:'VENDOR_USER_IMPORT',
    VENDOR_USER_READ:'VENDOR_USER_READ',
    VENDOR_USER_READ:'VENDOR_USER_READ',
    VENDOR_USER_SEARCH:'VENDOR_USER_SEARCH',
    VENDOR_USER_SEARCH:'VENDOR_USER_SEARCH',
    VENDOR_USER_UPDATE:'VENDOR_USER_UPDATE',
    VENDOR_USER_UPDATE:'VENDOR_USER_UPDATE',
    CLIENT_CONTRACT_CREATE:'CLIENT_CONTRACT_CREATE',
    CLIENT_CONTRACT_SEARCH:'CLIENT_CONTRACT_SEARCH',
    CLIENT_CONTRACT_READ:'CLIENT_CONTRACT_READ',
    CLIENT_CONTRACT_UPDATE:'CLIENT_CONTRACT_UPDATE',
    CLIENT_CONTRACT_DELETE:'CLIENT_CONTRACT_DELETE',
    CLIENT_CONTRACT_IMPORT:'CLIENT_CONTRACT_IMPORT'
}

export const ROUTE_CLAIMS_LIST = [
    {
        route: '/users',
        claims: [USER_CLAIMS.CORL_USERS_READ]
    }
]