import {
    Avatar,
    Box,
    Button,
    Typography,
    Tooltip,
} from "@mui/material";
import LogoutButton from "../LogOut/LogoutButton";
import { useSelector } from "react-redux";
import {
    userNameStyles,
    userActionsBtnWrapper,
    userActionsBtn,
    userInfoBoxStyle,
    userEmailStyles,
    userNameInfoWrapper,
    userNameBox,
    userNameAvtar,
    linkStyles,
    linkBoxStyles,
    iconStyles,
    userInfoWrapper,
    avatarStyles
} from "./NavBarStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";



const UserInfo = () => {
    const details = useSelector(state => state.session.details);
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [displayUserName, setDisplayUserName] = useState("");

    useEffect(() => {
        let userName = "";
        const { FirstName, LastName, UserEmail } = details;

        if (FirstName || LastName) {
            if (FirstName) {
                userName += FirstName.trim()[0];
            }
            if (LastName) {
                userName += LastName.trim()[0];
            }
        } else if (UserEmail) {
            userName += UserEmail.trim()[0];
        }

        setDisplayUserName(userName);

    }, [details?.FirstName, details?.LastName, details?.UserEmail])

    const userNameHover = () => {
        setShowUserDetails(true)
    }

    const handleUserNameClose = () => {
        setShowUserDetails(false);
    };

    return (
        <Box sx={userInfoBoxStyle}>
            <Box
                sx={userNameAvtar}
                onMouseEnter={userNameHover}
                onMouseLeave={handleUserNameClose}
            >
                <Avatar sx={(theme) => avatarStyles(theme)}>{displayUserName}</Avatar>
                {showUserDetails && <Box sx={theme => userInfoWrapper(theme)}>
                    <Box sx={userNameInfoWrapper}>
                        <Avatar sx={(theme) => avatarStyles(theme)}>{displayUserName}</Avatar>
                        <Box sx={userNameBox}>
                            <Typography variant="h3" sx={userNameStyles}>{details?.DisplayName}</Typography>
                            <Tooltip title={details?.UserEmail}>
                                <Typography variant="body2" sx={userEmailStyles}>{details?.UserEmail}</Typography>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={userActionsBtnWrapper}>
                        <Box sx={userActionsBtn}>
                            <LogoutButton handleUserNameClose={handleUserNameClose} />
                        </Box>
                    </Box>
                </Box>}
            </Box>
        </Box>
    );
}

export default UserInfo;