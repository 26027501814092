import {
        SEARCH_CLIENT_USER_ROLE_LIST_FAILURE,
        SEARCH_CLIENT_USER_ROLE_LIST_LOADING,
        SEARCH_CLIENT_USER_ROLE_LIST_SUCCESS,
        SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_FAILURE,
        SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_LOADING,
        SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_SUCCESS,
        POST_USER_ROLE_TO_CLIENT_USER_FAILURE,
        POST_USER_ROLE_TO_CLIENT_USER_LOADING,
        POST_USER_ROLE_TO_CLIENT_USER_SUCCESS,
        SEARCH_VENDOR_USER_ROLE_LIST_FAILURE,
        SEARCH_VENDOR_USER_ROLE_LIST_LOADING,
        SEARCH_VENDOR_USER_ROLE_LIST_SUCCESS,
        SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_FAILURE,
        SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_LOADING,
        SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_SUCCESS,
        POST_USER_ROLE_TO_VENDOR_USER_FAILURE,
        POST_USER_ROLE_TO_VENDOR_USER_LOADING,
        POST_USER_ROLE_TO_VENDOR_USER_SUCCESS
        } from "actions/userRoles";        
import { ERROR_MESSAGE, USER_ROLE_ADDED_SUCCESSFULLY, USER_ROLE_REMOVED_SUCCESSFULLY } from "components/Common/AlertMessages";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  userRoleListOfClientUserLoading: false,
  clientUserRoleList: [],
  userRoleListOfClientUser : [],
  userRoleListOfVendorUserLoading: false,
  vendorUserRoleList: [],
  userRoleListOfVendorUser : []
  };

export default function userRoles(state = initialState, action) {
    const { type, payload } = action;
    switch(type) {

        case SEARCH_CLIENT_USER_ROLE_LIST_LOADING: 
        case POST_USER_ROLE_TO_CLIENT_USER_LOADING: 
        case SEARCH_VENDOR_USER_ROLE_LIST_LOADING: 
        case POST_USER_ROLE_TO_VENDOR_USER_LOADING: {
            return {
                ...state,
                loading: false,
            }
        }

       case SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_LOADING: {
            return {
                ...state,
                userRoleListOfClientUserLoading: false,
            }
       }

       case SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_LOADING: {
            return {
                ...state,
                userRoleListOfVendorUserLoading: false
            }
       }

       case SEARCH_CLIENT_USER_ROLE_LIST_FAILURE: {
            toast.error(ERROR_MESSAGE)
            return {
                ...state,
                loading: false
            }
       }

       case SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_FAILURE: {
            return {
                ...state,
                userRoleListOfClientUserLoading: false,
                userRoleListOfClientUser: []
            }
       }

       case POST_USER_ROLE_TO_CLIENT_USER_FAILURE: {
            return {
                ...state,
                loading: false
            }
       }

       case SEARCH_VENDOR_USER_ROLE_LIST_FAILURE: {
            toast.error(ERROR_MESSAGE)
            return {
                ...state,
                loading: false
            }
        }

        case SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_FAILURE: {
                return {
                    ...state,
                    userRoleListOfVendorUserLoading: false,
                    userRoleListOfVendorUser: []
                }
        }

        case POST_USER_ROLE_TO_VENDOR_USER_FAILURE: {
                return {
                    ...state,
                    loading: false
                }
        }

       case SEARCH_CLIENT_USER_ROLE_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                clientUserRoleList: payload?.Data?.Rows
            }
       }

       case SEARCH_USER_ROLE_LIST_OF_CLIENT_USER_SUCCESS: {
            return {
                ...state,
                userRoleListOfClientUserLoading: false,
                userRoleListOfClientUser: payload?.Data?.Rows
            }
       }

       case POST_USER_ROLE_TO_CLIENT_USER_SUCCESS: {
            toast.success(USER_ROLE_ADDED_SUCCESSFULLY)
            return {
                ...state,
                loading: false
            }
       }

       case SEARCH_VENDOR_USER_ROLE_LIST_SUCCESS: {
        return {
            ...state,
            loading: false,
            vendorUserRoleList: payload?.Data?.Rows
            }
        }

        case SEARCH_USER_ROLE_LIST_OF_VENDOR_USER_SUCCESS: {
            return {
                    ...state,
                    userRoleListOfUserLoading: false,
                    userRoleListOfVendorUser: payload?.Data?.Rows
            }
        }

        case POST_USER_ROLE_TO_VENDOR_USER_SUCCESS: {
            toast.success(USER_ROLE_ADDED_SUCCESSFULLY)
            return {
                    ...state,
                    loading: false
            }
        }

       default:
            return state;

    }
}