import { isEmpty } from "lodash";

export const VALIDATIONS = {
  yearValidation: {
    key: "yearValidate",
    value: 4,
    noOfDigitError: "Year should have 4 digits",
    maxYearError: "Year Founded cannot be a future date!",
  },
  zipValidation: {
    key: "zipValidate",
    value: 5,
    error: "Zip should have 5 digits",
  },
  domainValidation: {
    key: "domainValidate",
    value:
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    error: "Domain Name should be valid",
  },
  emailValidation: {
    key: "emailValidate",
    value: /^[A-Za-z0-9'._%+-]{1,64}@[A-Za-z0-9.-]+\.[a-z]{2,255}$/i,
    error: "Invalid email address",
  },
  emailDomainValidation: {
    key: "emailDomainValidate",
    value: /^[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    error: "Invalid Domain Name",
  },
  onlyAlphabetValidation: {
    key: "onlyAlphabetValidate",
    value: /[^a-zA-Z ]/i,
    error:
      "Only alphabets are allowed. Please avoid numbers and special characters",
  },
  titleValidation: {
    key: "titleValidate",
    value: /^[a-zA-Z&\-]+$/i,
    error: "Please verify the title",
  },
  nameValidation: {
    key: "nameValidate",
    value:
      /[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]/i,
    error: "Please verify the name",
  },
  sharePointURLValidation: {
    key: "sharePointURLValidate",
    value: "https://corltech.sharepoint.com/sites/",
    error:
      "Please enter URL with https://corltech.sharepoint.com/sites/{ClientName} format",
  },
  leadingSpaceValidation: {
    key: "leadingSpaceValidation",
    value: /^\s/g,
    error: "",
  },
  laneText: {
    key: "LaneAndDynamicsText",
    error: "Length must be less than or equal to 32 characters",
  },
  clientDynamicId: {
    key: "ClientDynamicId",
    error: "Length must be less than or equal to 48 characters",
  },
  deleteConfirmationValidation: {
    key: "deleteConfirmationValidation",
    value: "Delete",
    error: "Entered text does not match with the expected text. Please retry.",
  },
  alphaNumericNameValidation: {
    key: "alphaNumericNameValidate",
    value: /[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9 ,.'-]/i, 
    error: "Please verify the name"
  },
  noSubDomainValidation: {
    key: "noSubDomainValidate",
    value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,})?(\/.*)?$/,
    error: "Please enter a valid URL"
  },
};

export const restrictSpaceAtStart = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(VALIDATIONS.leadingSpaceValidation.value, "");
};

export const nameValidation = (value) =>
  !isEmpty(value) &&
  (!VALIDATIONS.nameValidation.value.test(value)
    ? undefined
    : VALIDATIONS.nameValidation.error);

export const email = (value) =>
  value && !VALIDATIONS.emailValidation.value.test(value)
    ? VALIDATIONS.emailValidation.error
    : undefined;

export const alphaNumericNameValidation = (value) =>
  !isEmpty(value) && (!VALIDATIONS.alphaNumericNameValidation.value.test(value)
    ? undefined
    : VALIDATIONS.alphaNumericNameValidation.error);