export default function Divider(theme) {
    return {
        MuiDivider: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          }
        },
      }
    };
  }
  