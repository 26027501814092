import React, { Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import { Loading } from "./components";

const IncidentList = lazy(() =>
  import(/* webpackChunkName: "Incident" */ "./pages/Incident/IncidentList")
);

const IncidentCampaign = lazy(() =>
  import(
    /* webpackChunkName: "IncidentCampaign" */ "./pages/IncidentCampaign/IncidentCampaign"
  )
);

const QuestionnaireList = lazy(() =>
  import("./pages/QuestionnaireBuilder/QuestionnaireList")
);

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ "./pages/Dashboard/Dashboard")
);

const Assessment = lazy(() =>
  import(/* webpackChunkName: "Assessment" */ "./pages/Assessment/Assessment")
);

const Profile = lazy(() =>
  import(/* webpackChunkName: "profile" */ "./views/profile")
);

const ExternalApi = lazy(() =>
  import(/* webpackChunkName: "externalApi" */ "./views/external-api")
);

const Session = lazy(() =>
  import(/* webpackChunkName: "Session" */ "./pages/Session/Session")
);

const Logout = lazy(() =>
  import(/* webpackChunkName: "Logout" */ "./pages/Logout/Logout")
);

const ViewQuestionnaire = lazy(() =>
  import(/* webpackChunkName: "Incident" */ "./pages/QuestionnaireBuilder/ViewQuestionnaire")
);

const QuestionnaireBuilder = lazy(() =>
  import(/* webpackChunkName: "QuestionnaireBuilder" */ "./pages/QuestionnaireBuilder/QuestionnaireBuilder")
);

const OutreachList = lazy(() =>
  import(/* webpackChunkName: "Outreach" */ "./pages/Outreach/OutreachList")
);

const OutreachBuilder = lazy(() =>
  import(/* webpackChunkName: "QuestionnaireBuilder" */ "./pages/Outreach/Outreach")
);
const IncidentBuilder = lazy(() =>
  import(/* webpackChunkName: "IncidentBuilder" */ "./pages/IncidentBuilder/IncidentBuilder")
);

const Vendors = lazy(() =>
  import(/* webpackChunkName: "Vendor" */ "./pages/Vendors/Vendors")
);
const VendorProfile = lazy(() =>
  import(/* webpackChunkName: "VendorProfile" */ "./pages/VendorProfile")
);

const Clients = lazy(() =>
  import(/* webpackChunkName: "Client" */ "./pages/Clients")
);

const ClientProfile = lazy(() =>
  import(/* webpackChunkName: "ClientProfile" */ "./pages/ClientProfile")
);

const CorlUsers = lazy(() =>
  import(/* webpackChunkName: "Users" */ "./pages/Users")
);

const Unauthorized = lazy(() =>
  import(/* webpackChunkName: "Logout" */ "./pages/Unauthorized/Unauthorized")
);
const Error = lazy(() =>
  import(/* webpackChunkName: "Error" */ "./pages/Error/Error")
);

const ClientsCompanyInfo = lazy(() =>
  import(/* webpackChunkName: "AddClientCompanyInformation" */ "./pages/ClientProfile/CompanyInformation/AddClientInformation")
);

const VendorCompanyInfo = lazy(() =>
  import(/* webpackChunkName: "AddVendorCompanyInformation" */ "./pages/VendorProfile/CompanyInformation/AddVendorInformation")
);

const AllUsers = lazy(() =>
  import(/* webpackChunkName: "AllUsers" */ "./pages/AllUsers")
);
const AssessmentRequestReview = lazy(() =>
  import(/* webpackChunkName: "AssessmentRequestReview" */ "./pages/AssessmentRequest/AssessmentRequestReview")
);

const AssessmentRequest = lazy(() =>
  import(/* webpackChunkName: "AssessmentRequest" */ "./pages/AssessmentRequest/AssessmentRequestList/AssessmentRequestList")
);

const VerifyVendor = lazy(() =>
  import(/*webpackChunkName: "VerifyVendor"*/ "./pages/VerifyVendor/VerifyVendor")
)

const ProductDetails = lazy(() =>
  import(/*webpackChunkName: "VerifyVendor"*/ "./pages/VendorProfile/Products/ProductDetails/ProductDetails")
)

const ReplaceVendor = lazy(() =>
  import(/*webpackChunkName: "ReplaceVendor"*/ "./pages/ReplaceVendor/ReplaceVendor")
)

export default function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <ProtectedRoute path="/" exact component={Clients} />
      <ProtectedRoute path="/assessment" exact component={Assessment} />
      <ProtectedRoute path="/incident-list" exact component={IncidentList} />
      <ProtectedRoute path="/incident-campaign" exact component={IncidentCampaign} />
      <ProtectedRoute path="/incident-builder" exact component={IncidentBuilder} />
      <ProtectedRoute path="/incident-builder/:id" exact component={IncidentBuilder} />
      <ProtectedRoute path="/questionnaire-list" exact component={QuestionnaireList} />
      <ProtectedRoute path="/profile" component={Profile} />
      <ProtectedRoute path="/external-api" component={ExternalApi} />
      <ProtectedRoute path="/review-questions" component={ViewQuestionnaire} />
      <ProtectedRoute exact path="/questionnaire-template/:id" component={QuestionnaireBuilder} />
      <ProtectedRoute exact path="/questionnaire-template" component={QuestionnaireBuilder} />
      <ProtectedRoute exact path="/outreach/:id" component={OutreachBuilder} />
      <ProtectedRoute exact path="/outreach" component={OutreachBuilder} />
      <ProtectedRoute path="/outreach-list" component={OutreachList} />
      <ProtectedRoute exact path="/clients" component={Clients} />
      <ProtectedRoute exact path="/client-profile/:id" component={ClientProfile} />
      <Route path="/session" component={Session} />
      <Route path="/logout" component={Logout} />
      <ProtectedRoute path="/vendor" exact component={Vendors} />
      <ProtectedRoute path="/vendor-profile/:id" exact component={VendorProfile} />
      <Route path="/unauthorized" component={Unauthorized} />
      <Route path="/error" component={Error} />
      <ProtectedRoute path="/users" exact component={CorlUsers} />
      <ProtectedRoute path="/addNewClient" exact component={ClientsCompanyInfo} />
      <ProtectedRoute path="/addNewVendor" exact component={VendorCompanyInfo} />
      <ProtectedRoute path="/allusers-list" exact component={AllUsers} />
      <ProtectedRoute path="/assessment-request/:id" exact component={AssessmentRequestReview} />
      <ProtectedRoute path="/assessment-request-list" component={AssessmentRequest} />
      <ProtectedRoute path="/verify-vendor/:id" exact component={VerifyVendor} />
      <ProtectedRoute path="/product-profile-details/:vendorOrgId/:productId" exact component={ProductDetails} />
      <ProtectedRoute path="/replace-vendor/:id" exact component={ReplaceVendor} />
    </Suspense>
  );
}
