import {
  SEARCH_VENDOR_LIST_LOADING,
  SEARCH_VENDOR_LIST_SUCCESS,
  SEARCH_VENDOR_LIST_FAILURE,
  SEARCH_ASSESSMENT_LIST_LOADING,
  SEARCH_ASSESSMENT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_LIST_FAILURE,
  SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING,
  SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE,
  SET_VENDOR_LIST_PAGE,
  UPDATE_VENDOR_LIST,
  SEARCH_CLIENT_LIST_LOADING,
  SEARCH_CLIENT_LIST_SUCCESS,
  SEARCH_CLIENT_LIST_FAILURE,
  SET_CLIENT_LIST_PAGE,
  UPDATE_CLIENT_LIST,
  SET_PRODUCT_LIST_PAGE,
  UPDATE_PRODUCT_LIST,
  SEARCH_ASSESSMENT_CONFIG_LOADING,
  SEARCH_ASSESSMENT_CONFIG_SUCCESS,
  SEARCH_ASSESSMENT_CONFIG_FAILURE,
  SEARCH_ASSESSMENT_LIST_COUNT_LOADING,
  SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
  SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
  SET_ASSESSMENT_PAGE_NO,
  SET_ASSESSMENT_ROW_PER_PAGE,
  SET_ASSESSMENT_FILTERS,
  SET_SELECTED_ASSESSMENT_DETAILS_CARD,
  SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET,
  RESET_QUESTIONAIRE_SUMMARY,
  GET_QUESTIONNAIRE_INFO_FAILURE,
  GET_ASSESSMENT_NOTES_FAILURE,
  GET_ASSESSMENT_NOTES_LOADING,
  GET_QUESTIONNAIRE_INFO_LOADING,
  GET_ASSESSMENT_NOTES_SUCCESS,
  GET_QUESTIONNAIRE_INFO_SUCCESS,
} from "actions/assessments";
import { DEFAULT_ROWS_PER_PAGE } from "constants/constants";
import { formatDistance, intlFormatDistance } from "date-fns";
import { isEmpty, uniqBy } from "lodash";
import { ASSESSMENT_REQUEST_LIST_TAB_OPTIONS } from "pages/AssessmentRequest/AssessmentRequestList/assessmentRequestListConstants";
import { getSelectOptionsList } from "utils/arrayUtils";
import { getPercentage } from "utils/numberUtils";

const initialState = {
  loading: false,
  listLoading: false,
  assessmentList: [],
  vendorList: [],
  searchValueVendorList: [],
  vendorListPage: 1,
  clientList: [],
  searchValueClientList: [],
  clientListPage: 1,
  productList: [],
  searchValueProductList: [],
  productListPage: 1,
  assessmentConfigurationStatuses: [],
  assessmentListCount: 0,
  assessmentPageNo: 0,
  assessmentRowsPerPage: DEFAULT_ROWS_PER_PAGE,
  assessmentRequestsFilters: {},
  selectedAssessment: {},
  openAssessmentDetailsSidesheet: false,
  scopingQuestionnaireInfo: [],
  controlQuestionnaireInfo: [],
  assessmentNotes: [],
};

const getModifiedList = (actualList, completedAssessmentsCount = 0) => {

    let list = actualList;
    if (!isEmpty(actualList) && actualList?.length > 0) {
        list = actualList?.map((it, index) => {
            return {
                ...it,
                Id: index,
                LastStatusActivity: (!isEmpty(it) && !isEmpty(it?.LastUpdated)) ? (intlFormatDistance(new Date(it?.LastUpdated), new Date())?.includes("quarter") ?
                    formatDistance(new Date(it?.LastUpdated), new Date(), { addSuffix: true }) :
                    intlFormatDistance(new Date(it?.LastUpdated), new Date())) : null
            }
        });
    }
    return list;
}

export default function assessments(state = initialState, action) {
  const {
    type,
    payload,
    searchVendorValue,
    searchClientValue,
    searchProductValue,
  } = action;

  switch (type) {
    case SEARCH_VENDOR_LIST_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case SEARCH_VENDOR_LIST_SUCCESS: {
      let searchValueVendorList = [];
      let currentVendorList = [];
      if (searchVendorValue) {
        searchValueVendorList = getSelectOptionsList(
          payload?.Data?.Rows,
          "VendorOrgName",
          "VendorOrgName"
        );
      } else {
        currentVendorList = getSelectOptionsList(
          payload?.Data?.Rows,
          "VendorOrgName",
          "VendorOrgName"
        );
      }

      return {
        ...state,
        listLoading: false,
        vendorList: uniqBy(
          [...state.vendorList, ...currentVendorList],
          "value"
        ),
        searchValueVendorList: uniqBy(
          [...state.searchValueVendorList, ...searchValueVendorList],
          "value"
        ),
      };
    }

    case SEARCH_VENDOR_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case SEARCH_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentList: getModifiedList(payload?.Data?.Rows),
      };
    }
    case SEARCH_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentList: payload,
      };
    }

    case SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS: {
      let searchValueProductList = [];
      let currentProductList = [];
      if (searchProductValue) {
        searchValueProductList = getSelectOptionsList(
          payload?.Data?.Rows,
          "ProductName",
          "ProductName"
        );
      } else {
        currentProductList = getSelectOptionsList(
          payload?.Data?.Rows,
          "ProductName",
          "ProductName"
        );
      }
      return {
        ...state,
        listLoading: false,
        productList: uniqBy(
          [...state.productList, ...currentProductList],
          "value"
        ),
        searchValueProductList: uniqBy(
          [...state.searchValueProductList, ...searchValueProductList],
          "value"
        ),
      };
    }

    case SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case SET_VENDOR_LIST_PAGE: {
      return {
        ...state,
        vendorListPage: payload,
      };
    }

    case UPDATE_VENDOR_LIST: {
      return {
        ...state,
        searchValueVendorList: uniqBy(payload, "value"),
      };
    }

    case SEARCH_CLIENT_LIST_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case SEARCH_CLIENT_LIST_SUCCESS: {
      let searchValueClientList = [];
      let currentClientList = [];
      if (searchClientValue) {
        searchValueClientList = getSelectOptionsList(
          payload?.Data?.Rows,
          "ClientOrgName",
          "OrgId"
        );
      } else {
        currentClientList = getSelectOptionsList(
          payload?.Data?.Rows,
          "ClientOrgName",
          "OrgId"
        );
      }

      return {
        ...state,
        listLoading: false,
        clientList: uniqBy(
          [...state.clientList, ...currentClientList],
          "value"
        ),
        searchValueClientList: uniqBy(
          [...state.searchValueClientList, ...searchValueClientList],
          "value"
        ),
      };
    }

    case SEARCH_CLIENT_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case SET_CLIENT_LIST_PAGE: {
      return {
        ...state,
        clientListPage: payload,
      };
    }

    case UPDATE_CLIENT_LIST: {
      return {
        ...state,
        searchValueClientList: uniqBy(payload, "value"),
      };
    }

    case SET_PRODUCT_LIST_PAGE: {
      return {
        ...state,
        productListPage: payload,
      };
    }

    case UPDATE_PRODUCT_LIST: {
      return {
        ...state,
        searchValueProductList: uniqBy(payload, "value"),
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentConfigurationStatuses: getSelectOptionsList(
          payload?.Data?.Rows,
          "AssessmentDisplayStatus",
          "MasterStepName"
        ),
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentConfigurationStatuses: [],
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentListCount:
          payload?.Data?.Rows?.length > 0 &&
          payload?.Data?.Rows[0]?.CountOfAssessment,
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentListCount: payload,
      };
    }

    case SET_ASSESSMENT_PAGE_NO: {
      return {
        ...state,
        assessmentPageNo: payload,
      };
    }

    case SET_ASSESSMENT_ROW_PER_PAGE: {
      return {
        ...state,
        assessmentRowsPerPage: payload,
      };
    }

    case SET_ASSESSMENT_FILTERS: {
      return {
        ...state,
        assessmentRequestsFilters: payload,
      };
    }
    case SET_SELECTED_ASSESSMENT_DETAILS_CARD: {
      return {
        ...state,
        selectedAssessment: payload,
      };
    }
    case SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET: {
      return {
        ...state,
        openAssessmentDetailsSidesheet: payload,
      };
    }
    case RESET_QUESTIONAIRE_SUMMARY: {
      return {
        ...state,
        scopingQuestionnaireInfo: [],
        controlQuestionnaireInfo: [],
      };
    }
    case GET_ASSESSMENT_NOTES_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentNotes: [],
      };
    }
    case GET_QUESTIONNAIRE_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        scopingQuestionnaireInfo: [],
        controlQuestionnaireInfo: [],
      };
    }
    case GET_ASSESSMENT_NOTES_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_QUESTIONNAIRE_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ASSESSMENT_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentNotes: payload,
      };
    }
    case GET_QUESTIONNAIRE_INFO_SUCCESS: {
      let scoping = [],
        control = [];
      payload.map((item) => {
        if (
          item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList.length > 0
        ) {
          item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.forEach(
            (element) => {
              if (element?.QuestionnaireTemplateType === "scoping") {
                scoping.push(element);
              } else if (element?.QuestionnaireTemplateType === "control") {
                control.push(element);
              }
            }
          );
        }
      });
      return {
        ...state,
        loading: false,
        scopingQuestionnaireInfo: scoping,
        controlQuestionnaireInfo: control,
      };
    }

    default:
      return state;
  }
}
