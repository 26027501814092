import jwt_decode from "jwt-decode";
import { isEmpty, isNil } from "lodash";

export const isTokenExpired = (token, expiryWindow = 0) => {
  try {
    if (isNil(token) || isEmpty(token)) {
      return false;
    }

    const decodedToken = jwt_decode(token?.replace("Bearer ", ""));
    if (!isNil(decodedToken) && !isEmpty(decodedToken)) {
      if (decodedToken?.exp - Date.now() / 1000 <= expiryWindow) {
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    return false;
  }
};
