import {
  POST_PRODUCT_GROUP_TO_LIST_LOADING,
  POST_PRODUCT_GROUP_TO_LIST_SUCCESS,
  POST_PRODUCT_GROUP_TO_LIST_FAILURE,
  PUT_PRODUCT_GROUP_TO_LIST_LOADING,
  PUT_PRODUCT_GROUP_TO_LIST_SUCCESS,
  PUT_PRODUCT_GROUP_TO_LIST_FAILURE,
  DELETE_PRODUCT_GROUP_FROM_LIST_LOADING,
  DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS,
  DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE,
  POST_PRODUCT_TO_LIST_LOADING,
  POST_PRODUCT_TO_LIST_SUCCESS,
  POST_PRODUCT_TO_LIST_FAILURE,
  PUT_PRODUCT_TO_LIST_LOADING,
  PUT_PRODUCT_TO_LIST_SUCCESS,
  PUT_PRODUCT_TO_LIST_FAILURE,
  DELETE_PRODUCT_FROM_LIST_LOADING,
  DELETE_PRODUCT_FROM_LIST_SUCCESS,
  DELETE_PRODUCT_FROM_LIST_FAILURE,
  SEARCH_PRODUCT_GROUP_LIST_LOADING,
  SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
  SEARCH_PRODUCT_GROUP_LIST_FAILURE,
  SEARCH_PRODUCT_LIST_LOADING,
  SEARCH_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAILURE,
  SET_VENDORS_PRODUCTS_TAB_VALUE,
  SET_PRODUCT_DELETE_ERRORS,
  SET_PRODUCTS_GROUP_FORCE_DELETE,
  SEARCH_SELECTED_PRODUCT_DETAILS_LOADING,
  SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS,
  SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE,
  ADD_PRODUCT_COMPONENT_LOADING,
  ADD_PRODUCT_COMPONENT_SUCCESS,
  ADD_PRODUCT_COMPONENT_FAILURE,
  SEARCH_PRODUCT_COMPONENT_LOADING,
  SEARCH_PRODUCT_COMPONENT_SUCCESS,
  SEARCH_PRODUCT_COMPONENT_FAILURE,
  UPDATE_PRODUCT_COMPONENT_LOADING,
  UPDATE_PRODUCT_COMPONENT_SUCCESS,
  UPDATE_PRODUCT_COMPONENT_FAILURE,
  DELETE_PRODUCT_COMPONENT_LOADING,
  DELETE_PRODUCT_COMPONENT_SUCCESS,
  DELETE_PRODUCT_COMPONENT_FAILURE,
  ADD_CORL_CLEARED_ASSESSMENT_LOADING,
  ADD_CORL_CLEARED_ASSESSMENT_SUCCESS,
  ADD_CORL_CLEARED_ASSESSMENT_FAILURE,
} from "../actions/vendorProduct";
import { ERROR_MESSAGE, PRODUCT_GROUP_ADDED_SUCCESSFULLY, PRODUCT_GROUP_DELETED_SUCCESSFULLY, PRODUCT_GROUP_UPDATED_SUCCESSFULLY, VENDORS_PRODUCTS_TAB_OPTIONS } from "pages/VendorProfile/Products/constants";
import { toast } from "react-toastify";
import { PRODUCT_ADDED_SUCCESSFULLY, PRODUCT_DELETED_SUCCESSFULLY, PRODUCT_UPDATED_SUCCESSFULLY } from "constants/errorMessage";
import { getSelectOptionsList } from "utils/arrayUtils";
import { CORL_CLEARED_ASSESSMENT_ADDED_SUCCESSFULLY, PRODUCT_COMPONENT_ADDED_SUCCESSFULLY, PRODUCT_COMPONENT_DELETED_SUCCESSFULLY, PRODUCT_COMPONENT_UPDATED_SUCCESSFULLY } from "components/Common/AlertMessages";

const initialState = {
  loading: false,
  productGroupNames: {},
  productGroupList: [],
  productsList:[],
  deleteProductGroup:false,
  vendorProductsTabValue: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
  autoCompleteProductGroup: [],
  vendorProductDeleteError: [],
  productComponents: [],
  selectedProductDetails: {},
  vendorOrgId: null,
  forceDelete: false,
};

export function vendorProductsPage(state = initialState, action) {
  const { type, payload } = action;

      switch (type) {
        case SEARCH_PRODUCT_GROUP_LIST_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }
        case SEARCH_PRODUCT_GROUP_LIST_SUCCESS: {
          return {
            ...state,
            loading: false,
            productGroupList: payload,
            autoCompleteProductGroup:getSelectOptionsList(payload,"ProductGroupName","ProductGroupId")
          };
        }
        case SEARCH_PRODUCT_GROUP_LIST_FAILURE: {          
          return {
            ...state,
            loading: false,
            productGroupList: []
          };
        }
        case POST_PRODUCT_GROUP_TO_LIST_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }
        case POST_PRODUCT_GROUP_TO_LIST_SUCCESS: {
          toast.success(PRODUCT_GROUP_ADDED_SUCCESSFULLY)
          return {
            ...state,
            loading: false,
            productGroup: payload
          };
        }
        case POST_PRODUCT_GROUP_TO_LIST_FAILURE: {  
          toast.error(ERROR_MESSAGE)        
          return {
            ...state,
            loading: false,
          };
        }
        case PUT_PRODUCT_GROUP_TO_LIST_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }
        case PUT_PRODUCT_GROUP_TO_LIST_SUCCESS: {
          toast.success(PRODUCT_GROUP_UPDATED_SUCCESSFULLY)          
          return {
            ...state,
            loading: false,
          };
        }
        case PUT_PRODUCT_GROUP_TO_LIST_FAILURE: {
          toast.error(ERROR_MESSAGE)
          return {
            ...state,
            loading: false,
          };
        }
        case DELETE_PRODUCT_GROUP_FROM_LIST_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }
        case DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS: {
          toast.success(PRODUCT_GROUP_DELETED_SUCCESSFULLY)
          return {
            ...state,
            loading: false,      
            forceDelete: false      
          };
        }
        case DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE: {
          if(!payload){
            toast.error(ERROR_MESSAGE)
          }
          return {
            ...state,
            loading: false,
            forceDelete: payload
          };
        }    
        case SEARCH_PRODUCT_LIST_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }
    
        case SEARCH_PRODUCT_LIST_SUCCESS: {
          const { vendorOrgId } = action;
          return {
            ...state,
            loading: false,
            productsList: payload,
            vendorOrgId: vendorOrgId
          };
        }
    
        case SEARCH_PRODUCT_LIST_FAILURE: {
          return {
            ...state,
            loading: false,
            productsList: [],
          };
        }
    
        case POST_PRODUCT_TO_LIST_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }
    
        case POST_PRODUCT_TO_LIST_SUCCESS: {
          toast.success(PRODUCT_ADDED_SUCCESSFULLY);
          return {
            ...state,
            loading: false,
          };
        }
    
        case POST_PRODUCT_TO_LIST_FAILURE: {
          toast.error(ERROR_MESSAGE);
          return {
            ...state,
            loading: false,
          };
        }
    
        case PUT_PRODUCT_TO_LIST_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }
    
        case PUT_PRODUCT_TO_LIST_SUCCESS: {
          toast.success(PRODUCT_UPDATED_SUCCESSFULLY)
          return {
            ...state,
            loading: false,
          };
        }
    
        case PUT_PRODUCT_TO_LIST_FAILURE: {
          toast.error(ERROR_MESSAGE);
          return {
            ...state,
            loading: false,
          };
        }
    
        case DELETE_PRODUCT_FROM_LIST_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }
    
        case DELETE_PRODUCT_FROM_LIST_SUCCESS: {
          toast.success(PRODUCT_DELETED_SUCCESSFULLY)
          return {
            ...state,
            loading: false,
          };
        }
    
        case DELETE_PRODUCT_FROM_LIST_FAILURE: {
          toast.error(ERROR_MESSAGE);
          return {
            ...state,
            loading: false,
          };
        }
        case SET_VENDORS_PRODUCTS_TAB_VALUE: {
          return {
            ...state,
            vendorProductsTabValue:
              payload || VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
          };
        }  
        case SET_PRODUCT_DELETE_ERRORS: {
          return {
            ...state,
            vendorProductDeleteError:payload
          };
        }   
        case SET_PRODUCTS_GROUP_FORCE_DELETE: {
          return {
            ...state, 
            forceDelete: payload
          }
        }   
        case SEARCH_SELECTED_PRODUCT_DETAILS_LOADING: {
          return {
            ...state, 
            loading: true,
          }
        }
        case SEARCH_SELECTED_PRODUCT_DETAILS_SUCCESS: {
          return {
            ...state, 
            loading: false,
            selectedProductDetails: payload
          }
        }
        case SEARCH_SELECTED_PRODUCT_DETAILS_FAILURE: {
          return {
            ...state, 
            loading: false,
            selectedProductDetails: {}
          }
        }
        case ADD_PRODUCT_COMPONENT_LOADING: {
          return {
            ...state, 
            loading: true,
          }
        }
        case ADD_PRODUCT_COMPONENT_SUCCESS: {
          toast.success(PRODUCT_COMPONENT_ADDED_SUCCESSFULLY)
          return {
            ...state, 
            loading: false,
          }
        }
        case ADD_PRODUCT_COMPONENT_FAILURE: {
          toast.error(ERROR_MESSAGE)
          return {
            ...state, 
            loading: false,
          }
        }
        case UPDATE_PRODUCT_COMPONENT_LOADING: {
          return {
            ...state, 
            loading: true,
          }
        }
        case UPDATE_PRODUCT_COMPONENT_SUCCESS: {
          toast.success(PRODUCT_COMPONENT_UPDATED_SUCCESSFULLY)
          return {
            ...state, 
            loading: false,
          }
        }
        case UPDATE_PRODUCT_COMPONENT_FAILURE: {
          toast.error(ERROR_MESSAGE)
          return {
            ...state, 
            loading: false,
          }
        }
        case DELETE_PRODUCT_COMPONENT_LOADING: {
          return {
            ...state, 
            loading: true,
          }
        }
        case DELETE_PRODUCT_COMPONENT_SUCCESS: {
          toast.success(PRODUCT_COMPONENT_DELETED_SUCCESSFULLY)
          return {
            ...state, 
            loading: false,
          }
        }
        case DELETE_PRODUCT_COMPONENT_FAILURE: {
          toast.error(ERROR_MESSAGE)
          return {
            ...state, 
            loading: false,
          }
        }
        case SEARCH_PRODUCT_COMPONENT_LOADING: {
          return {
            ...state, 
            loading: true,
          }
        }
        case SEARCH_PRODUCT_COMPONENT_SUCCESS: {
          return {
            ...state, 
            loading: false,
            productComponents: payload
          }
        }
        case SEARCH_PRODUCT_COMPONENT_FAILURE: {
          return {
            ...state, 
            loading: false,
            productComponents: []
            }
        }
        case ADD_CORL_CLEARED_ASSESSMENT_LOADING: {
          return {
            ...state, 
            loading: true,
          }
        }
        case ADD_CORL_CLEARED_ASSESSMENT_SUCCESS: {
          toast.success(CORL_CLEARED_ASSESSMENT_ADDED_SUCCESSFULLY)
          return {
            ...state, 
            loading: false,
          }
        }
        case ADD_CORL_CLEARED_ASSESSMENT_FAILURE: {
          toast.error(ERROR_MESSAGE)
          return {
            ...state, 
            loading: false,
          }
        }
        default:
          return state;
  } 
}
