import axios from 'axios';
import { setErrors } from './errorHandler';

export const GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST = 'GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST';
export const GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS = 'GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS';
export const GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL = 'GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL';

export const getQuestionnaireTemplate = (id) => async (dispatch) => {
  dispatch({ type: GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST });
  try {
    const res = await axios.get(`/questionnairetemplate/${id}`);

    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL,
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAIL,
    });
  }
};


export const SET_QUESTIONNAIRE_TEMPLATE_DETAILS = '@@questionnaireTemplate/SET_QUESTIONNAIRE_TEMPLATE_DETAILS';

export const setQuestionnaireTemplateDetails = (detail) => async (dispatch) => {
  dispatch({
    type: SET_QUESTIONNAIRE_TEMPLATE_DETAILS,
    payload: detail,
  });
};