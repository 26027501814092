export const getVendorIncidentContactBody = (searchText = "") => {
  return {
    "intent": "",
    "fields": [
      "FirstName",
      "LastName",
      "Title",
      "Email",
      "CellPhone",
      "OfficePhone",
      "VendorContactId",
      "FullName",
      "IsTeamContact",
    ],
    "criterion": [
      {
        "field": "FullName",
        "operator": "startswith",
        "value": searchText
      }
    ],
    "sort": [
      {
        "field": "FullName",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0
  }
}


export const constructIncidentResponseContactFormData = (data) => {
  return {
    "customerVendorId": data?.CustomerVendorId,
    "productVendorId": data?.ProductVendorId,
    "customerVendorProductId": data?.CustomerVendorProductId,
    "vendorContactId": data?.VendorContactId,
    "contactTypeId": data?.ContactTypeId,
    "title": data?.formData?.Title,
    "cellPhone": data?.formData?.CellPhone,
    "officePhone": data?.formData?.OfficePhone,
    "active": true,
  }
}

export const getCustomerProductContactBody = (vendorOrgId, customerVendorId, productVendorId) => {

  return {
    "intent": "",
    "fields": [
      "FirstName",
      "LastName",
      "Title",
      "Email",
      "CustomerVendorId",
      "ProductVendorId",
      "ContactType",
      "FullName",
      "IsTeamContact",
    ],
    "criterion": [
      {
        "field": "VendorOrgId",
        "operator": "=",
        "value": vendorOrgId
      },
      {
        "field": "CustomerVendorId",
        "operator": customerVendorId === null ? "isnull" : "=",
        "value": customerVendorId === null ? "0" : customerVendorId
      },
      {
        "field": "ProductVendorId",
        "operator": productVendorId === null ? "isnull" : "=",
        "value": productVendorId === null ? "0" : productVendorId
      }

    ],
    "sort": [
      {
        "field": "FullName",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0
  }
}