import axios from 'axios';
import { isNil } from 'lodash';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { NO_DATA_FOUND } from '../constants/errorMessage'
import { DRAFT_STATUS } from '../pages/IncidentBuilder/constants'


export const GET_INCIDENT_RECORD_TYPE_NAME_LIST_LOADING = '@incidentBuilder/GET_INCIDENT_RECORD_TYPE_NAME_LIST_LOADING';
export const GET_INCIDENT_RECORD_TYPE_NAME_LIST_SUCCESS = '@incidentBuilder/GET_INCIDENT_RECORD_TYPE_NAME_LIST_SUCCESS';
export const GET_INCIDENT_RECORD_TYPE_NAME_LIST_FAILURE = '@incidentBuilder/GET_INCIDENT_RECORD_TYPE_NAME_LIST_FAILURE';

export const getIncidentRecordTypeNameList = (entityName) => async (dispatch) => {
  dispatch({ type: GET_INCIDENT_RECORD_TYPE_NAME_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=${entityName}`);
    if(response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_INCIDENT_RECORD_TYPE_NAME_LIST_FAILURE,
        payload: []
      });
    } else {
      dispatch({
        type: GET_INCIDENT_RECORD_TYPE_NAME_LIST_SUCCESS,
        payload: response.data
      });
    }
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_RECORD_TYPE_NAME_LIST_FAILURE,
      payload: getErrorObject(err)
    });
  }
};

export const GET_INCIDENT_STATUS_TYPE_NAME_LIST_LOADING = '@incidentBuilder/GET_INCIDENT_STATUS_TYPE_NAME_LIST_LOADING';
export const GET_INCIDENT_STATUS_TYPE_NAME_LIST_SUCCESS = '@incidentBuilder/GET_INCIDENT_STATUS_TYPE_NAME_LIST_SUCCESS';
export const GET_INCIDENT_STATUS_TYPE_NAME_LIST_FAILURE = '@incidentBuilder/GET_INCIDENT_STATUS_TYPE_NAME_LIST_FAILURE';

export const getIncidentStatusTypeNameList = (entityName) => async (dispatch) => {
  dispatch({ type: GET_INCIDENT_STATUS_TYPE_NAME_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=${entityName}`);
    if(response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_INCIDENT_STATUS_TYPE_NAME_LIST_FAILURE,
        payload: []
      });
    } else {
      dispatch({
        type: GET_INCIDENT_STATUS_TYPE_NAME_LIST_SUCCESS,
        payload: response.data
      });
    }
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_STATUS_TYPE_NAME_LIST_FAILURE,
      payload: getErrorObject(err)
    });
  }
};

export const GET_INCIDENT_SEVERITY_LIST_LOADING = '@incidentBuilder/GET_INCIDENT_SEVERITY_LIST_LOADING';
export const GET_INCIDENT_SEVERITY_LIST_SUCCESS = '@incidentBuilder/GET_INCIDENT_SEVERITY_LIST_SUCCESS';
export const GET_INCIDENT_SEVERITY_LIST_FAILURE = '@incidentBuilder/GET_INCIDENT_SEVERITY_LIST_FAILURE';

export const getIncidentSeverityList = (entityName) => async (dispatch) => {
  dispatch({ type: GET_INCIDENT_SEVERITY_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=${entityName}`);
    if(response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_INCIDENT_SEVERITY_LIST_FAILURE,
        payload: []
      });
    } else {
      dispatch({
        type: GET_INCIDENT_SEVERITY_LIST_SUCCESS,
        payload: response.data
      });
    }
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_SEVERITY_LIST_FAILURE,
      payload: getErrorObject(err)
    });
  }
};

export const GET_INCIDENT_RECORD_LOADING = '@incidenBuilder/GET_INCIDENT_RECORD_LOADING';
export const GET_INCIDENT_RECORD_SUCCESS = '@incidenBuilder/GET_INCIDENT_RECORD_SUCCESS';
export const GET_INCIDENT_RECORD_FAILURE = '@incidenBuilder/GET_INCIDENT_RECORD_FAILURE';

export const getIncidentRecord = (id) => async (dispatch) => {
  dispatch({ type: GET_INCIDENT_RECORD_LOADING });
  try {
    const response = await axios.get(`/incident/${id}`);
    if(response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter(it => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_INCIDENT_RECORD_FAILURE,
        payload: {}
      });
    } else {
      dispatch({
        type: GET_INCIDENT_RECORD_SUCCESS,
        payload: response.data
      });
    }
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_RECORD_FAILURE,
      payload: getErrorObject(err)
    });
  }
};


export const POST_INCIDENT_RECORD_LOADING = '@incidentBuilder/POST_INCIDENT_RECORD_LOADING';
export const POST_INCIDENT_RECORD_SUCCESS = '@incidentBuilder/POST_INCIDENT_RECORD_SUCCESS';
export const POST_INCIDENT_RECORD_FAILURE = '@incidentBuilder/POST_INCIDENT_RECORD_FAILURE';

export const createIncidentRecord = (id, formData, callback) => async dispatch => {
    dispatch({
        type: POST_INCIDENT_RECORD_LOADING
    });
    let body;
    if(isNil(formData?.Status)){
      body = {
        ...formData,
        Status: DRAFT_STATUS
      }
    }
    else {
      body = formData
    }
    body = JSON.stringify(body);
    try {
        let response;
        if(!isNil(id)){
          response = await axios.put(`/incident/${id}`, body);
        }
        else{
          response = await axios.post('/incident', body);
        }

        if(response?.data?.ErrorCount > 0) {
          dispatch(setErrors(response?.data?.Errors));
          dispatch({
            type: POST_INCIDENT_RECORD_FAILURE
          })
        } else {
          dispatch({
              type: POST_INCIDENT_RECORD_SUCCESS,
              payload: response?.data
          });
          if(callback) {
            callback(response?.data?.Data)
          }
        }
    } catch (err) {
        dispatch({
          type: POST_INCIDENT_RECORD_FAILURE,
          payload: getErrorObject(err)
        })
    }
}

export const POST_INCIDENT_RECORD_STATUS_LOADING = '@incidentBuilder/POST_INCIDENT_RECORD_STATUS_LOADING';
export const POST_INCIDENT_RECORD_STATUS_SUCCESS = '@incidentBuilder/POST_INCIDENT_RECORD_STATUS_SUCCESS';
export const POST_INCIDENT_RECORD_STATUS_FAILURE = '@incidentBuilder/POST_INCIDENT_RECORD_STATUS_FAILURE';

export const updateIncidentRecordStatus = (id, formData, callback) => async dispatch => {
    dispatch({
        type: POST_INCIDENT_RECORD_STATUS_LOADING
    });
    const body = JSON.stringify(formData);
    try {
        let response;
        if(!isNil(id)){
          response = await axios.post(`incident/${id}/action/updatestatus`, body);
        }
        if(response?.data?.ErrorCount > 0) {
          dispatch(setErrors(response?.data?.Errors));
          dispatch({
            type: POST_INCIDENT_RECORD_STATUS_FAILURE
          })
        } else {
          dispatch({
              type: POST_INCIDENT_RECORD_STATUS_SUCCESS,
              payload: response?.data
          });
          if(callback) {
            callback()
          }
        }
    } catch (err) {
        dispatch({
          type: POST_INCIDENT_RECORD_STATUS_FAILURE,
          payload: getErrorObject(err)
        })
    }
}

export const SET_INCIDENT_RECORD = "@incidentBuilder/SET_INCIDENT_RECORD"
export const setIncidentrecord = data => async dispatch => {
  dispatch({
    type: SET_INCIDENT_RECORD,
    payload: data
  })
}
