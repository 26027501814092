import {
    Box,
    Breadcrumbs,
    Button,
    Grid,
    Link,
    Typography,
} from '@mui/material';

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import Seperator from "../../assets/icons/breadcrumbs-seperator.svg";

import {bannerContainerWrap, bannerTitleStyles, breadcrumbLabel, buttonsGrid, breadCrumbs, bannerButtonStyles} from './bannerStyle';

import Container from "../Page/Container";
import Status from "../Status/Status";
import Remove from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';
import { useHistory } from 'react-router';

const Banner = ({ title, buttonLabel, breadcrumbs,onClickButton,icon, buttonRoute, disabled = false }) => {
  
  const history = useHistory();
  
  const onClickBreadCrumb = (url) => {
    history.push(url);
  };
    
    return (
        <>
         
            <Box sx={bannerContainerWrap}>
                <Grid container>
                    <Grid item xs={8} >
                    {breadcrumbs &&
                    <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={breadCrumbs}
                    separator={<img alt="" src={Seperator} />}
                    >
                    {breadcrumbs &&
                      breadcrumbs.map((breadcrumb, i) => {
                        return (
                          <Link
                            component={"button"}
                            key={i}
                            underline="hover"
                            color="primary"
                            onClick={() => onClickBreadCrumb(breadcrumb.url)}
                            sx={breadcrumbLabel}
                          >
                            {breadcrumb.text}
                          </Link>
                        );
                      })}
                     </Breadcrumbs>
                    }
                    {
                      title && 
                      <Typography sx={(theme) => bannerTitleStyles(theme, breadcrumbs)}>
                        {title}
                      </Typography>
                    }
                    </Grid>

                    <Grid item xs={4} sx={buttonsGrid}>
                    
                    {buttonLabel && 
                    (
                    <Button 
                      variant="contained"
                      href={buttonRoute}
                      onClick={onClickButton}
                      startIcon={icon}
                      className={disabled ? "isDisabled" : ""}
                      disabled={disabled}
                      sx={bannerButtonStyles}
                    >
                      {buttonLabel}
                    </Button>
                    )}
                    </Grid>
                </Grid>
            </Box>
          
        </>

    )

}

export default Banner;