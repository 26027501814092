import axios from 'axios';
import { setErrors } from './errorHandler';
import { QUESTIONNAIRE_TEMPLATE_STATUS } from '../constants/questionnaireTemplate';
import { ACTIVE } from '../constants/active';
import { getErrorObject } from '../utils/apiUtils';
import { NO_DATA_FOUND } from '../constants/errorMessage'
import { getIncidentListSearchBody } from '../utils/questionnaireBuilderUtil'
import { DRAFT_STATUS_ID, QA_REJECTED_STATUS, READY_FOR_QA_STATUS, QA_APPROVED_STATUS } from '../pages/QuestionnaireBuilder/constants';

export const SET_QUESTION_LIST = '@questionnaireBuilder/SET_QUESTION_LIST';
export const setQuestionList = (question) => async (dispatch) => {
      dispatch({       
        type: SET_QUESTION_LIST,       
        payload: question  
    })
}

export const HIDE_ALERT = '@questionnaireBuilder/HIDE_ALERT';
export const hideAlert = () => async (dispatch) => {
      dispatch({       
        type: HIDE_ALERT,
    })
}

export const POST_QUESTIONNAIRE_TEMPLATE_LOADING = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_LOADING';
export const POST_QUESTIONNAIRE_TEMPLATE_SUCCESS = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_SUCCESS';
export const POST_QUESTIONNAIRE_TEMPLATE_FAILURE = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_FAILURE';

export const createQuestionnaireTemplate = (formData, callback) => async dispatch => {
    dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_LOADING
    });
    const data = {
        ...formData,
        questionnaireTemplateStatus: QUESTIONNAIRE_TEMPLATE_STATUS.DRAFT,
        active: ACTIVE.active
    }
    const body = JSON.stringify(data);

    try {
        const response = await axios.post('/questionnairetemplate', body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: POST_QUESTIONNAIRE_TEMPLATE_FAILURE
            });
        } else {
        dispatch({
            type: POST_QUESTIONNAIRE_TEMPLATE_SUCCESS,
            payload: response?.data
        });

        if (callback) {
            callback(response?.data?.Data.QuestionnaireTemplate);
        }
        }
    } catch {
        dispatch({
            type: POST_QUESTIONNAIRE_TEMPLATE_FAILURE
        });
    }
}

export const PUT_QUESTIONNAIRE_TEMPLATE_LOADING = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_LOADING';
export const PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS';
export const PUT_QUESTIONNAIRE_TEMPLATE_FAILURE = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_FAILURE';

export const updateQuestionnaireTemplate = (id, formData) => async dispatch => {
    dispatch({
        type: PUT_QUESTIONNAIRE_TEMPLATE_LOADING
    });
    const data = {
        ...formData,
        active: ACTIVE.active
    }
    const body = JSON.stringify(data);

    try {
        const response = await axios.put(`/questionnairetemplate/${id}`, body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: PUT_QUESTIONNAIRE_TEMPLATE_FAILURE
            });
        } else {
        dispatch({
            type: PUT_QUESTIONNAIRE_TEMPLATE_SUCCESS,
            payload: response?.data
        });
        let status =
          response?.data?.Data?.QuestionnaireTemplate?.QuestionnaireTemplateStatusName;
          if(status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {  
            await dispatch(
              updateStatusOfQuestionnaire({
                questionnaireTemplateId: id,
                newQuestionnaireTemplateStatus: 1,
              })
            );
          }          
        dispatch(getQuestionnaireTemplate(id));
        }
    } catch {
        dispatch({
            type: PUT_QUESTIONNAIRE_TEMPLATE_FAILURE
        });
    }
}


export const GET_QUESTIONNAIRE_TEMPLATE_LOADING = '@questionnaireBuilder/GET_QUESTIONNAIRE_TEMPLATE_LOADING';
export const GET_QUESTIONNAIRE_TEMPLATE_SUCCESS = '@questionnaireBuilder/GET_QUESTIONNAIRE_TEMPLATE_SUCCESS';
export const GET_QUESTIONNAIRE_TEMPLATE_FAILURE = '@questionnaireBuilder/GET_QUESTIONNAIRE_TEMPLATE_FAILURE';

export const getQuestionnaireTemplate = (id) => async dispatch => {    
    dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_LOADING
    });
    
    try {
      const response = await axios.get(`/questionnairetemplate/${id}`);

      if (response?.data?.ErrorCount > 0) {
        dispatch(setErrors(response?.data?.Errors));
        dispatch({
          type: GET_QUESTIONNAIRE_TEMPLATE_FAILURE,
        });
      } else {
        dispatch({
          type: GET_QUESTIONNAIRE_TEMPLATE_SUCCESS,
          payload: response?.data,
        });
      }
    } catch {
      dispatch({
        type: GET_QUESTIONNAIRE_TEMPLATE_FAILURE,
      });
    }
}

export const POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING';
export const POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS';
export const POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE = '@questionnaireBuilder/POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE';

export const createQuestionnaireTemplateQuestion = (id, status, formData, callback) => async dispatch => {    
    dispatch({
        type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING
    });
    const body = JSON.stringify(formData);
    
    try {
        const response = await axios.post(`/questionnairetemplate/${id}/question`, body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE
            });
        } else {
            dispatch({
                type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
                payload: response?.data
            }); 
            if(status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) {  
              await dispatch(
                updateStatusOfQuestionnaire({
                  questionnaireTemplateId: id,
                  newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
                })
              );
            }
            dispatch(getQuestionnaireTemplate(id));
            if (callback) {
                callback();
            }  
        }        
    } catch {
        dispatch({
            type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE
        });
    }
}

export const PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING';
export const PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS';
export const PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE = '@questionnaireBuilder/PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE';

export const updateQuestionnaireTemplateQuestion = (id, status, formData) => async dispatch => {    
    dispatch({
        type: PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING
    });
    const body = JSON.stringify(formData);
    
    try {
        const response = await axios.put(`/questionnairetemplate/${id}/question`, body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE
            });
        } else {
            dispatch({
                type: PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
                payload: response?.data
            });
            if(status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) { 
              await dispatch(
                updateStatusOfQuestionnaire({
                  questionnaireTemplateId: id,
                  newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
                })
              );
            }
            dispatch(getQuestionnaireTemplate(id));
        }               
    } catch {
        dispatch({
            type: PUT_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE
        })
    }
}

export const DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING';
export const DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS';
export const DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE = '@questionnaireBuilder/DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE';

export const deleteQuestionnaireTemplateQuestion = (id, status, questionId) => async dispatch => {    
    dispatch({
        type: DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_LOADING
    });
    
    try {
        const response = await axios.delete(`/questionnairetemplate/${id}/question/${questionId}`);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE
            });
        } else {
            dispatch({
                type: DELETE_QUESTIONNAIRE_TEMPLATE_QUESTION_SUCCESS,
                payload: response?.data
            });   
            if(status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) { 
              await dispatch(
                updateStatusOfQuestionnaire({
                  questionnaireTemplateId: id,
                  newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
                })
              );
            }
            dispatch(getQuestionnaireTemplate(id));
        }        
    } catch {
        dispatch({
            type: POST_QUESTIONNAIRE_TEMPLATE_QUESTION_FAILURE
        });
    }
}

export const SET_SELECTED_QUESTION = '@questionnaireBuilder/SET_SELECTED_QUESTION';
export const setSelectedQuestion = (question) => async (dispatch) => {
      dispatch({       
        type: SET_SELECTED_QUESTION,       
        payload: question  
    })
}

export const RESET_QUESTIONNAIRE_TEMPLATE = '@questionnaireBuilder/RESET_QUESTIONNAIRE_TEMPLATE';
export const resetQuestionnaireTemplate = (question) => async (dispatch) => {
      dispatch({       
        type: RESET_QUESTIONNAIRE_TEMPLATE,       
        payload: question  
    })
}

export const ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_LOADING = '@questionnaireBuilder/ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_LOADING';
export const ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS = '@questionnaireBuilder/ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS';
export const ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_FAILURE = '@questionnaireBuilder/ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_FAILURE';

export const actionQuestionnaireTemplateQuestionRequired = (id, status, questionId, formData) => async dispatch => {    
    dispatch({
        type: ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_LOADING
    });
    const body = JSON.stringify(formData);
    
    try {
        const response = await axios.post(`/questionnairetemplate/${questionId}/action/updateIsRequired`, body);
        if(response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_FAILURE
            });
        } else {
            dispatch({
                type: ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_SUCCESS,
                payload: response?.data
            });
            if(status === QA_REJECTED_STATUS || status === READY_FOR_QA_STATUS || status === QA_APPROVED_STATUS) { 
              await dispatch(
                updateStatusOfQuestionnaire({
                  questionnaireTemplateId: id,
                  newQuestionnaireTemplateStatus: DRAFT_STATUS_ID,
                })
              );
            }
            dispatch(getQuestionnaireTemplate(id));
        }        
    } catch {
        dispatch({
            type: ACTION_QUESTIONNAIRE_TEMPLATE_QUESTION_REQUIRED_FAILURE
        });
    }
}


export const SEARCH_INCIDENT_LIST_LOADING = '@@questionnaireBuilder/SEARCH_INCIDENT_LIST_LOADING';
export const SEARCH_INCIDENT_LIST_SUCCESS = '@@questionnaireBuilder/SEARCH_INCIDENT_LIST_SUCCESS' ;
export const SEARCH_INCIDENT_LIST_FAILURE = '@@questionnaireBuilder/SEARCH_INCIDENT_LIST_FAILURE' ;

export const getIncidentListData = () => async (dispatch) => {
    dispatch({ type: SEARCH_INCIDENT_LIST_LOADING });
    try {
      const body = getIncidentListSearchBody();
      const response = await axios.post(`/incident/search`, body);
      if(response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(it => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_INCIDENT_LIST_FAILURE,
          payload: []
        });
    } else {
      dispatch({
        type: SEARCH_INCIDENT_LIST_SUCCESS,
        payload: response.data
      });
    }
      
    } catch (err) {
      dispatch({
        type: SEARCH_INCIDENT_LIST_FAILURE,
        payload: getErrorObject(err)
      });
    }
};

export const GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING = '@@questionnaireBuilder/GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING';
export const GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS = '@@questionnaireBuilder/GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS';
export const GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE = '@@questionnaireBuilder/GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE';

export const getQuestionnaireStatusTypeNameList = (entityName) => async (dispatch) => {
  dispatch({ type: GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=${entityName}`);
    if(response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE,
        payload: []
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_SUCCESS,
        payload: response.data
      });
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_STATUS_TYPE_NAME_LIST_FAILURE,
      payload: getErrorObject(err)
    });
  }
};


export const POST_COMMENT_QUESTIONNAIRE_LOADING = '@@questionnaireBuilder/POST_COMMENT_QUESTIONNAIRE_LOADING';
export const POST_COMMENT_QUESTIONNAIRE_SUCCESS = '@@questionnaireBuilder/POST_COMMENT_QUESTIONNAIRE_SUCCESS';
export const POST_COMMENT_QUESTIONNAIRE_FAILURE = '@@questionnaireBuilder/POST_COMMENT_QUESTIONNAIRE_FAILURE';

export const addCommentToQuestionnaire = (formData) => async dispatch => {
    dispatch({
        type: POST_COMMENT_QUESTIONNAIRE_LOADING
    });
    const body = JSON.stringify(formData);
    try {
        let response;
        response = await axios.post(`/questionnairetemplate/action/reviewComment`, body);
        if(response?.data?.ErrorCount > 0) {
          dispatch(setErrors(response?.data?.Errors));
          dispatch({
            type: POST_COMMENT_QUESTIONNAIRE_FAILURE
          })
        } else {
          dispatch({
              type: POST_COMMENT_QUESTIONNAIRE_SUCCESS,
              payload: response?.data
          });
        }
    } catch (err) {
        dispatch({
          type: POST_COMMENT_QUESTIONNAIRE_FAILURE,
          payload: getErrorObject(err)
        })
    }
}

export const POST_QUESTIONNAIRE_STATUS_LOADING = '@@questionnaireBuilder/PUT_QUESTIONNAIRE_STATUS_LOADING';
export const POST_QUESTIONNAIRE_STATUS_SUCCESS = '@@questionnaireBuilder/PUT_QUESTIONNAIRE_STATUS_SUCCESS';
export const POST_QUESTIONNAIRE_STATUS_FAILURE = '@@questionnaireBuilder/PUT_QUESTIONNAIRE_STATUS_FAILURE';

export const updateStatusOfQuestionnaire = (formData) => async dispatch => {
    dispatch({
        type: POST_QUESTIONNAIRE_STATUS_LOADING
    });
    const body = JSON.stringify(formData);
    try {
        let response;
        response = await axios.post(`/questionnairetemplate/action/updatestatus`, body);
        if(response?.data?.ErrorCount > 0) {
          dispatch(setErrors(response?.data?.Errors));
          dispatch({
            type: POST_QUESTIONNAIRE_STATUS_FAILURE
          })
        } else {
          dispatch({
              type: POST_QUESTIONNAIRE_STATUS_SUCCESS,
              payload: response?.data
          });
        }
    } catch (err) {
        dispatch({
          type: POST_QUESTIONNAIRE_STATUS_FAILURE,
          payload: getErrorObject(err)
        })
    }
}

export const QUESTION_PANEL_DISABLE_STATE = '@questionnaireBuilder/QUESTION_PANEL_DISABLE_STATE';
export const questionPanelDisableStateUpdate = (status) => async (dispatch) => {
      dispatch({       
        type: QUESTION_PANEL_DISABLE_STATE,       
        payload: status  
    })
}

export const REORDER_QUESTIONS_LOADING = '@@questionnaireBuilder/REORDER_QUESTIONS_LOADING';
export const REORDER_QUESTIONS_SUCCESS = '@@questionnaireBuilder/REORDER_QUESTIONS_SUCCESS';
export const REORDER_QUESTIONS_FAILURE = '@@questionnaireBuilder/REORDER_QUESTIONS_FAILURE';

export const reorderQuestions = (formData, id) => async dispatch => {
    dispatch({
        type: REORDER_QUESTIONS_LOADING
    });
    
    const body = JSON.stringify(formData);
    try {
        let response;
        response = await axios.post(`/QuestionnaireTemplate/${id}/action/reorderquestions`, body);
        if(response?.data?.ErrorCount > 0) {
          dispatch(setErrors(response?.data?.Errors));
          dispatch({
            type: REORDER_QUESTIONS_FAILURE
          })
        } else {
          dispatch({
              type: REORDER_QUESTIONS_SUCCESS,
              payload: response?.data
          });
          dispatch(getQuestionnaireTemplate(id));
        }
    } catch (err) {
        dispatch({
          type: REORDER_QUESTIONS_FAILURE,
          payload: getErrorObject(err)
        })
    }
}