export default function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          height: "unset",
          marginTop: "29px",
          width: "100%"
        },
      },
    },
  };
}
