import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import {
    Stack,
    Box,
    Button,
    Typography
} from '@mui/material'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    menuPanelWrapper,
    menuPanelLinksWrapper,
    navigationWrapper,
    navigationLinkLable,
    quickActionsBtn
} from './NavBarStyles'
import { getIcon } from './util';
import { useDispatch } from 'react-redux';
import { setMenuPanelId } from 'actions/navbar';


const MenuPanel = ({ subMenuList }) => {
    const dispatch = useDispatch();
    const [showMenuPanel, setShowMenuPanel] = useState(true);

    const onClickSuMenu = (e, menuPanelValue) => {
        setShowMenuPanel(menuPanelValue);
        e.stopPropagation();
    }

    return (
        <>
            <Stack
                sx={(theme) => menuPanelWrapper(theme, { showMenuPanel })}
                onMouseLeave={() => dispatch(setMenuPanelId(0))}
                onClick={(e) => e.stopPropagation()}
            >
                <Box sx={menuPanelLinksWrapper}>
                    {
                        subMenuList.map((submenu, index) => {
                            const { AppMenuName, MenuRoute, ButtonLabel, ButtonRoute, ButtonActionClaim, ButtonIcon } = submenu;
                            return (
                                <Box sx={navigationWrapper} key={index}>
                                    <Link to={MenuRoute} onClick={(e) => onClickSuMenu(e, false)}>
                                        <Typography variant="h3" sx={navigationLinkLable}>
                                            {AppMenuName}
                                        </Typography>
                                    </Link>
                                    {
                                        ButtonActionClaim && ButtonLabel && (
                                            <Link to={ButtonRoute} onClick={(e) => onClickSuMenu(e, false)}>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    startIcon={<FontAwesomeIcon icon={getIcon(ButtonIcon)} />}
                                                    sx={quickActionsBtn}
                                                    className="activeNavBarButton"
                                                >
                                                    {ButtonLabel}
                                                </Button>
                                            </Link>
                                        )
                                    }
                                </Box>
                            );
                        })
                    }
                </Box>
            </Stack>
        </>
    )
}

export default MenuPanel