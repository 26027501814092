import {
  DELETE_SUCCESSFULLY_MESSAGE,
  ERROR_MESSAGE,
} from "components/Common/AlertMessages";
import {
  SEARCH_INCIDENT_LIST_LOADING,
  SEARCH_INCIDENT_LIST_SUCCESS,
  SEARCH_INCIDENT_LIST_FAILURE,
  DELETE_INCIDENT_LOADING,
  DELETE_INCIDENT_SUCCESS,
  DELETE_INCIDENT_FAILURE,
} from "../actions/incident";
import { isNil } from "lodash";
import {
  convertToLocalDate,
  convertToLocalDateTime,
} from "utils/dateAndTimeUtils";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  list: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_INCIDENT_LIST_LOADING:
    case DELETE_INCIDENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_INCIDENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload?.Data?.Rows.map((item, index) => {
          return {
            ...item,
            id: index,
            DateReported:
              !isNil(item.DateFirstReported) &&
              convertToLocalDate(item.DateFirstReported, "MM/dd/yyyy"),
            LastEdited:
              !isNil(item.UpdateTimeStamp) &&
              convertToLocalDateTime(item.UpdateTimeStamp, "MM/dd/yyyy"),
          };
        }),
      };
    }

    case SEARCH_INCIDENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        list: [],
      };
    }

    case DELETE_INCIDENT_SUCCESS: {
      toast.success(DELETE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_INCIDENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
